import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getHeader() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function CadastrarConfigPadraoValor(configPadraoValor, id) {
  const url = id
    ? `${urlBase}config-padrao-valor/${id}`
    : `${urlBase}config-padrao-valor/`;
  const config = getHeader();
  let response;
  if (id) {
    response = await axios.put(url, configPadraoValor, config);
  } else {
    response = await axios.post(url, configPadraoValor, config);
  }
  return response;
}

async function getConfigPadraoValor(id) {
  const url = urlBase + `config-padrao-valor/${id}/`;
  const config = getHeader();

  const response = await axios.get(url, config);
  return response;
}

async function getListaConfigPadraoValor(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url =
    urlBase + "config-padrao-valor/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirConfigPadraoValor(id) {
  const url = urlBase + `config-padrao-valor/${id}/`;
  const config = getHeader();
  const response = await axios.delete(url, config);
  return response;
}

const putConfigPadraoValor = async (id, data) => {
  const url = `${urlBase}config-padrao-valor/${id}/`;
  const config = getHeader();
  const response = await axios.put(url, data, config);
  return response;
};

const ConfigPadraoValorRequest = {
  CadastrarConfigPadraoValor,
  getConfigPadraoValor,
  getListaConfigPadraoValor,
  ExcluirConfigPadraoValor,
  putConfigPadraoValor,
};

export default ConfigPadraoValorRequest;
