import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { urlBase } from "../../Constante/Url";
import Logo from "../../assets/images/Logo colorida.png";
import { userRequest } from "../../userRequest";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/usersContext";

const Login = () => {
  const { login } = useUserContext();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const navigation = useNavigate();

  const LoginSistema = async () => {
    const body = {
      email: email,
      password: senha,
    };

    const url = urlBase + "login/";
    setLoading(true);
    try {
      const response = await axios.post(url, body);
      if (response.status === 200 || response.status === 201) {
        login(response.data.access, null);
        const responseUser = await userRequest.getUser(response.data.user_id);
        if (responseUser.status === 200 || responseUser.status === 201) {
          const data = responseUser.data;
          login(response.data.access, data);
          navigation("/");
        }
      }
    } catch (error) {
      const { detail } = error.response.data;
      setErrorMsg(detail);
    } finally {
      setLoading(false);
    }
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      LoginSistema();
    }
  };

  return (
    <div className="Login">
      <div
        name="normal_login"
        className="login-form"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img alt="" src={Logo} height={150} />

        <div
          style={{
            marginTop: 20,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "20%",
          }}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Usuario"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            id="email"
          />

          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
            onChange={(e) => setSenha(e.target.value)}
            value={senha}
            onKeyDown={handlePressEnter}
          />

          {errorMsg && <text style={{ color: "red" }}>{errorMsg}</text>}

          <div style={{ display: "flex" }}>
            <Link className="login-form-forgot" to="/Esqueci-senha">
              Esqueci minha senha
            </Link>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={LoginSistema}
            loading={loading}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Login;
