import React, { useState } from "react";
import { Button, Divider, Modal } from "antd";
import TabelaRequest from "../../tabelaRequest";
import { colors } from "../../../../Utils/temas";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDeleteCampo = ({ visible, onCancel, id, estadoCampo }) => {
  const [loading, setLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarCampo = async () => {
    setLoading(true);
    try {
      const response = await TabelaRequest.deleteCampo(id);
      if (response.status === 204) {
        estadoCampo((prevState) => prevState.filter((item) => item.id !== id));
        onCancel();
        setLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal visible={visible} onCancel={onCancel} footer={null}>
        <text style={{ fontSize: 18, fontWeight: "500" }}>
          Deletar <span style={{ fontWeight: "700" }}>CAMPO</span>
        </text>
        <Divider />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button
            style={{
              background: colors.secondary,
              color: "white",
              fontWeight: "500",
            }}
            onClick={deletarCampo}
            loading={loading}
          >
            {loading ? "Confirmando..." : "Confirmar"}
          </Button>
        </div>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
