/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Row, Col, Input } from "antd";
import { useState, React, useEffect } from "react";
import SeloGrupoPaiRequest from "./SeloGrupoPaiRequest";
import SelSeloGrupo from "../../Selecao/SeloGrupo/Sel_SeloGrupo";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";
const { Search } = Input;

const { useForm } = Form;

function CadastroSeloGrupoPai({
  visible,
  afterPost,
  onCancel,
  optionsUf,
  optionsSistema,
  id,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalSeloGrupoVisible, setIsModalSeloGrupoVisible] = useState(false);

  const [errorExists, setErrorExists] = useState("");

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getSeloGrupoPaiId();
    }
  }, [id]);

  async function CadastrarSeloGrupoPai(seloGrupoPai) {
    try {
      const response = await SeloGrupoPaiRequest.CadastrarSeloGrupoPai(
        seloGrupoPai,
        id
      );

      if (response.status === 201) {
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
        setConfirmLoading(false);
        afterPost((prevState) => [response.data, ...prevState]);
        setErrorExists("");
        onCancel();
      }
      if (response.status === 200) {
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        setConfirmLoading(false);
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        afterPost((prevState) => prevState.map(cb));
        onCancel();
      }
    } catch (error) {
      const { selo_grupo_id } = error.response.data;
      if (selo_grupo_id) {
        setErrorExists(selo_grupo_id[0]);
      }
    } finally {
      setConfirmLoading(false);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarSeloGrupoPai(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  function onSelecionarSeloGrupo(valor) {
    setIsModalSeloGrupoVisible(false);
    form.setFieldsValue({
      selo_grupo_id: valor.id,
      selo_grupo_descricao: valor.descricao,
    });
  }

  const buscarSeloGrupo = () => {
    setIsModalSeloGrupoVisible(true);
  };

  const getSeloGrupoPaiId = async () => {
    try {
      const response = await SeloGrupoPaiRequest.getSeloGrupoPai(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          selo_grupo_id: data.selo_grupo_id,
          selo_grupo_numero: data.selo_grupo_numero,
          selo_grupo_descricao: data.selo_grupo_descricao,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Form form={form} layout="vertical" preserve={false}>
          <Row gutter={10}>
            <Col span={0}>
              <Form.Item name="id" />
            </Col>
            <Col span={0}>
              <Form.Item
                name="selo_grupo_id"
                rules={[
                  {
                    required: true,
                    message: "Informe o grupo",
                  },
                ]}
              />
            </Col>

            <Col span={0}>
              <Form.Item name="selo_grupo_numero" />
            </Col>
            <Col span={24} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name="selo_grupo_descricao"
                label="Selo grupo"
                style={{ margin: 0 }}
              >
                <Search readOnly allowClear onSearch={buscarSeloGrupo} />
              </Form.Item>
              {errorExists && (
                <text style={{ color: "red" }}>{errorExists}</text>
              )}
            </Col>
          </Row>
        </Form>
        <SelSeloGrupo
          onSelecionado={(valor) => {
            onSelecionarSeloGrupo(valor);
          }}
          visible={isModalSeloGrupoVisible}
          onCancel={() => setIsModalSeloGrupoVisible(false)}
          optionsSistema={optionsSistema}
          optionsUf={optionsUf}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroSeloGrupoPai;
