import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function getListaGrupoFrases(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "frase-grupo/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function CadastrarGrupoFrases(GrupoFrases, id) {
  const url = id ? `${urlBase}frase-grupo/${id}` : `${urlBase}frase-grupo/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, GrupoFrases, config);
  } else {
    response = await axios.post(url, GrupoFrases, config);
  }
  return response;
}

async function getGrupoFrases(id) {
  const url = urlBase + `frase-grupo/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirGrupoFrases(id) {
  const url = urlBase + `frase-grupo/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const putGrupoFrases = async (id, data) => {
  const url = `${urlBase}frase-grupo/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const GrupoFrasesRequest = {
  getListaGrupoFrases,
  getGrupoFrases,
  CadastrarGrupoFrases,
  ExcluirGrupoFrases,
  putGrupoFrases,
};

export default GrupoFrasesRequest;
