import { Button, Space, Table, Tabs, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { menuRequest } from "./menuRequest";
import sistemaRequest from "../../Utils/sistemaRequest";
import { ModalInserir } from "./componentes/modais/modalInserir";
import { ModalDelete } from "./componentes/modais/modalDelete";
import { colors } from "../../Utils/temas";
import { useNavigate } from "react-router-dom";
import { DrawerFilter } from "./componentes/drawerFilter";

const { TabPane } = Tabs;

export const Menu = () => {
  const [menu, setMenu] = useState([]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filtros, setFiltros] = useState({});

  const navigation = useNavigate();

  useEffect(() => {
    carregarSistemas();
  }, []);

  useEffect(() => {
    if (filtros.sistema_id) {
      fetchRequest(1, 10, filtros);
    }
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Descrição sistema",
      dataIndex: "sistema_descricao",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (valor) => {
        const valorFormat = () => {
          if (valor) {
            return "Sim";
          }
          return "Não";
        };

        return <Tag color={valor ? "green" : "red"}>{valorFormat()}</Tag>;
      },
    },
    {
      title: "Ações",
      render: (_, render) => (
        <Space size="middle">
          <Tooltip title="Detalhes">
            <CopyOutlined
              onClick={() => navigation(`/Permissoes/${render.id}`)}
            />
          </Tooltip>

          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(render.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(render.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await menuRequest.getMenu(page, pageSize, params);
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setMenu(results);
        setLoading(false);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const carregarSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setSistemas(results);
        setFiltros({ sistema_id: results[0].id });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (value) => {
    setOpen(true);
    setId(value);
  };

  const handleDelete = (value) => {
    setOpenDelete(true);
    setIdDelete(value);
  };

  const handleClear = () => {
    setFiltros((prevState) => ({ ...prevState, em_teste: undefined }));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            color: "white",
            background: colors.secondary,
            fontWeight: "500",
          }}
          className="botao"
        >
          Inserir
        </Button>

        <Button
          icon={<FilterOutlined />}
          style={{
            color: "white",
            fontWeight: "500",
            background: colors.secondary,
          }}
          className="botao"
          onClick={() => setOpenFilter(true)}
        >
          Filtros
        </Button>
      </div>

      <Tabs
        Tabs
        type="card"
        size="large"
        onChange={(e) =>
          setFiltros((prevState) => ({ ...prevState, sistema_id: e }))
        }
      >
        {sistemas.map((item) => (
          <TabPane
            tab={item.nome_sistema}
            key={item.id}
            style={{ marginTop: -18 }}
          >
            <Table
              columns={columns}
              dataSource={menu}
              loading={loading}
              pagination={{
                total: totalRecords,
                onChange: (page, pageSize) =>
                  fetchRequest(page, pageSize, filtros),
              }}
            />
          </TabPane>
        ))}
      </Tabs>

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setId(null);
        }}
        visible={open}
        estadoMenu={setMenu}
        id={id}
        sistemaId={filtros.sistema_id}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoPermissao={setMenu}
      />

      <DrawerFilter
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
        onFinish={(e) => {
          setFiltros((prevState) => ({ ...prevState, ...e }));
          setOpenFilter(false);
        }}
        limpar={handleClear}
      />
    </div>
  );
};
