import { UserTockem } from "../../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getPrimaryKey = async (page, pageSize, params, id) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}PrimaryKey/?campo_tabela_id=${id}&limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};
const getPrimaryKeyId = async (id) => {
  const url = `${urlBase}PrimaryKey/${id}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postPrimaryKey = async (data, id) => {
  const url = id ? `${urlBase}PrimaryKey/${id}` : `${urlBase}PrimaryKey/`;
  const config = getConfig();
  let response;
  if (id) {
    response = axios.put(url, data, config);
  } else {
    response = axios.post(url, data, config);
  }
  return response;
};

const deletePrimaryKey = async (id) => {
  const url = `${urlBase}PrimaryKey/${id}/`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};

export const primaryRequest = {
  getPrimaryKey,
  getPrimaryKeyId,
  postPrimaryKey,
  deletePrimaryKey,
};
