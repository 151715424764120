import { Modal } from "antd";
import React, { useState } from "react";
import { sequencesRequest } from "../../../sequencesRequest";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoSequences }) => {
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletarPermissao = async () => {
    setLoading(true);
    try {
      const response = await sequencesRequest.deleteSequence(id);
      if (response.status === 204) {
        onCancel();
        estadoSequences((prevState) =>
          prevState.filter((item) => {
            return item.id !== id;
          })
        );
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarPermissao}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 18 }}>
          Deletar esta Sequence?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
