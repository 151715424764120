import { Modal } from "antd";
import React, { useState } from "react";
import CartorioRequest from "../../../../CartorioRequest";
import { ModalStatusDelete } from "../../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoAcessoRemoto }) => {
  const [loading, setLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const deletarAcesso = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.ExcluirAcessoRemoto(id);
      if (response.status === 204) {
        estadoAcessoRemoto((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
      }
      onCancel();
      setOpenStatus(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarAcesso}
        confirmLoading={loading}
      >
        <text style={{ fontSize: 16, fontWeight: "500" }}>
          Deseja deletar este Acesso Remoto?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatus(false)}
        visible={openStatus}
      />
    </>
  );
};
