/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Select, Skeleton, Switch } from "antd";
import React, { useEffect, useState } from "react";
import sistemaRequest from "../../../../../Utils/sistemaRequest";
import { menuRequest } from "../../../menuRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";

export const ModalInserir = ({
  visible,
  onCancel,
  estadoMenu,
  id,
  sistemaId,
}) => {
  const [sistemas, setSistemas] = useState([]);

  const [loading, setLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState((prevState) => ({
    create: false,
    edit: false,
  }));

  const [form] = Form.useForm();

  useEffect(() => {
    carregarSistemas();
  }, []);

  useEffect(() => {
    if (id) {
      getPermissaoId();
    }
  }, [id]);

  const carregarSistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setSistemas(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsSistemas = sistemas.map((item) => {
    return {
      label: item.nome_sistema,
      value: item.id,
    };
  });

  const inserirMenu = async (data) => {
    try {
      const response = await menuRequest.postMenu(data, id);
      if (response.status === 201) {
        const { data } = response;
        form.resetFields();
        onCancel();
        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoMenu((prevState) => [...prevState]);
        } else {
          estadoMenu((prevState) => [data, ...prevState]);
        }
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        form.resetFields();
        onCancel();
        const { data } = response;

        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoMenu((prevState) => prevState.filter((item) => item.id !== id));
        } else {
          const cb = (callback) => {
            if (callback.id === id) {
              return { ...callback, ...data };
            } else {
              return { ...callback };
            }
          };
          setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
          estadoMenu((prevState) => prevState.map(cb));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissaoId = async () => {
    setLoading(true);
    try {
      const response = await menuRequest.getMenuId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  form.setFieldsValue({ sistema_id: parseInt(sistemaId) });

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        footer={null}
        title={id ? "Editar" : "Inserir"}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) => inserirMenu(values))
            }
          >
            <Form.Item
              name="descricao"
              label="Descrição:"
              style={{ fontWeight: "500", margin: 0 }}
              rules={[
                {
                  required: true,
                  message: "Informe o nome da descrição",
                },
              ]}
            >
              <Input placeholder="Descrição" />
            </Form.Item>

            <Form.Item
              name="sistema_id"
              label="Sistemas:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
              rules={[
                {
                  required: true,
                  message: "Selecione ao menos um sistema",
                },
              ]}
            >
              <Select placeholder="Sistemas" options={optionsSistemas} />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit">
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
