/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { aplicacaoRequest } from "./request";
import { FilterOutlined } from "@ant-design/icons";
import { colors } from "../../../Utils/temas";
import { DrawerFilter } from "./componentes/drawerFilter";
const { TabPane } = Tabs;

export const Aplicacoes = () => {
  const [aplicacoes, setAplicacoes] = useState([
    { cartorio_descricao: "", nome_aplicacao: "", status: "" },
  ]);

  const [tabs, setTabs] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(null);

  const getInitialFilters = () => {
    const savedFilters = localStorage.getItem("aplicacoes_filtros");

    if (savedFilters) return JSON.parse(savedFilters);
    return {
      nome_aplicacao: "",
    };
  };

  const [filtros, setFiltros] = useState(getInitialFilters);

  useEffect(() => {
    saveFilters();
    listarAplicacoes(1, 10, filtros);
  }, [filtros]);

  useEffect(() => {
    tabsAplicacoes();
  }, []);

  const listarAplicacoes = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await aplicacaoRequest.getAplicacao(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setAplicacoes(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const tabsAplicacoes = async () => {
    try {
      const response = await aplicacaoRequest.getListarAplicacao();

      const { nome_aplicacao } = response.data;

      if (response.status === 200) {
        setFiltros((prevState) => ({
          ...prevState,
          nome_aplicacao: nome_aplicacao[0],
        }));
        setTabs(nome_aplicacao);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const tags = {
    nome_cartorio: "Cartório",
    nome_aplicacao: "Aplicação",
    cidade_cartorio: "Cidade",
    uf_cartorio: "UF",
    status: "Status",
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColorsKey = () => {
            if (key === "cidade_cartorio") {
              return "gold";
            } else if (key === "uf_cartorio") {
              return "blue";
            } else if (key === "nome_cartorio") {
              return "cyan";
            } else if (key === "status" && value === "ATIVO") {
              return "green";
            } else if (key === "status" && value === "INATIVO") {
              return "red";
            }
          };

          if (key === "nome_aplicacao") {
            return null;
          }

          return (
            <Tag
              closable
              key={key}
              onClose={() => onRemoveTags(key)}
              color={tagsColorsKey()}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  const columns = [
    {
      title: "Cartório",
      dataIndex: "cartorio_descricao",
    },
    { title: "Cidade", dataIndex: "cartorio_cidade" },
    { title: "UF", dataIndex: "cartorio_uf" },
    {
      title: "Nome do aplicativo",
      dataIndex: "nome_aplicacao",
    },
    {
      title: "Data atualização",
      dataIndex: "data_hora_atualizacao",
      render: (dataCompleta) => {
        const data = new Date(dataCompleta);

        return data.toLocaleString().replace(",", "");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (values) => (
        <Tag color={values === "ATIVO" ? "green" : "red"}>{values}</Tag>
      ),
    },
  ];

  const saveFilters = () => {
    localStorage.setItem("aplicacoes_filtros", JSON.stringify(filtros));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          padding: 10,
        }}
      >
        <Button
          style={{
            fontWeight: "500",
            background: colors.secondary,
            color: "white",
          }}
          icon={<FilterOutlined />}
          className="botao"
          onClick={() => setOpenFilter(true)}
        >
          Filtros
        </Button>
      </div>

      <Tabs
        type="card"
        size="large"
        onChange={(item) =>
          setFiltros((prevState) => ({
            ...prevState,
            nome_aplicacao: item,
          }))
        }
      >
        {tabs.map((item) => (
          <TabPane tab={item} style={{ marginTop: -16 }} key={item}>
            <Table
              dataSource={aplicacoes}
              pagination={{
                total: totalRecords,
                onChange: (page, pageSize) => {
                  listarAplicacoes(page, pageSize, filtros);
                },
              }}
              columns={columns}
              loading={loading}
              title={getTags}
            />
          </TabPane>
        ))}
      </Tabs>

      <DrawerFilter
        visible={openFilter}
        onClose={() => setOpenFilter(false)}
        estadoFiltros={(e) =>
          setFiltros((prevState) => ({
            ...prevState,
            nome_cartorio: e.nome_cartorio,
            cidade_cartorio: e.nome_cidade,
            uf_cartorio: e.uf_cartorio,
            status: e.status,
          }))
        }
        limparFiltro={() => {
          setFiltros((prevState) => ({
            nome_aplicacao: prevState.nome_aplicacao,
          }));
        }}
      />
    </>
  );
};
