import { Modal } from "antd";
import React, { useState } from "react";
import dominiosRequest from "../../dominioRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDeletar = ({
  visible,
  onCancel,
  idDelete,
  estadoDominio,
}) => {
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const deleteDominio = async () => {
    setLoading(true);
    try {
      const response = await dominiosRequest.deleteDominios(idDelete);
      if (response.status === 204) {
        setLoading(false);
        onCancel();
        estadoDominio((prevState) =>
          prevState.filter((item) => item.id !== idDelete)
        );
        setOpenStatusModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deleteDominio}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "600", fontSize: 18 }}>
          Deseja realmente excluir esse domínio?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
