/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Input, Modal, Skeleton, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../../../../Utils/temas";
import { SelectCampos } from "../../../foreignKey/components/selectCampos";
import { valoresDefaultRequest } from "../../valoresDefaultRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";
const { useForm } = Form;
const { Search } = Input;

export const ModalInsert = ({
  visible,
  onCancel,
  id,
  estadoValores,
  idValDef,
}) => {
  const [loading, setLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openStatusModal, setOpenStatusModal] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (idValDef) {
      getValuesVD(idValDef);
    }
  }, [idValDef]);

  const postValoresDefault = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await valoresDefaultRequest.postValoresDefault(
        data,
        idValDef
      );
      if (response.status === 201) {
        onCancel();
        const { data } = response;
        estadoValores((prevState) => [data, ...prevState]);
        form.resetFields();
        setConfirmLoading(false);
        setOpenStatusModal((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        const cb = (callback) => {
          if (callback.id === idValDef) {
            return { ...callback, ...response.data };
          }
          return { ...callback };
        };
        estadoValores((prevState) => prevState.map(cb));
        form.resetFields();
        setOpenStatusModal((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const onSelect = (value) => {
    form.setFieldsValue({ campo__nome: value.nome, campo_id: value.id });
    setOpenSelect(false);
  };

  const getValuesVD = async (id) => {
    setLoading(true);
    try {
      const response = await valoresDefaultRequest.getValoresDefaultId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          campo__nome: data.campo__nome,
          campo_id: data.campo_id,
          data_alteracao: data.data_alteracao,
          em_teste: data.em_teste,
          id: data.id,
          tabela__nome: data.tabela__nome,
          tabela_id: data.tabela_id,
          valor: data.valor,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title={idValDef ? "Editar" : "Inserir"}
        footer={null}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form.validateFields().then((values) => {
                const data = {
                  campo__nome: values.campo__nome,
                  campo_id: values.campo_id,
                  valor: values.valor,
                  tabela_id: id,
                  em_teste: values.em_teste,
                };

                postValoresDefault(data);
              });
            }}
            initialValues={{ em_teste: true }}
          >
            <Form.Item
              name="valor"
              label="Valor:"
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Informe o valor" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="campo_id">
              <Form.Item
                name="campo__nome"
                label="Campo:"
                style={{ fontWeight: "500", margin: 0 }}
                rules={[{ required: true, message: "Informe o campo" }]}
              >
                <Search readOnly onSearch={() => setOpenSelect(true)} />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Em Teste:"
              name="em_teste"
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}

        <SelectCampos
          openModal={openSelect}
          onCancel={() => setOpenSelect(false)}
          id={id}
          onSelecionado={(value) => onSelect(value)}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, create: false }))
        }
        visible={openStatusModal.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openStatusModal.edit}
      />
    </>
  );
};
