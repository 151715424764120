import React from "react";
import { styles } from "../../Atualizacao-id/estilos";
import { DeleteOutlined } from "@ant-design/icons";

export const CartoriosSelecionados = () => {
  return (
    <div style={{ marginLeft: 15, marginBottom: 10 }}>
      <text style={styles.cartorioSelecionado}>Cartórios selecionados</text>
    </div>
  );
};

export const ListaCartoriosSelecionados = ({ onClick, nomeCartorio }) => {
  return (
    <div style={styles.containerCartorio}>
      <div style={styles.espacamentos}>
        <text>{nomeCartorio}</text>
        <DeleteOutlined onClick={onClick} />
      </div>
    </div>
  );
};
