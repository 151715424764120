import { Modal } from "antd";
import React, { useState } from "react";
import { menuRequest } from "../../../menuRequest";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoPermissao }) => {
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarPermissao = async () => {
    try {
      const response = await menuRequest.deleteMenu(id);
      if (response.status === 204) {
        onCancel();
        estadoPermissao((prevState) =>
          prevState.filter((item) => {
            return item.id !== id;
          })
        );
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal visible={visible} onCancel={onCancel} onOk={deletarPermissao}>
        <text style={{ fontWeight: "500", fontSize: 18 }}>
          Deletar esta permissão?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
