import { UserTockem } from "../../Utils/autenticacao";
import { urlBase } from "../../Constante/Url";
import axios from "axios";

const getGroupReport = async (params) => {
  const url = `${urlBase}grupo-relatorio/`;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
};
const getGroupReportId = async (id) => {
  const url = `${urlBase}grupo-relatorio/${id}`;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  const response = await axios.get(url, config);
  return response;
};
const getReport = async (params) => {
  const url = `${urlBase}relatorio/`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
};

const postReportGroup = async (data, id) => {
  const url = id
    ? `${urlBase}grupo-relatorio/${id}`
    : `${urlBase}grupo-relatorio/`;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  let response;
  
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const postReport = async (data) => {
  const url = `${urlBase}relatorio/`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  const response = await axios.post(url, data, config);
  return response;
};

const putReport = async (id, data) => {
  const url = `${urlBase}grupo-relatorio/${id}/`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  const response = await axios.put(url, data, config);
  return response;
};

const deleteGroupReport = async (id) => {
  const url = `${urlBase}grupo-relatorio/${id}`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  const response = await axios.delete(url, config);
  return response;
};
const deleteReport = async (id) => {
  const url = `${urlBase}relatorio/${id}`;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };

  const response = await axios.delete(url, config);
  return response;
};

export const requestReport = {
  getReport,
  getGroupReport,
  getGroupReportId,
  postReport,
  postReportGroup,
  putReport,
  deleteGroupReport,
  deleteReport,
};
