import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";

import { Divider, Layout } from "antd";
import MenuSuperior from "../MenuSuperior/MenuSuperior";
import MenuPrincipal from "../MenuPrincipal/MenuPrincipal";
import "./estilos.css";
import { isLogged } from "../../Utils/autenticacao";
import Logo from "../../assets/images/Logo colorida.png";
import { useState } from "react";

const { Header, Content, Footer, Sider } = Layout;

const Template = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const navigate = useNavigate();

  if (!isLogged()) {
    return <Navigate to="/Login" />;
  }

  const token = localStorage.getItem("TockemUsuario");

  const tokenPayload = JSON.parse(atob(token.split(".")[1]));

  const currentTime = Math.floor(Date.now() / 1000);

  if (tokenPayload.exp && tokenPayload.exp < currentTime) {
    localStorage.removeItem("TockemUsuario");
    navigate("/");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="xxl"
        width={isSmallScreen ? "40vh" : "26vh"}
        collapsible
        style={{ background: "#F0F2F5" }}
        className="teste"
        onBreakpoint={(xs) => setIsSmallScreen(xs)}
      >
        <div style={{ padding: "10px 0px 13px 20px" }}>
          <Link to="/" style={{ paddingTop: 0 }}>
            <img src={Logo} alt="logo" style={{ width: 100 }} />
          </Link>
        </div>
        <MenuPrincipal />
      </Sider>

      <Layout style={{ minHeight: "100%" }}>
        <Header
          style={{
            background: "#f0f2f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <MenuSuperior />
        </Header>
        <Divider style={{ margin: "0px 0px 0px 0px" }} />

        <Content>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: "center", borderTop: "1px solid #E2E4E7" }}>
          <h1>Help System! Tri7©2024</h1>
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Template;
