import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getHeader() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function CadastrarConfigPadrao(configPadrao, id) {
  const url = id ? `${urlBase}config-padrao/${id}` : `${urlBase}config-padrao/`;
  const config = getHeader();
  let response;
  if (id) {
    response = await axios.put(url, configPadrao, config);
  } else {
    response = await axios.post(url, configPadrao, config);
  }
  return response;
}

async function getConfigPadrao(id) {
  const url = urlBase + `config-padrao/${id}/`;
  const config = getHeader();

  const response = await axios.get(url, config);
  return response;
}

async function getListaConfigPadrao(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "config-padrao/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirConfigPadrao(id) {
  const url = urlBase + `config-padrao/${id}/`;
  const config = getHeader();
  const response = await axios.delete(url, config);
  return response;
}

const putConfigPadrao = async (id, data) => {
  const url = `${urlBase}config-padrao/${id}`;
  const config = getHeader();
  const response = await axios.put(url, data, config);
  return response;
};

const ConfigPadraoRequest = {
  CadastrarConfigPadrao,
  getConfigPadrao,
  getListaConfigPadrao,
  ExcluirConfigPadrao,
  putConfigPadrao,
};

export default ConfigPadraoRequest;
