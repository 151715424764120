import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import React from "react";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { colors } from "../../../../../Utils/temas";
const { Item } = Form;
const { Group } = Radio;

const { useForm } = Form;

export const DrawerFilter = ({ visible, onClose, onFinish, limpar }) => {
  const [form] = useForm();

  const limparFiltros = () => {
    form.resetFields();
    limpar();
  };

  return (
    <Drawer visible={visible} onClose={onClose} title="Filtros">
      <Form
        form={form}
        layout="vertical"
        style={{ fontWeight: "500", height: "100%" }}
        onFinish={(value) => {
          onFinish(value);
          onClose();
        }}
      >
        <Item name="nome" label="Nome">
          <Input />
        </Item>

        <Item name="campo_nome" label="Campo:">
          <Input />
        </Item>

        <Item name="em_teste" label="Em teste:">
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "68%",
            justifyContent: "flex-end",
          }}
        >
          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
              icon={<SearchOutlined />}
              className="botao"
              htmlType="submit"
            >
              Pesquisar
            </Button>
            <Button
              icon={<ClearOutlined />}
              onClick={() => {
                onClose();
                limparFiltros();
              }}
            >
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
