import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Radio, Row, Select, Table } from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import EmolumentoPeriodoRequest from "../../Paginas/CadastroEmolumenoPeriodo/EmolumentoPeriodoRequest";
import { colors } from "../../Utils/temas";

function SelEmolumentoPeriodo({ onSelecionado, visible, onCancel, optionsUf }) {
  const [EmolumentoPeriodo, setEmolumentoPeriodo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [form_pesquisa] = Form.useForm();
  const [filtros, setFiltros] = useState([]);

  function handleOk() {
    onSelecionado(linhaSelecionada);
  }

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "Data incial",
      dataIndex: "data_inical",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await EmolumentoPeriodoRequest.getListaEmolumentoPeriodo(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setEmolumentoPeriodo(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
  };

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  return (
    <Modal
      width={800}
      title="Selecionar Emolumento Período"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "80vh", overflowX: "hidden" }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <Row gutter={10}>
            <Col span={6}>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"I"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="uf" label="UF">
                <Select options={optionsUf} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Button
                htmlType="submit"
                style={{ background: colors.secondary, color: "white" }}
              >
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={12} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="small"
        rowKey={"id"}
        rowSelection={{
          type: "radio",
          onChange: SelecionarLinha,
        }}
        dataSource={EmolumentoPeriodo}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
      />
    </Modal>
  );
}
export default SelEmolumentoPeriodo;
