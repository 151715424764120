import { Modal } from "antd";
import React, { useState } from "react";
import SeloGrupoFilhoRequest from "../SeloGrupoFilhoRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({
  visible,
  onCancel,
  id,
  estadoSeloGrupoFilho,
}) => {
  const [openStatus, setOpenStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletarSeloGrupoFilho = async () => {
    setLoading(true);
    try {
      const response = await SeloGrupoFilhoRequest.ExcluirSeloGrupoFilho(id);
      if (response.status === 204) {
        estadoSeloGrupoFilho((prevCartorio) =>
          prevCartorio.filter((item) => {
            return item.id !== id;
          })
        );
        onCancel();
        setOpenStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarSeloGrupoFilho}
        confirmLoading={loading}
      >
        <h3>Deseja realmente excluir esse selo grupo Filho?</h3>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatus(false)}
        visible={openStatus}
      />
    </>
  );
};
