/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Form, Row, Col, Radio, Button, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import FrasesRequest from "./FrasesRequest";
import SelGrupoFrase from "../../Selecao/GrupoFrase/Sel_GrupoFrase";
import { CloseOutlined } from "@ant-design/icons";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";
const { Search } = Input;
const { TextArea } = Input;

function CadastroFrases({ visible, id, onCancel, estadoFrases }) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalGrupoFraseVisible, setIsModalGrupoFraseVisible] =
    useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getFraseId();
    }
  }, [id]);

  async function CadastrarFrases(Frases) {
    try {
      const response = await FrasesRequest.CadastrarFrases(Frases, id);
      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        estadoFrases((prevState) => [response.data, ...prevState]);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoFrases((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarFrases(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const buscarGrupoFraseId = () => {
    setIsModalGrupoFraseVisible(true);
  };

  function onSelecionarGrupoFrase(valor) {
    setIsModalGrupoFraseVisible(false);
    form.setFieldsValue({
      frase_grupo_id: valor.id,
      frase_grupo_descricao: valor.descricao,
    });
  }

  const LimparGrupo = () => {
    form.setFieldsValue({
      frase_grupo_id: null,
      frase_grupo_descricao: null,
    });
  };

  const getFraseId = async () => {
    setLoading(true);
    try {
      const response = await FrasesRequest.getFrases(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          texto: data.texto,
          frase_grupo_id: data.frase_grupo_id,
          frase_grupo_descricao: data.frase_grupo_descricao,
          situacao: data.situacao,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Row gutter={10}>
              <Col span={0}>
                <Form.Item name="id" />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="texto"
                  label="Texto"
                  rules={[{ required: true, message: "Informe o texto." }]}
                >
                  <TextArea
                    showCount
                    maxLength={150}
                    style={{
                      height: 80,
                    }}
                    placeholder="Texto"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item name="frase_grupo_id">
                  <Form.Item
                    name="frase_grupo_descricao"
                    label="Grupo"
                    rules={[
                      {
                        required: true,
                        message: "Informe o grupo",
                      },
                    ]}
                  >
                    <Search readOnly allowClear onSearch={buscarGrupoFraseId} />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label=" ">
                  <Button onClick={LimparGrupo} icon={<CloseOutlined />} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="situacao" label="Situação">
                  <Radio.Group>
                    <Radio value={"A"}>Ativo</Radio>
                    <Radio value={"I"}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        <SelGrupoFrase
          onSelecionado={(valor) => {
            onSelecionarGrupoFrase(valor);
          }}
          visible={isModalGrupoFraseVisible}
          onCancel={() => setIsModalGrupoFraseVisible(false)}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroFrases;
