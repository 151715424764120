import { useEffect, useState, React } from "react";
import {
  Button,
  Drawer,
  Form,
  Space,
  Table,
  Col,
  Row,
  Input,
  Divider,
  Tag,
  Tooltip,
  Tabs,
  Radio,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import ConfigGrupoRequest from "./ConfigGrupoRequest";
import CadastroConfigGrupo from "./CadastroConfigGrupo";
import sistemaRequest from "../../Utils/sistemaRequest";
import { ModalDelete } from "./modais/modalDelete";
import { colors } from "../../Utils/temas";
const { Group } = Radio;

const { TabPane } = Tabs;

const ConfigGrupo = () => {
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [openDelete, setOpenDelete] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [form_pesquisa] = Form.useForm();

  const [filtros, setFiltros] = useState({});

  const [configGrupo, setConfigGrupo] = useState([]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await ConfigGrupoRequest.getListaConfigGrupo(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      const { results, count } = response.data;
      setConfigGrupo(results);
      setTotalRecords(count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros((prevState) => ({ ...prevState, ...value }));
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros((prevState) => ({
      ...prevState,
      descricao: undefined,
      em_teste: undefined,
    }));
    form_pesquisa.resetFields();
    setDrawerVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  async function onEditConfigGrupo(id) {
    showModal();
    setId(id);
  }

  function onDeletarConfigGrupo(id) {
    setOpenDelete(true);
    setIdDelete(id);
  }

  async function CarregarSistema() {
    const response = await sistemaRequest.getSistemas();
    if (response.status === 200) {
      const { results } = response.data;
      setSistemas(results);
      setFiltros({ sistema_id: results[0].id });
    }
  }

  const optionsSistema = sistemas.map((sistema) => {
    return {
      label: sistema.nome_sistema,
      value: sistema.id,
    };
  });

  useEffect(() => {
    CarregarSistema();
  }, []);

  useEffect(() => {
    if (filtros.sistema_id) {
      FetchRecords(1, 10, filtros);
    }
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data Alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const date = new Date(value);
        const dateFormatted = date.toLocaleString().replace(",", "");
        return dateFormatted;
      },
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Sistema",
      dataIndex: "sistema_descricao",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => {
        const descricao = () => {
          if (value) {
            return "Sim";
          }
          return "Não";
        };

        return <Tag color={value ? "green" : "red"}>{descricao()}</Tag>;
      },
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditConfigGrupo(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarConfigGrupo(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tags = {
    descricao: "Descrição",
    sistema_id: "Sistema",
    em_teste: "Em teste",
  };

  const onRemoverFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColor = () => {
            if (key === "descricao") {
              return "volcano";
            } else if (key === "em_teste" && value) {
              return "green";
            }
            return "red";
          };

          if (key === "sistema_id") return null;

          if (value === "") return undefined;

          return (
            <Tag
              key={key}
              closable
              onClose={() => onRemoverFiltros(key)}
              color={tagsColor(key)}
            >
              <text>
                {tags[key] +
                  ": " +
                  (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer visible={drawerVisible} onClose={() => setDrawerVisible(false)}>
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            style={{ height: "100%" }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="descricao" label="Descrição">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              style={{ fontWeight: "500" }}
            >
              <Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Group>
            </Form.Item>

            <div
              style={{
                height: "78%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Divider style={{ background: "#DCDCDC" }} />

              <Row gutter={0}>
                <Col span={10}>
                  <Button
                    htmlType="submit"
                    style={{
                      background: colors.secondary,
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="botao"
                  >
                    <KeyOutlined />
                    Pesquisar
                  </Button>
                </Col>

                <Col span={5} push={7}>
                  <Button onClick={Limpar}>
                    <CloseOutlined />
                    Limpar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Drawer>
      </div>

      <Tabs
        type="card"
        size="large"
        onChange={(e) =>
          setFiltros((prevState) => ({ ...prevState, sistema_id: e }))
        }
      >
        {sistemas.map((item) => (
          <TabPane
            tab={item.nome_sistema}
            key={item.id}
            style={{ marginTop: -18 }}
          >
            <Table
              loading={loading}
              columns={columns}
              dataSource={configGrupo}
              pagination={{
                total: totalRecords,
                onChange: (page, pagaSize) => {
                  FetchRecords(page, pagaSize, filtros);
                },
              }}
              title={getTags}
            />
          </TabPane>
        ))}
      </Tabs>
      <CadastroConfigGrupo
        visible={isModalVisible}
        form={form}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        optionsSistema={optionsSistema}
        sistemaId={filtros.sistema_id}
        id={id}
        estadoConfigGrupo={setConfigGrupo}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoConfigGrupo={setConfigGrupo}
      />
    </div>
  );
};

export default ConfigGrupo;
