import { urlBase } from "../../../../Constante/Url";
import axios from "axios";
import { UserTockem } from "../../../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getEstacao = (page, pageSize, id, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}estacao/?cartorio_id=${id}&limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};

const putEstacao = async (id, data) => {
  const url = `${urlBase}estacao/${id}/`;
  const config = getConfig();
  const response = axios.put(url, data, config);
  return response;
};

const deleteEstacao = async (id) => {
  const url = `${urlBase}estacao/${id}`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};

export const estacaoRequest = {
  getEstacao,
  putEstacao,
  deleteEstacao,
};
