import { Button, Form, Input, Modal, Result } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { Usuario } from "../../perfil";

export const ModalTrocarSenha = ({ visible, onCancel }) => {
  const [form] = useForm();

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [erro, setErro] = useState(null);
  const [sucess, setSucess] = useState(false);

  const atualizarSenha = async () => {
    setErro(null);
    setSucess(false);

    try {
      const dados = {
        password: password,
        password2: password2,
      };

      const response = await Usuario.putAtualizarSenha(dados);
      if (response.status === 200 || response.status === 201) {
        setSucess(true);
      }
    } catch (error) {
      setErro(error.response ? error.response.data : "Erro desconhecido");
    }
  };

  useEffect(() => {
    setSucess(false);
    setErro(null);
    form.resetFields();
  }, [visible, form]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Mudar senha"
      footer={null}
    >
      {sucess ? (
        <Result
          status="success"
          title="Senha alterada com sucesso!"
          extra={[
            <Button type="primary" onClick={onCancel}>
              Fechar
            </Button>,
          ]}
        />
      ) : (
        <Form form={form}>
          <Form.Item name="password">
            <text style={{ fontWeight: "600", fontSize: 14 }}>Nova senha:</text>
            <Input.Password
              style={{ marginTop: 4, borderRadius: 4 }}
              onChange={(e) => setPassword(e.target.value)}
            />
            {erro ? (
              <text style={{ color: "#ff3333" }}>{erro.password[0]}</text>
            ) : null}
          </Form.Item>

          <Form.Item name="password2">
            <text style={{ fontWeight: "600", fontSize: 14 }}>
              Confirmar nova senha:
            </text>
            <Input.Password
              style={{ marginTop: 4, borderRadius: 4 }}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {erro ? (
              <text style={{ color: "#ff3333" }}>{erro.password[1]}</text>
            ) : null}
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "40%",
              }}
            >
              <Button onClick={onCancel}>Cancelar</Button>
              <Button htmlType="submit" type="primary" onClick={atualizarSenha}>
                Confirmar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};
