import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getViews = async (page, pageSize, params) => {
  let skip = (page - 1) * page;
  const url = `${urlBase}View/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getViewId = async (id) => {
  const url = `${urlBase}View/${id}/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postView = async (data, id) => {
  const url = id ? `${urlBase}View/${id}` : `${urlBase}View/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putView = async (id, data) => {
  const url = `${urlBase}View/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteView = async (id) => {
  const url = `${urlBase}View/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const viewRequest = {
  getViews,
  getViewId,
  postView,
  putView,
  deleteView,
};
