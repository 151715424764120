import axios from "axios";
import { urlBase } from "./Constante/Url";
import { UserTockem } from "./Utils/autenticacao";

const getConfig = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
};

const getUser = (id) => {
  const url = `${urlBase}usuario/registro/${id}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

export const userRequest = {
  getUser,
};
