import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { procedureRequest } from "./procedureRequest";
import { ModalInserir } from "./components/modais/inserir";
import { ModalDelete } from "./components/modais/delete";
import { colors } from "../../Utils/temas";
const { Group } = Radio;

export const Procedure = () => {
  const [procedures, setProcedures] = useState([]);

  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [procedureId, setProcedureId] = useState(null);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openInsert, setOpenInsert] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [filtro, setFiltro] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    fetchRequest(1, 10, filtro);
  }, [filtro]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data Alteração",
      dataIndex: "data_atualizacao",
      render: (value) => {
        const data = new Date(value);
        return data.toLocaleString().replace(",", "");
      },
    },
    { title: "Nome", dataIndex: "nome" },
    {
      title: "Em Teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await procedureRequest.getProcedure(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setProcedures(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setFiltro(value);
    setOpen(false);
  };

  const handleClear = () => {
    setOpen(false);
    form.resetFields();
    setFiltro({});
  };

  const handleEdit = (value) => {
    setProcedureId(value);
    setOpenInsert(true);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  return (
    <>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => setOpenInsert(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined /> Inserir
        </Button>

        <Button
          onClick={() => setOpen(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined /> Filtro
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={procedures}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => fetchRequest(page, pageSize, filtro),
        }}
        loading={loading}
      />

      <Drawer
        visible={open}
        onClose={() => setOpen(false)}
        title="Filtrar por:"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSearch}
          style={{ height: "90%" }}
        >
          <Form.Item name="nome" label="Nome:" style={{ fontWeight: "500" }}>
            <Input />
          </Form.Item>

          <Form.Item
            name="em_teste"
            label="Em teste:"
            style={{ margin: 0, fontWeight: "500" }}
          >
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>

          <div
            style={{
              height: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleClear}>
                <ClearOutlined />
                Limpar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <SearchOutlined /> Pesquisar
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <ModalInserir
        onCancel={() => {
          setOpenInsert(false);
          setProcedureId(null);
        }}
        visible={openInsert}
        estadoProcedure={setProcedures}
        id={procedureId}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setIdDelete(null);
        }}
        visible={openDelete}
        id={idDelete}
        onDelete={setProcedures}
      />
    </>
  );
};
