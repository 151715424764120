/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  Input,
  Select,
  Form,
  Switch,
  Radio,
  Skeleton,
} from "antd";
import { useState, React, useEffect } from "react";
import SeloGrupoRequest from "./SeloGrupoRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

const { useForm } = Form;

function CadastroSeloGrupo({
  id,
  visible,
  onCancel,
  optionsUf,
  optionsSistema,
  estadoSeloGrupo,
  optionsTipoCartorio,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorsExist, setErrorsExist] = useState({ sistema_id: [""] });
  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getSeloGrupoId();
    }
  }, [id]);

  async function CadastrarSeloGrupo(seloGrupo) {
    setConfirmLoading(true);
    try {
      const response = await SeloGrupoRequest.CadastrarSeloGrupo(seloGrupo, id);
      if (response.status === 201) {
        form.resetFields();
        estadoSeloGrupo();
        onCancel();
        setErrorsExist({ sistema_id: [""] });
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        estadoSeloGrupo();
        onCancel();
        setErrorsExist({ sistema_id: [""] });
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      const { data } = error.response;
      setErrorsExist(data);
    } finally {
      setConfirmLoading(false);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarSeloGrupo(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const getSeloGrupoId = async () => {
    setLoading(true);
    try {
      const response = await SeloGrupoRequest.getSeloGrupo(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          descricao_completa: data.descricao_completa,
          tipo_cartorio: data.tipo_cartorio,
          controle_automatico: data.controle_automatico,
          pagina_acresce: data.pagina_acresce,
          numero: data.numero,
          situacao: data.situacao,
          sistema_descricao: data.sistema_descricao,
          sistema_id: data.sistema_id,
          estoque_minimo: data.estoque_minimo,
          uf: data.uf,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        destroyOnClose
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Form.Item name="id" style={{ display: "none" }} />

            <div style={{ display: "flex", gap: 16 }}>
              <Form.Item
                name="descricao"
                label="Descrição"
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="descricao_completa"
                label="Descrição completa"
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item name="tipo_cartorio" label="Tipo">
                <Select options={optionsTipoCartorio} style={{ width: 238 }} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", gap: 16 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "150%",
                }}
              >
                <Form.Item
                  name="sistema_id"
                  label="Sistema"
                  style={{ marginBottom: 0, width: "100%" }}
                >
                  <Select options={optionsSistema} />
                </Form.Item>
                {errorsExist && (
                  <text
                    style={{
                      color: "red",
                      width: "100%",
                      display:
                        errorsExist.sistema_id[0] !== "" ? "flex" : "none",
                    }}
                  >
                    {errorsExist.sistema_id}
                  </text>
                )}
              </div>

              <Form.Item
                name="estoque_minimo"
                label="Estoque minimo"
                style={{ width: "150%" }}
              >
                <Input />
              </Form.Item>
              <Form.Item name="uf" label="UF" style={{ width: "69%" }}>
                <Select name="uf" options={optionsUf} />
              </Form.Item>

              <Form.Item name="numero" label="Numero" style={{ width: "69%" }}>
                <Input />
              </Form.Item>
            </div>

            <div style={{ display: "flex", gap: 43 }}>
              <Form.Item
                name="controle_automatico"
                label="Controle Aut."
                valuePropName="checked"
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="pagina_acresce"
                label="Pagina acresce"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"F"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroSeloGrupo;
