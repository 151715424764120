/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumb, Button, Input, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { menuRequest } from "../menuRequest";
import { ModalInserir } from "./componentes/modais/modalInserir";
import { DrawerFilter } from "./componentes/drawer";
import { ModalDelete } from "./componentes/modais/modalDelete";
import { colors } from "../../../Utils/temas";
import { ErrorModal } from "../../../Utils/errorModal";

export const MenuId = () => {
  const { permissaoId } = useParams();

  const [menus, setMenus] = useState([]);

  const [filtro, setFiltro] = useState({});
  const [error, setError] = useState({ open: false, status: null });
  const [menu, setMenu] = useState({ descricao: "", sistema_descricao: "" });

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [idItem, setIdItem] = useState(0);

  const [idDelete, setIdDelete] = useState(null);

  useEffect(() => {
    fetchRequest(1, 10, {});
    menuHead();
  }, [permissaoId]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Descrição", dataIndex: "descricao" },
    { title: "Chave rotina", dataIndex: "chave_rotina" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (_, results) => (
        <Space size="large">
          <EditOutlined onClick={() => handleEdit(results.id)} />
          <DeleteOutlined onClick={() => handleDelete(results.id)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (value) => {
    setIdItem(value);
    setOpen(true);
  };

  const handleDelete = (value) => {
    setIdDelete(value);
    setOpenDelete(true);
  };

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await menuRequest.getMenuItem(
        page,
        pageSize,
        params,
        permissaoId
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setMenus(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const menuHead = async () => {
    try {
      const response = await menuRequest.getMenuId(permissaoId);
      if (response.status === 200 || response.status === 201) {
        setMenu(response.data);
      }
    } catch (error) {
      console.error(error);
      const { status } = error.response;
      setError({ open: true, status: status });
    }
  };

  const onPesquisarClick = (e) => {
    setFiltro(e);
    fetchRequest(1, 10, e);
    setOpenFilter(false);
  };

  return (
    <div>
      <Breadcrumb style={{ padding: 10, fontSize: 14, fontWeight: "500" }}>
        <Breadcrumb.Item href="/Permissoes">Permissões</Breadcrumb.Item>
        <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <div style={{ width: "48%" }}>
          <text>Descrição:</text>
          <Input value={menu.descricao} readOnly />
        </div>

        <div style={{ width: "48%" }}>
          <text>Sistema descrição:</text>
          <Input value={menu.sistema_descricao} readOnly />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          Inserir
        </Button>
        <Button
          icon={<FilterOutlined />}
          onClick={() => setOpenFilter(true)}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          Filtros
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={menus}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => fetchRequest(page, pageSize, filtro),
        }}
        loading={loading}
      />

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setIdItem(null);
        }}
        visible={open}
        id={permissaoId}
        estadoPermissao={setMenus}
        idItem={idItem}
      />

      <DrawerFilter
        onClose={() => setOpenFilter()}
        visible={openFilter}
        onPesquisarClick={onPesquisarClick}
        fetchRequest={() => {
          fetchRequest(1, 10, {});
          setFiltro({});
        }}
      />

      <ModalDelete
        visible={openDelete}
        onCancel={() => {
          setOpenDelete(false);
          setIdDelete(null);
        }}
        id={idDelete}
        estadoPermissao={setMenus}
      />

      <ErrorModal
        msg="Desculpe, parece que a Permissão que você está procurando não pode ser encontrada. Por favor, verifique a URL e tente novamente."
        onCancel={() => setError({ open: false, status: null })}
        status={error.status}
        visible={error.open}
      />
    </div>
  );
};
