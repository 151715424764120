import { Button, Space, Table, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { arquivosRequest } from "./arquivosRequest";
import { DeleteOutlined } from "@ant-design/icons";
import { ModalInserir } from "./modal";
import { ModalDelete } from "./modalDelete";
import { colors } from "../../../Utils/temas";
import { useUserContext } from "../../../contexts/usersContext";

const { TabPane } = Tabs;

export const AtualizacaoRemota = () => {
  const [arquivos, setArquivos] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [id, setId] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [filtros, setFiltros] = useState({ tipo_arquivo: "S" });

  const { currentUser } = useUserContext();

  useEffect(() => {
    listaArquivos(1, 10, filtros);
  }, [filtros]);

  const columns = [
    { title: "Nome arquivo", dataIndex: "nome_arquivo" },
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data arquivo",
      dataIndex: "data_arquivo",
      render: (value) => {
        return <text>{formatarData(value)}</text>;
      },
    },
    {
      title: "Tipo arquivo",
      dataIndex: "tipo_arquivo",
      render: (value) => (
        <text>{value === "D" ? "Dependência" : "Sistema"}</text>
      ),
    },
    { title: "Sistema descrição", dataIndex: "sistema_descricao" },
    {
      title: "Ações",
      render: (_, render) => (
        <Space size="small">
          {currentUser.is_staff && (
            <Tooltip title="Deletar">
              <DeleteOutlined onClick={() => handleDelete(render.id)} />
            </Tooltip>
          )}
          <Tooltip title="Download">
            <DownloadOutlined
              onClick={() => baixarArquivo(render.link_arquivo)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDelete = (value) => {
    setOpenDelete(true);
    setId(value);
  };

  const listaArquivos = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await arquivosRequest.getArquivos(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setLoading(false);
        setArquivos(results);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatarData = (data) => {
    const date = new Date(data);
    const dataFormatada = date.toLocaleString().split(",");
    return dataFormatada;
  };

  const baixarArquivo = (link) => {
    const element = document.createElement("a");
    element.href = link;
    element.download = "teste.zip";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const tiposArquivos = [
    { descricao: "Sistema", value: "S" },
    { descricao: "Dependência", value: "D" },
  ];

  return (
    <>
      {currentUser.is_staff && (
        <div style={{ padding: 10 }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setOpen(true)}
            style={{
              background: colors.secondary,
              fontWeight: "500",
              color: "white",
            }}
            className="botao"
          >
            Inserir
          </Button>
        </div>
      )}

      <Tabs
        type="card"
        size="large"
        onChange={(e) => setFiltros({ tipo_arquivo: e })}
      >
        {tiposArquivos.map((item) => (
          <TabPane
            tab={item.descricao}
            key={item.value}
            style={{ marginTop: -16 }}
          >
            <Table
              columns={columns}
              dataSource={arquivos}
              pagination={{
                total: totalRecords,
                onChange: (page, pageSize) =>
                  listaArquivos(page, pageSize, filtros),
              }}
              loading={loading}
            />
          </TabPane>
        ))}
      </Tabs>

      <ModalInserir
        onCancel={() => setOpen(false)}
        visible={open}
        atualizarLista={() => listaArquivos(1, 10, filtros)}
        tipoArquivo={filtros.tipo_arquivo}
      />
      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        estadoArquivos={setArquivos}
        id={id}
      />
    </>
  );
};
