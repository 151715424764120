import { Modal } from "antd";
import React, { useState } from "react";
import { requestReport } from "../../../request";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDeletar = ({
  visible,
  onCancel,
  id,
  estadoRelatorioGrupo,
}) => {
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletarRelatorioGrupo = async () => {
    setLoading(true);
    try {
      const response = await requestReport.deleteGroupReport(id);
      if (response.status === 204) {
        onCancel();
        estadoRelatorioGrupo();
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarRelatorioGrupo}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500" }}>
          Deseja excluir este Relatório Grupo?
        </text>
      </Modal>
      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
