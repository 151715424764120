import { Modal } from "antd";
import React, { useState } from "react";
import ConfigGrupoRequest from "../../ConfigGrupoRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoConfigGrupo }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteConfigGrupo = async () => {
    setConfirmLoading(true);
    try {
      const response = await ConfigGrupoRequest.ExcluirConfigGrupo(id);
      if (response.status === 204) {
        setConfirmLoading(false);
        estadoConfigGrupo((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        onCancel();
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deleteConfigGrupo}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontSize: 16, fontWeight: "500" }}>
          Deseja realmente excluir esse Config. Grupo?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
