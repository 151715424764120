import { Modal } from "antd";
import React, { useState } from "react";
import { comandosRequest } from "../comandosRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDeletar = ({ visible, onCancel, id, estadoComando }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteComando = async () => {
    setConfirmLoading(true);
    try {
      const response = await comandosRequest.deleteComando(id);
      if (response.status === 204) {
        estadoComando((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        onCancel();
        setConfirmLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        title="Deletar"
        onOk={deleteComando}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 17 }}>
          Tem certeza que deseja deletar este Comando?
        </text>
      </Modal>

      <ModalStatusDelete
        visible={openModalStatus}
        onCancel={() => setOpenModalStatus(false)}
      />
    </>
  );
};
