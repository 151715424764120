export const ListaTipoValor = [
    {
       tipo: '1',
       descricao:'Valor Comum' 
    },
    {
        tipo:'2',
        descricao:'Valor impressora'
    },
    {
        tipo:'3',
        descricao:'Valor padrão'
    },
    {
        tipo:'4',
        descricao:'Valor tabela'
    },
    {
        tipo:'5',
        descricao:'Cor'
    },
    {
        tipo:'6',
        descricao:'Texto'
    },
    {
        tipo:'7',
        descricao:'fonte'
    }    
]