import { Button, Space, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { comandosRequest } from "./comandosRequest";
import { ModalInserir } from "./modalInserir";
import { DrawerFiltro } from "./drawerFiltro";
import { ModalDeletar } from "./modalDeletar";
import { colors } from "../../Utils/temas";

export const Comandos = () => {
  const [comandos, setComandos] = useState([]);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filtro, setFiltro] = useState({});

  useEffect(() => {
    getComandos(1, 10, filtro);
  }, [filtro]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data atualização",
      dataIndex: "data_atualizacao",
      render: (value) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleString().replace(",", "");
        return formattedDate;
      },
    },
    { title: "Descricao", dataIndex: "descricao", width: 440 },
    {
      title: "Texto Firebird",
      dataIndex: "texto_firebird",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Em Teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (item) => (
        <Space size="small">
          <Tooltip title="Editar">
            <EditOutlined onClick={() => handleEdit(item.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(item.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleEdit = (item) => {
    setId(item);
    setOpen(true);
  };

  const handleDelete = (item) => {
    setOpenDelete(true);
    setIdDelete(item);
  };

  const getComandos = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await comandosRequest.getComandos(
        page,
        pageSize,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setComandos(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveTags = (key) => {
    const novosFiltros = { ...filtro };
    delete novosFiltros[key];

    setFiltro(novosFiltros);
  };

  const tag = {
    descricao: "Descrição",
    em_teste: "Em teste",
  };

  const getTags = () => (
    <>
      {Object.entries(filtro)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColors = () => {
            if (key === "descricao") {
              return "gold";
            } else if (key === "em_teste" && value) {
              return "green";
            }
            return "red";
          };

          return (
            <Tag
              closable
              onClose={() => onRemoveTags(key)}
              color={tagsColors(key)}
              key={key}
            >
              <text style={{ padding: 10 }}>
                {tag[key] +
                  ": " +
                  (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          Inserir
        </Button>
        <Button
          icon={<FilterOutlined />}
          onClick={() => setOpenFilter(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          Filtro
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={comandos}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => getComandos(page, pageSize, filtro),
        }}
        loading={loading}
        size="large"
        title={getTags}
      />

      <ModalInserir
        onCancel={() => {
          setOpen(false);
          setId(null);
        }}
        visible={open}
        estadoComandos={setComandos}
        id={id}
      />
      <ModalDeletar
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        estadoComando={setComandos}
        id={idDelete}
      />

      <DrawerFiltro
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
        onFinish={(value) => {
          setFiltro(value);
          setOpenFilter(false);
        }}
        cleanFilter={() => setFiltro({})}
      />
    </>
  );
};
