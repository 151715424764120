import { Button, Divider, Drawer, Input } from "antd";
import React, { useState } from "react";
import { colors } from "../../../../Utils/temas";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";

export const DrawerFilter = ({
  visible,
  onClose,
  valuesFilter,
  clearFilter,
}) => {
  const [codigoSelo, setCodigoSelo] = useState("");

  const filter = () => {
    valuesFilter(codigoSelo);
  };

  const clear = () => {
    clearFilter("");
    onClose();
    setCodigoSelo("");
  };

  return (
    <Drawer visible={visible} onClose={onClose} title="Filtros">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <text style={{ fontWeight: "500" }}>Código selo:</text>
          <Input
            onChange={(e) => setCodigoSelo(e.target.value)}
            value={codigoSelo}
          />
        </div>

        <div>
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              icon={<FilterOutlined />}
              className="botao"
              onClick={filter}
            >
              Filtrar
            </Button>
            <Button onClick={clear} icon={<ClearOutlined />}>
              Limpar
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
