import React from "react";
import { useUserContext } from "../../contexts/usersContext";
import { Typography } from "antd";

export const Home = () => {
  const { currentUser } = useUserContext();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography style={{ fontWeight: "500", fontSize: 24 }}>
        Bem vindo, {currentUser.nome}!
      </Typography>
    </div>
  );
};
