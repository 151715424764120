/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Switch,
} from "antd";
import TabelaRequest from "../../tabelaRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../Utils/modalStatus";
const { useForm, Item } = Form;

export const ModalAddFields = ({
  id,
  visible,
  onCancel,
  tabela_id,
  estadoCampo,
}) => {
  const [dominios, setDominios] = useState([]);

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    getDomains();
  }, []);

  useEffect(() => {
    if (id) {
      getFieldId();
    }
  }, [id]);

  const getDomains = async () => {
    try {
      const response = await TabelaRequest.getDominios();
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        const dominiosFormatados = data.map((item) => ({
          label: item.nome,
          value: item.id,
        }));
        setDominios(dominiosFormatados);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const inserirCampo = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await TabelaRequest.postCampo(data, id);
      if (response.status === 200) {
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          }
          return { ...callback };
        };
        estadoCampo((prevState) => prevState.map(cb));
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
      if (response.status === 201) {
        onCancel();
        estadoCampo((prevState) => [response.data, ...prevState]);
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleClose = () => {
    onCancel();
    form.resetFields();
  };

  const getFieldId = async () => {
    setLoading(true);
    try {
      const response = await TabelaRequest.getCampoId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          dominio_id: data.dominio_id,
          em_teste: data.em_teste,
          not_null: data.not_null,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        footer={null}
        title={id ? "Editar campo" : "Inserir um novo campo"}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => inserirCampo({ ...e, tabela_id: tabela_id })}
            initialValues={{ em_teste: true }}
          >
            <Item
              name="nome"
              label="Nome do campo:"
              rules={[{ required: true, message: "Campo obrigatório." }]}
            >
              <Input />
            </Item>

            <Item
              name="dominio_id"
              label="Domínio:"
              rules={[
                { required: true, message: "Selecione ao menos um domínio." },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione um domínio"
                options={dominios}
              />
            </Item>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "30%",
              }}
            >
              <Item name="em_teste" label="Em teste:" valuePropName="checked">
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Item>
              <Item name="not_null" label="Not Null:" valuePropName="checked">
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Item>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <Button onClick={handleClose}>Cancelar</Button>
              <Button
                style={{
                  background: "#F89116",
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                htmlType="submit"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
