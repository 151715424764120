import axios from "axios";
import { urlBase } from "../../Constante/Url";
import { UserTockem } from "../../Utils/autenticacao";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

const passwordReset = (data) => {
  const url = `${urlBase}login/password-reset/`;
  const response = axios.post(url, data, getConfig);
  return response;
};
const validateToken = (data) => {
  const url = `${urlBase}login/password-reset/validate_token/`;
  const response = axios.post(url, data, getConfig);
  return response;
};
const confirmPassword = (data) => {
  const url = `${urlBase}login/password-reset/confirm/`;
  const response = axios.post(url, data, getConfig);
  return response;
};

export const esqueciSenhaRequest = {
  passwordReset,
  validateToken,
  confirmPassword,
};
