import { useState, useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Table } from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import ConfigPadraoRequest from "../../Paginas/CadastroConfigPadrao/ConfigPadraoRequest";
import { colors } from "../../Utils/temas";

function SelConfigPadrao({ onSelecionado, visible, onCancel }) {
  const [ConfigPadrao, setConfigPadrao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [form_pesquisa] = Form.useForm();
  const [filtros, setFiltros] = useState([]);

  function handleOk() {
    onSelecionado(linhaSelecionada);
  }

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Tabela",
      dataIndex: "tabela",
    },
    {
      title: "Key Field",
      dataIndex: "key_field",
    },
    {
      title: "List Field",
      dataIndex: "list_field",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await ConfigPadraoRequest.getListaConfigPadrao(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setConfigPadrao(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setLinhaSelecionada(null);
  };

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  return (
    <Modal
      width={800}
      title="Selecionar Config Grupo"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "100%" }}
      okButtonProps={{ disabled: !linhaSelecionada }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="descricao" label="Descrição">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="tabela" label="Tabela">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={12} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="small"
        rowKey={"id"}
        rowSelection={{
          type: "radio",
          onChange: SelecionarLinha,
          selectedRowKeys: linhaSelecionada ? [linhaSelecionada.id] : [],
        }}
        dataSource={ConfigPadrao}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
      />
    </Modal>
  );
}
export default SelConfigPadrao;
