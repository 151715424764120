/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Switch, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import dominiosRequest from "../../Paginas/CadastroDominios/dominioRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

export const SelDominio = ({ visible, onCancel, estadoDominio, id }) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getDominioId();
    }
  }, [id]);

  const postDominio = async (values) => {
    setConfirmLoading(true);
    try {
      const response = await dominiosRequest.postDominios(values, id);
      if (response.status === 201) {
        onCancel();
        estadoDominio((prevState) => [response.data, ...prevState]);
        form.resetFields();
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoDominio((prevState) => prevState.map(cb));
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDominioId = async () => {
    setLoading(true);
    try {
      const response = await dominiosRequest.getDominiosId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          tamanho_coluna_firebird: data.tamanho_coluna_firebird,
          tipo_coluna_firebird: data.tipo_coluna_firebird,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        width={600}
        footer={null}
        title={id ? "Editar" : "Inserir"}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) => postDominio(values))
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                label="Nome domínio:"
                name="nome"
                style={{ width: "100%", padding: "0px 10px 0px 0px" }}
                rules={[{ required: true, message: "Campo obrigatório." }]}
              >
                <Input />
              </Form.Item>

              <div style={{ width: "100%" }}>
                <Form.Item
                  label="Tamanho coluna Firebird:"
                  name="tamanho_coluna_firebird"
                  rules={[
                    { required: true, message: "Campo obrigatório." },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Por favor, insira apenas números.",
                    },
                  ]}
                  style={{ width: "100%", margin: 0 }}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              label="Tipo coluna Firebird:"
              name="tipo_coluna_firebird"
              rules={[{ required: true, message: "Campo obrigatório." }]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Em teste:"
              style={{ marginTop: 10 }}
              name="em_teste"
              valuePropName="checked"
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "30%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Inserir
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>
      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
