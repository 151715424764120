import { Modal } from "antd";
import React, { useState } from "react";
import CartorioRequest from "../CartorioRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoCartorio }) => {
  const [loading, setLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarCartorio = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.ExcluirCartorio(id);
      if (response.status === 204) {
        onCancel();
        estadoCartorio((prevCartorio) =>
          prevCartorio.filter((item) => {
            return item.id !== id;
          })
        );
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarCartorio}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja realmente excluir este cartório?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
