import { Button, Divider, Drawer, Form, Input } from "antd";
import React from "react";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import { colors } from "../../../../../Utils/temas";

export const DrawerFilter = ({
  visible,
  onClose,
  onPesquisarClick,
  fetchRequest,
}) => {
  const [form] = Form.useForm();

  const onClear = () => {
    form.resetFields();
    fetchRequest();
    onClose();
  };

  return (
    <Drawer visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
        onFinish={onPesquisarClick}
      >
        <Form.Item
          name="chave_rotina"
          label="Chave rotina:"
          style={{ fontWeight: "500" }}
        >
          <Input placeholder="Chave rotina" />
        </Form.Item>

        <div>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<FilterOutlined />}
              htmlType="submit"
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
            >
              Filtrar
            </Button>
            <Button icon={<CloseOutlined />} onClick={onClear}>
              Cancelar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
