import { Modal } from "antd";
import React, { useState } from "react";
import { requestReport } from "../../../request";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDeletarRelatorio = ({
  visible,
  onCancel,
  id,
  estadoRelatorio,
}) => {
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const deletarRelatorio = async () => {
    setConfirmLoading(true);
    try {
      const response = await requestReport.deleteReport(id);
      if (response.status === 204) {
        onCancel();
        estadoRelatorio();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarRelatorio}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja deletar este relatório?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
