/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import AtualizacaoRequest from "../../Atualizacao/atualizacao";
import { TabErros } from "./tabs/tab";

export const ModalDetalhes = ({ visible, onCancel, selectedItem }) => {
  const [cartDetalhes, setCartDetalhes] = useState({ nome_cartorio: "" });

  const [erros, setErros] = useState([]);

  const [tipoErro, setTipoErro] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      getDetails();
      getBuscarErro();
    }
  }, [selectedItem]);

  const getDetails = async () => {
    setLoading(true);
    try {
      const response = await AtualizacaoRequest.getAtualizacaoCartId(
        selectedItem
      );
      if (response.status === 200 || response.status === 201) {
        setCartDetalhes(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getBuscarErro = async () => {
    try {
      const response = await AtualizacaoRequest.getBuscarErro(selectedItem);
      if (response.status === 200 || response.status === 201) {
        setErros(response.data);
        setTipoErro(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { TabPane } = Tabs;

  const dicionario = {
    P: "Parametro",
    D: "Dominio",
    T: "Tabela",
    C: "Campo",
    PK: "Primary Key",
    FK: "Foreign Key",
    ID: "Index",
    TG: "Trigger",
    PR: "Procedure",
    VI: "View",
    SE: "Sequences",
    ME: "Menu",
    MI: "Menu Item",
    CO: "Comandos",
  };

  return (
    <Modal visible={visible} onCancel={onCancel} width="80%" footer={null}>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <text style={{ fontWeight: "bold" }}>
            {cartDetalhes.nome_cartorio}
          </text>
          <Tabs
            type="card"
            size="large"
            style={{ padding: 5 }}
            onChange={(e) => setTipoErro(e)}
          >
            {erros.map((item) => (
              <TabPane
                key={item}
                tab={dicionario[item]}
                style={{ marginTop: -16 }}
              >
                <TabErros tipoErro={tipoErro} id={selectedItem} />
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
    </Modal>
  );
};
