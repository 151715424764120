/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { versaoRequest } from "./versaoRequest";
import { Table } from "antd";

export const VersaoCart = ({ id }) => {
  const [versao, setVersao] = useState([
    {
      cartorio_descricao: "",
      cartorio_id: null,
      data_hora_versao: "",
      id: null,
      numero_versao: "",
      numero_versao_anterior: "",
      sistema_descricao: "",
      sistema_id: null,
    },
  ]);

  useEffect(() => {
    getVersaoId();
  }, []);

  const getVersaoId = async () => {
    try {
      const response = await versaoRequest.getVersao({ cartorio_id: id });
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setVersao(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dataFormatada = (data) => {
    const date = new Date(data);
    const dateFormatted = date.toLocaleString().replace(",", "");
    return dateFormatted;
  };

  const columns = [
    {
      title: "Data e hora de versão",
      dataIndex: "data_hora_versao",
      render: (value) => dataFormatada(value),
    },
    { title: "Número versão", dataIndex: "numero_versao" },
    { title: "Sistema", dataIndex: "sistema_descricao" },
  ];

  return <Table columns={columns} dataSource={versao} />;
};
