/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Form, Row, Col, Switch, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import ConfigPadraoValorRequest from "./ConfigPadraoValorRequest";
import SelConfigPadrao from "../../Selecao/ConfigPadrao/Sel_ConfigPadrao";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

function CadastroConfigPadrao({ visible, onCancel, estadoPadraoValor, id }) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalConfigPadraoVisible, setIsModalConfigPadraoVisible] =
    useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getConfigPadraoValorId();
    }
  }, [id]);

  const { Search } = Input;

  async function CadastrarConfigPadraoValor(configPadraoValor) {
    setConfirmLoading(true);
    try {
      const response =
        await ConfigPadraoValorRequest.CadastrarConfigPadraoValor(
          configPadraoValor,
          id
        );
      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        estadoPadraoValor((prevState) => [response.data, ...prevState]);
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        setConfirmLoading(false);
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoPadraoValor((prevState) => prevState.map(cb));
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CadastrarConfigPadraoValor(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const buscarConfigPadraoId = () => {
    setIsModalConfigPadraoVisible(true);
  };

  function onSelecionarConfigPadrao(valor) {
    setIsModalConfigPadraoVisible(false);
    form.setFieldsValue({
      config_padrao_id: valor.id,
      config_padrao_descricao: valor.descricao,
    });
  }

  const getConfigPadraoValorId = async () => {
    setLoading(true);
    try {
      const response = await ConfigPadraoValorRequest.getConfigPadraoValor(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          valor: data.valor,
          config_padrao_id: data.config_padrao_id,
          config_padrao_descricao: data.config_padrao_descricao,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title="Cadastro"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Row gutter={10}>
              <Col span={0}>
                <Form.Item name="id" />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  rules={[
                    {
                      required: true,
                      message: "Informe a descrição",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="valor"
                  label="Valor"
                  rules={[
                    {
                      required: true,
                      message: "Informe o valor",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Col span={24}>
              <Form.Item name="config_padrao_id">
                <Form.Item
                  name="config_padrao_descricao"
                  label="Config padrão"
                  rules={[
                    {
                      required: true,
                      message: "Informe o padrão",
                    },
                  ]}
                  style={{ margin: 0 }}
                >
                  <Search readOnly allowClear onSearch={buscarConfigPadraoId} />
                </Form.Item>
              </Form.Item>
            </Col>

            <Form.Item
              name="em_teste"
              label="Em teste"
              style={{ margin: 0 }}
              valuePropName="checked"
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>
          </Form>
        )}
        <SelConfigPadrao
          onSelecionado={(valor) => {
            onSelecionarConfigPadrao(valor);
          }}
          visible={isModalConfigPadraoVisible}
          onCancel={() => setIsModalConfigPadraoVisible(false)}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroConfigPadrao;
