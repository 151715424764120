/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Table,
  Tag,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { colors } from "../../../Utils/temas";
import TabelaRequest from "../tabelaRequest";
import { ModalAddFields } from "../components/modalAddFields";
import { ModalDeleteCampo } from "../components/modalDeleteCampo";
import { ModalCreatePk } from "./componentes/modal";
const { Group } = Radio;

export const TabCampos = ({ tabelaId }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [errorExist, setErrorExist] = useState(null);

  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCreatePk, setOpenCreatePk] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filtros, setFiltros] = useState({});

  const [classes, setClasses] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (tabelaId) {
      getCampos(1, 10, filtros);
    }
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Nome domínio",
      dataIndex: "nome_dominio",
      key: "nome_dominio",
    },
    {
      title: "Nome tabela",
      dataIndex: "nome_tabela",
      key: "nome_tabela",
    },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      key: "em_teste",
      render: (valor) => (
        <Tag color={valor ? "green" : "red"}>{valorFormat(valor)}</Tag>
      ),
    },
    {
      title: "Not Null",
      dataIndex: "not_null",
      key: "not_null",
      render: (valor) => (
        <Tag color={valor ? "green" : "red"}>{valorFormat(valor)}</Tag>
      ),
    },
    {
      title: "Ações",
      key: "id",
      render: (value) => {
        return (
          <Space>
            <EditOutlined onClick={() => getValuesEdit(value.id)} />
            <DeleteOutlined onClick={() => getValues(value.id)} />
          </Space>
        );
      },
    },
  ];

  const valorFormat = (valor) => {
    if (valor) {
      return "Sim";
    }
    return "Não";
  };

  const getValues = (value) => {
    setIdDelete(value);
    setOpenModalDelete(true);
  };

  const getValuesEdit = (value) => {
    setOpenModal(true);
    setId(value);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const getColors = () => {
            if (key === "nome") {
              return "geekblue";
            } else if (key === "dominio_nome") {
              return "volcano";
            }
          };

          const tagsValores = {
            true: "Sim",
            false: "Não",
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  color={
                    key === "em_teste"
                      ? value
                        ? "green"
                        : "red"
                      : getColors(key)
                  }
                  key={key}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? tagsValores[value] : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  const confirm = () => {
    Modal.success({
      content: "Registro gravado com sucesso.",
    });
  };

  const criarPk = async () => {
    const dados = {
      campo_id: selectedRow,
    };
    try {
      const response = await TabelaRequest.criarPk(dados);
      if (response.status === 201) {
        confirm();
      }
    } catch (error) {
      setErrorExist(error.response.data.detail);
    } finally {
      setOpenCreatePk(false);
    }
  };

  const handleRowExpand = (record) => {
    if (selectedRow === record.id) {
      setSelectedRow(null);
      setExpandedRowKey(null);
      setErrorExist(null);
    } else {
      setSelectedRow(record.id);
      setExpandedRowKey(record.id);
    }
  };

  const tags = {
    nome: "Nome",
    em_teste: "Em teste",
    dominio_nome: "Dominio",
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const onPesquisarClick = (value) => {
    setFiltros(value);
    setDrawer(false);
  };

  const limpar = () => {
    setFiltros([]);
    form.resetFields();
    setDrawer(false);
    getCampos(1, 10, {});
  };

  const getCampos = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await TabelaRequest.getClasseId(
        tabelaId,
        page,
        pageSize,
        params
      );

      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setClasses(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={classes}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => getCampos(page, pageSize, filtros),
        }}
        loading={loading}
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setOpenModal(true)}
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
            >
              <PlusOutlined />
              Incluir
            </Button>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
                marginLeft: 10,
              }}
              onClick={() => setDrawer(true)}
              className="botao"
              icon={<SearchOutlined />}
            >
              Filtros
            </Button>

            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        rowKey={"id"}
        expandable={{
          expandedRowRender: (record) => {
            if (record.id === expandedRowKey) {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 100,
                  }}
                >
                  <Button onClick={() => setOpenCreatePk(true)}>
                    Criar PK
                  </Button>
                  {errorExist && (
                    <text style={{ color: "red" }}>{errorExist}</text>
                  )}
                </div>
              );
            }
            return null;
          },
          expandedRowKeys: [expandedRowKey],
          onExpand: (expanded, record) => {
            handleRowExpand(record);
          },
        }}
      />

      <ModalAddFields
        onCancel={() => {
          setOpenModal(false);
          setId(null);
        }}
        visible={openModal}
        tabela_id={tabelaId}
        estadoCampo={setClasses}
        id={id}
      />

      <Drawer visible={drawer} onClose={() => setDrawer(false)} title="Filtros">
        <Form
          form={form}
          layout="vertical"
          onFinish={onPesquisarClick}
          style={{ fontWeight: "500", height: "70%" }}
        >
          <Form.Item name="nome" label="Campo:">
            <Input />
          </Form.Item>

          <Form.Item name="dominio_nome" label="Domínio:">
            <Input />
          </Form.Item>

          <Form.Item
            name="em_teste"
            label="Em teste:"
            style={{ margin: 0, fontWeight: "500" }}
          >
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <SearchOutlined /> Pesquisar
              </Button>

              <Button onClick={limpar} icon={<ClearOutlined />}>
                Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <ModalDeleteCampo
        visible={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        id={idDelete}
        estadoCampo={setClasses}
      />

      <ModalCreatePk
        visible={openCreatePk}
        onCancel={() => setOpenCreatePk(false)}
        onOk={criarPk}
      />
    </>
  );
};
