import { useEffect, useState, React } from "react";
import {
  Button,
  Drawer,
  Form,
  Space,
  Table,
  Col,
  Row,
  Input,
  Tag,
  Radio,
  Divider,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import GrupoFrasesRequest from "./GrupoFrasesRequest";
import CadastroGrupoFrases from "./CadastroGrupoFrases";
import { ModalDelete } from "./modais/modalDelete";
import { colors } from "../../Utils/temas";

const GrupoFrases = () => {
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form_pesquisa] = Form.useForm();

  const [filtros, setFiltros] = useState([]);
  const [GrupoFrases, setGrupoFrases] = useState([]);

  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [GrupoFrasesId, setGrupoFrasesId] = useState(null);

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await GrupoFrasesRequest.getListaGrupoFrases(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setGrupoFrases(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  const OnCancel = () => {
    setIsModalVisible(false);
    setGrupoFrasesId(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  async function onEditGrupoFrases(id) {
    setGrupoFrasesId(id);
    showModal();
  }

  function onDeletarGrupoFrases(id) {
    setIdDelete(id);
    setOpenDelete(true);
  }

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Dia Inicio",
      dataIndex: "dia_inicio",
    },
    {
      title: "Dia Fim",
      dataIndex: "dia_fim",
    },
    {
      title: "Mês",
      dataIndex: "mes",
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
      render: (situacao) => {
        let situacao_descricao = "";
        let color = "";

        if (situacao === "A") {
          situacao_descricao = "Ativo";
          color = "green";
        } else {
          situacao_descricao = "Inativo";
          color = "red";
        }
        return <Tag color={color}> {situacao_descricao} </Tag>;
      },
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditGrupoFrases(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarGrupoFrases(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tags = {
    descricao: "Descrição",
    situacao: "Situação",
  };

  const onRemoverFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
    FetchRecords(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagColors = () => {
            if (key === "descricao") return "purple";
            else return "magenta";
          };

          const tagsValue = () => {
            if (key === "situacao") {
              if (value === "I") {
                return "Inativo";
              } else return "Ativo";
            } else return value;
          };

          return (
            <Tag
              closable
              key={key}
              onClose={() => onRemoverFiltros(key)}
              color={tagColors(key)}
            >
              <text style={{ padding: 10 }}>
                {tags[key] + ": " + tagsValue(value)}
              </text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={600}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <Form.Item name="descricao" label="Descrição">
              <Input />
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <Form.Item name="situacao" label="Situação">
              <Radio.Group>
                <Radio value={"A"}>Ativo</Radio>
                <Radio value={"I"}>Inativo</Radio>
              </Radio.Group>
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <Row gutter={10}>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  style={{
                    background: colors.secondary,
                    color: "white",
                    fontWeight: "500",
                  }}
                  className="botao"
                >
                  <KeyOutlined />
                  Pesquisar
                </Button>
              </Col>

              <Col span={5} push={7}>
                <Button onClick={Limpar}>
                  <CloseOutlined />
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={GrupoFrases}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
      />
      <CadastroGrupoFrases
        visible={isModalVisible}
        onCancel={OnCancel}
        estadoGrupo={setGrupoFrases}
        id={GrupoFrasesId}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={idDelete}
        estadoFrase={setGrupoFrases}
      />
    </div>
  );
};

export default GrupoFrases;
