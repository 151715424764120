import { Modal } from "antd";
import React, { useState } from "react";
import TabelaRequest from "../../tabelaRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, tabelaId, tabelaState }) => {
  const [loading, setLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarTabela = async () => {
    setLoading(true);
    try {
      const response = await TabelaRequest.deleteTabela(tabelaId);
      if (response.status === 204) {
        onCancel(false);
        setLoading(false);
        tabelaState((prevState) =>
          prevState.filter((item) => item.id !== tabelaId)
        );
        setLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarTabela}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "600", fontSize: 16 }}>
          Deseja realmente excluir esse cartório?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
