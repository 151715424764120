/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaKey } from "react-icons/fa";
import {
  Button,
  Calendar,
  DatePicker,
  Divider,
  Input,
  Modal,
  Select,
  Skeleton,
} from "antd";
import CartorioRequest from "../CartorioRequest";
import moment from "moment";

export const ModalBlock = ({ visible, onCancel, id, atualizarEstados }) => {
  const [cartorio, setCartorio] = useState({
    nome_cartorio: "",
    cpfcnpj: "",
    cidade: "",
    uf: "",
    bloqueio: false,
    chave: null,
    dia_vencimento: null,
    data_vencimento: null,
    data_liberacao: moment(new Date()),
    data_bloqueio: null,
  });

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorExist, setErrorExist] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (id) {
      getCartorio();
    }
  }, [id]);

  const getCartorio = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.getCartorio(id);
      if (response.status === 200) {
        setCartorio((prevState) => ({
          ...response.data,
          data_liberacao: cartorio.data_liberacao,
        }));
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    const dateMoment = moment().set("date", value);

    setCartorio((prevState) => ({
      ...prevState,
      dia_vencimento: value,
      data_vencimento: dateMoment.toISOString(),
    }));
  };

  const handleDateChange = (date) => {
    if (date === null) {
      setCartorio((prevState) => ({
        ...prevState,
        dia_vencimento: null,
        data_vencimento: null,
      }));
    }

    if (date) {
      setCartorio((prevState) => ({
        ...prevState,
        dia_vencimento: date.date(),
        data_vencimento: moment(date).toISOString(),
      }));
    }
  };

  const handleDateValidity = (e) => {
    const { value } = e.target;

    const dataVencimento = moment(cartorio.data_liberacao);
    dataVencimento.add(value - 1, "days");

    setCartorio((prevState) => ({
      ...prevState,
      dias_validade: value,
      data_bloqueio: dataVencimento.toISOString(),
    }));
  };

  const handleKeyDown = (e) => {
    const keyCode = e.keyCode || e.which;

    if (
      (keyCode < 48 || keyCode > 57) &&
      keyCode !== 8 &&
      keyCode !== 46 &&
      keyCode !== 144 &&
      (keyCode < 96 || keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const formattDate = (date) => {
    const data = new Date(date);

    return data.toLocaleDateString();
  };

  const formattReplaceDate = (data) => {
    const dataFull = new Date(data);
    const dataReplace = dataFull.toLocaleDateString("pt-BR").replace(/\//g, "");

    return dataReplace;
  };

  const handleDateChangeValidity = (date) => {
    setCartorio((prevState) => ({
      ...prevState,
      data_bloqueio: moment(date).toISOString(),
    }));
  };

  const bloquearCartorio = async () => {
    setConfirmLoading(true);
    try {
      const response = await CartorioRequest.putCartorio(id, cartorio);
      if (response.status === 200) {
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...cartorio };
          } else {
            return { ...callback };
          }
        };
        atualizarEstados((prevState) => prevState.map(cb));
        setConfirmLoading(false);
        setErrorMessage("");
      }
    } catch (error) {
      const { dia_vencimento } = error.response.data;
      setErrorMessage(dia_vencimento[0]);
      setConfirmLoading(false);
    }
  };

  const returnZerosLeft = (values) => {
    if (values.length === 1) {
      return `00${values}`;
    } else if (values.length === 2) {
      return `0${values}`;
    } else {
      return values;
    }
  };

  const formValid = () => {
    const {
      dia_vencimento,
      data_vencimento,
      data_liberacao,
      data_bloqueio,
      dias_validade,
      cpfcnpj,
      chave,
    } = cartorio;

    const campoPreenchido = (campo) =>
      campo !== "" && campo !== null && campo !== undefined;

    return [
      dia_vencimento,
      data_vencimento,
      data_liberacao,
      data_bloqueio,
      dias_validade,
      cpfcnpj,
      chave,
    ].every(campoPreenchido);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel();
        setErrorExist(false);
        setErrorMessage("");
      }}
      title="Gerar liberação de Sistemas"
      width={1000}
      bodyStyle={{ background: "#F0F0F0" }}
      onOk={() => {
        if (formValid() && cartorio.chave.length === 34) {
          bloquearCartorio();
        } else {
          setErrorExist(true);
        }
      }}
      confirmLoading={confirmLoading}
    >
      <text style={{ fontWeight: "500" }}>Informações</text>
      <div style={{ marginTop: 10, display: "flex" }}>
        <div style={{ width: 800 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Bloquear:</text>
              <Select
                style={{ width: 140 }}
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
                value={cartorio.bloqueio}
                onChange={(e) =>
                  setCartorio((prevState) => ({ ...prevState, bloqueio: e }))
                }
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Dia Vencimento:</text>
              <Input
                style={{ width: 140 }}
                value={cartorio.dia_vencimento}
                onChange={handleInputChange}
                maxLength={2}
                onKeyDown={handleKeyDown}
              />
              {errorExist && !cartorio.dia_vencimento && (
                <text style={{ fontWeight: "400", color: "red" }}>
                  Este campo é obrigatório!
                </text>
              )}
              {errorMessage && (
                <text style={{ fontWeight: "400", color: "red" }}>
                  {errorMessage}
                </text>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Data Vencimento:</text>
              <DatePicker
                style={{ width: 140 }}
                format="DD/MM/YYYY"
                value={
                  cartorio.data_vencimento && moment(cartorio.data_vencimento)
                }
                onChange={handleDateChange}
              />
              {errorExist && !cartorio.data_vencimento && (
                <text style={{ fontWeight: "400", color: "red" }}>
                  Este campo é obrigatório!
                </text>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "80%",
              marginTop: 10,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Data liberação:</text>
              <DatePicker
                style={{ width: 140 }}
                format="DD/MM/YYYY"
                disabled
                value={moment(cartorio.data_liberacao)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Dias Validade:</text>
              <Input
                style={{ width: 140 }}
                onChange={handleDateValidity}
                value={cartorio.dias_validade}
                maxLength={2}
                onKeyDown={handleKeyDown}
              />
              {(errorExist && cartorio.dias_validade === null) ||
              (errorExist && cartorio.dias_validade === undefined) ||
              (errorExist && cartorio.dias_validade === "") ? (
                <text style={{ fontWeight: "400", color: "red" }}>
                  Este campo é obrigatório.
                </text>
              ) : null}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <text style={{ fontWeight: "500" }}>Dt. Bloqueio:</text>
              <DatePicker
                style={{ width: 140 }}
                format="DD/MM/YYYY"
                value={cartorio.data_bloqueio && moment(cartorio.data_bloqueio)}
                onChange={handleDateChangeValidity}
              />
              {errorExist && !cartorio.data_bloqueio && (
                <text style={{ fontWeight: "400", color: "red" }}>
                  Este campo é obrigatório!
                </text>
              )}
            </div>
          </div>

          <Divider />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <text style={{ fontWeight: "500" }}>Chave do sistema:</text>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "38%",
              }}
            >
              <Button
                style={{ width: 100 }}
                onClick={() => {
                  if (
                    cartorio.dia_vencimento !== "" &&
                    cartorio.data_vencimento !== null &&
                    cartorio.data_liberacao !== null &&
                    cartorio.data_bloqueio !== null &&
                    cartorio.dias_validade !== null &&
                    cartorio.dias_validade !== undefined &&
                    cartorio.dias_validade !== ""
                  ) {
                    setCartorio((prevState) => ({
                      ...prevState,
                      chave: `${cartorio.bloqueio ? "S" : "N"}${
                        cartorio.cpfcnpj === null ? "" : cartorio.cpfcnpj
                      }${
                        cartorio.dias_validade
                          ? returnZerosLeft(cartorio.dias_validade)
                          : ""
                      }${
                        cartorio.data_liberacao
                          ? formattReplaceDate(cartorio.data_liberacao)
                          : ""
                      }${
                        cartorio.data_bloqueio
                          ? formattReplaceDate(cartorio.data_bloqueio)
                          : ""
                      }`,
                    }));
                  } else {
                    setErrorExist(true);
                  }
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: 74,
                    fontWeight: "500",
                  }}
                >
                  <FaKey size={20} />
                  <text>Código</text>
                </div>
              </Button>
            </div>

            <Input
              style={{ marginTop: 10 }}
              value={cartorio.chave}
              onChange={(e) =>
                setCartorio((prevState) => ({
                  ...prevState,
                  chave: e.target.value,
                }))
              }
              maxLength={34}
              readOnly
            />
            {errorExist && cartorio.chave === null ? (
              <text style={{ fontWeight: "400", color: "red" }}>
                Este campo é obrigatório!
              </text>
            ) : null}

            {errorExist && cartorio.cpfcnpj === null ? (
              <text style={{ fontWeight: "400", color: "red" }}>
                Desculpe, não encontramos nenhum CPF/CNPJ vinculado a este
                cartório.
              </text>
            ) : null}
          </div>
        </div>

        <div style={{ marginLeft: 20, marginTop: -24, width: "60%" }}>
          <Calendar fullscreen={false} />
        </div>
      </div>

      <Divider />

      <div>
        <text style={{ fontWeight: "500" }}>Cartório</text>
        {loading ? (
          <Skeleton />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#FFFFFF",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Cartório:</text>
              </div>

              <text style={{ paddingLeft: 10, fontWeight: "500" }}>
                {cartorio.nome_cartorio}
              </text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>CPF/CNPJ:</text>
              </div>
              <text style={{ paddingLeft: 10, fontWeight: "500" }}>
                {cartorio.cpfcnpj}
              </text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Cidade:</text>
              </div>
              <text style={{ paddingLeft: 10 }}>{cartorio.cidade}</text>
            </div>

            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>UF:</text>
              </div>

              <text style={{ paddingLeft: 10 }}>{cartorio.uf}</text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Bloqueio:</text>
              </div>
              <text style={{ paddingLeft: 10 }}>
                {cartorio.bloqueio ? "Sim" : "Não"}
              </text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Chave:</text>
              </div>
              <text style={{ paddingLeft: 10 }}>{cartorio.chave}</text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F1F1F1" }}>
                <text>Dia Vencimento:</text>
              </div>

              <text style={{ paddingLeft: 10 }}>{cartorio.dia_vencimento}</text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Dt. Vencimento:</text>
              </div>

              <text style={{ paddingLeft: 10 }}>
                {cartorio.data_vencimento &&
                  formattDate(cartorio.data_vencimento)}
              </text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Dt. Liberação</text>
              </div>

              <text style={{ paddingLeft: 10 }}>
                {cartorio.data_liberacao &&
                  formattDate(cartorio.data_liberacao)}
              </text>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F1F1F1",
                padding: "0px 10px 0px 10px",
                display: "flex",
              }}
            >
              <div style={{ width: 110, borderRight: "1px solid #F2F2F2" }}>
                <text>Dt. Bloqueio:</text>
              </div>

              <text style={{ paddingLeft: 10 }}>
                {cartorio.data_bloqueio && formattDate(cartorio.data_bloqueio)}
              </text>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
