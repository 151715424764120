import { Button, Divider, Drawer, Input, Select } from "antd";
import React, { useState } from "react";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import { colors } from "../../../../../Utils/temas";
import SelCartorio from "../../../../../Selecao/Cartorio/Sel_Carotorio";

const { Search } = Input;

export const DrawerFilter = ({ visible, onClose, filtrar, usuarios }) => {
  const [filtros, setFiltros] = useState({
    cartorio_id: null,
    usuario_id: null,
    nome_cartorio: "",
  });

  const [openSelCart, setOpenSelCart] = useState(false);

  return (
    <Drawer visible={visible} onClose={onClose} title="Filtros">
      <SelCartorio
        visible={openSelCart}
        onCancel={() => setOpenSelCart(false)}
        onSelecionado={(e) => {
          setFiltros((prevState) => ({
            ...prevState,
            cartorio_id: e.id,
            nome_cartorio: e.nome_cartorio,
          }));
          setOpenSelCart(false);
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <>
            <text style={{ fontWeight: "500" }}>Nome usuário:</text>
            <Select
              showSearch
              options={usuarios.map((item) => ({
                label: item.nome,
                value: item.id,
              }))}
              filterOption={(input, option) =>
                (option.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              style={{ width: "100%" }}
              onChange={(value) =>
                setFiltros((prevState) => ({ ...prevState, usuario_id: value }))
              }
              value={filtros.usuario_id}
            />
          </>

          <div style={{ marginTop: 10 }}>
            <text style={{ fontWeight: "500" }}>Cartorio:</text>
            <Search
              onSearch={() => setOpenSelCart(true)}
              value={filtros.nome_cartorio}
            />
          </div>
        </div>

        <div>
          <Divider style={{ width: "100%" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<FilterOutlined />}
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              onClick={() =>
                filtrar({
                  cartorio_id: filtros.cartorio_id,
                  usuario_id: filtros.usuario_id,
                  nome_cartorio: filtros.nome_cartorio,
                })
              }
            >
              Filtrar
            </Button>
            <Button
              icon={<CloseOutlined />}
              onClick={() => {
                onClose();
                setFiltros({
                  cartorio_id: null,
                  nome_cartorio: "",
                  usuario_id: null,
                });
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
