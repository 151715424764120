import { Modal } from "antd";
import React, { useState } from "react";
import EmolumentoRequest from "../EmolumentoRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoEmolumento }) => {
  const [loading, setLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarEmolumento = async () => {
    setLoading(true);
    try {
      const response = await EmolumentoRequest.ExcluirEmolumento(id);
      if (response.status === 204) {
        estadoEmolumento((prevEmolu) =>
          prevEmolu.filter((item) => item.id !== id)
        );
        onCancel();
        setLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarEmolumento}
        confirmLoading={loading}
      >
        <text style={{ fontSize: 16, fontWeight: "500" }}>
          Deseja realmente excluir esse emolumento?
        </text>
      </Modal>
      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
