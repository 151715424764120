import { Button, Modal } from "antd";
import React, { useState } from "react";
import versaoHistorico from "../../versao";
import { colors } from "../../../../Utils/temas";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({ onCancel, visible, id, onSave }) => {
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const deletarVersao = async () => {
    try {
      const response = await versaoHistorico.deleteVersao(id);
      if (response.status === 204) {
        onCancel();

        onSave((prevState) => {
          return prevState.filter((versaoValue) => versaoValue.id !== id);
        });
        setOpenModalStatus(true);
        setConfirmLoading(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 18 }}>
          Deseja deletar esta versão?
        </text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
            }}
          >
            <Button onClick={onCancel}>Cancelar</Button>
            <Button
              onClick={deletarVersao}
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
