import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getTabelas = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = urlBase + "tabela/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
};
const getDominios = async () => {
  const url = urlBase + "dominio/";
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};
const getTabelaId = async (id) => {
  const url = urlBase + `tabela/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};
const getClasseId = async (id, page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url =
    urlBase + `campo/?tabela_id=${id}&limit=${pageSize}&offset=${skip}`;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
};

const postTabela = async (data, id) => {
  const url = id ? `${urlBase}tabela/${id}` : `${urlBase}tabela/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};
const postCampo = async (data, id) => {
  const url = id ? `${urlBase}campo/${id}/` : `${urlBase}campo/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};
const criarPk = async (data) => {
  const url = `${urlBase}campo/criar-pk/`;
  const config = getConfig();
  const response = await axios.post(url, data, config);
  return response;
};

const deleteTabela = async (id) => {
  const url = urlBase + `tabela/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config, id);
  return response;
};
const deleteCampo = async (id) => {
  const url = urlBase + `campo/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config, id);
  return response;
};

const putCampo = async (id, data) => {
  const url = urlBase + `campo/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config, id);
  return response;
};

const putTabela = async (id, dados) => {
  const url = urlBase + `tabela/${id}`;
  const config = getConfig();
  const response = await axios.put(url, dados, config, id);
  return response;
};

const getCampoId = async (id) => {
  const url = `${urlBase}campo/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const putEmTeste = async (params) => {
  const url = `${urlBase}tabela/alterar-em_teste-tabela`;
  const config = getConfig(params);
  const response = await axios.put(url, {}, config);
  return response;
};

const TabelaRequest = {
  criarPk,
  putCampo,
  postCampo,
  putTabela,
  postTabela,
  getTabelas,
  getCampoId,
  putEmTeste,
  getDominios,
  getTabelaId,
  getClasseId,
  deleteCampo,
  deleteTabela,
};

export default TabelaRequest;
