import { Modal } from "antd";
import React, { useState } from "react";
import EmolumentoPeriodoRequest from "../EmolumentoPeriodoRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoEmolumentoPer }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarEmolumentoPeriodo = async () => {
    setConfirmLoading(true);
    try {
      const response = await EmolumentoPeriodoRequest.ExcluirEmolumentoPeriodo(
        id
      );
      if (response.status === 204) {
        estadoEmolumentoPer((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        onCancel();
        setConfirmLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarEmolumentoPeriodo}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 17 }}>
          Deseja deletar este Emolumento Período?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
