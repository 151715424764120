import { Modal } from "antd";
import React, { useState } from "react";
import EmolumentoItemRequest from "../../EmolumentoItemRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({
  id,
  visible,
  onCancel,
  estadoEmolumentoItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteEmolumentoItem = async () => {
    setLoading(true);
    try {
      const response = await EmolumentoItemRequest.deleteEmolumentoItem(id);
      if (response.status === 204) {
        estadoEmolumentoItem();
        onCancel();
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deleteEmolumentoItem}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja deletar este Emolumento Item?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
