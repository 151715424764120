import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import React from "react";
import { colors } from "../../../../../Utils/temas";
const { Item, useForm } = Form;
const { Group } = Radio;

export const DrawerFilter = ({ visible, onClose, filtro, limpar }) => {
  const [form] = useForm();
  return (
    <Drawer title="Filtros" visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{ fontWeight: "500", height: "100%" }}
        onFinish={(e) => filtro(e)}
      >
        <Item name="nome" label="Nome:">
          <Input />
        </Item>
        <Item name="campo_nome" label="Campo:">
          <Input />
        </Item>
        <Item name="em_teste" label="Em teste:">
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Item>

        <div
          style={{
            height: "66%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Divider />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<SearchOutlined />}
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              htmlType="submit"
              className="botao"
            >
              Pesquisar
            </Button>
            <Button
              icon={<ClearOutlined />}
              style={{ fontWeight: "500" }}
              onClick={() => {
                limpar();
                form.resetFields();
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
