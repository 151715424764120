import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import React from "react";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";
import { colors } from "../../../../Utils/temas";
const { Group } = Radio;

export const DrawerFilter = ({
  visible,
  onClose,
  onPesquisarClick,
  fetchRequest,
}) => {
  const [form] = Form.useForm();

  const onClear = () => {
    form.resetFields();
    onClose();
    fetchRequest();
  };

  return (
    <Drawer visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{ height: "100%" }}
        onFinish={onPesquisarClick}
      >
        <Form.Item label="Nome:" style={{ fontWeight: "500" }} name="nome">
          <Input />
        </Form.Item>

        <Form.Item
          label="Em teste"
          name="em_teste"
          style={{ fontWeight: "500" }}
        >
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Form.Item>

        <div
          style={{
            height: "78%",
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<FilterOutlined />}
              htmlType="submit"
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
            >
              Filtrar
            </Button>
            <Button icon={<ClearOutlined />} onClick={onClear}>
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
