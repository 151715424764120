import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getHeader() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function CadastrarConfigGrupo(configGrupo, id) {
  const url = id ? `${urlBase}config-grupo/${id}` : `${urlBase}config-grupo/`;
  const config = getHeader();
  let response;
  if (id) {
    response = await axios.put(url, configGrupo, config);
  } else {
    response = await axios.post(url, configGrupo, config);
  }
  return response;
}

async function getConfigGrupo(id) {
  const url = urlBase + `config-grupo/${id}/`;
  const config = getHeader();

  const response = await axios.get(url, config);
  return response;
}

async function getListaConfigGrupo(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "config-grupo/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirConfigGrupo(id) {
  const url = urlBase + `config-grupo/${id}/`;
  const config = getHeader();
  const response = await axios.delete(url, config);
  return response;
}

const putConfigGrupo = async (id, data) => {
  const url = `${urlBase}config-grupo/${id}`;
  const config = getHeader();
  const response = await axios.put(url, data, config);
  return response;
};

const ConfigGrupoRequest = {
  CadastrarConfigGrupo,
  getConfigGrupo,
  getListaConfigGrupo,
  ExcluirConfigGrupo,
  putConfigGrupo,
};

export default ConfigGrupoRequest;
