/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  Input,
  Modal,
  Switch,
  Button,
  Divider,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { procedureRequest } from "../../../procedureRequest";
import { colors } from "../../../../../Utils/temas";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";

export const ModalInserir = ({ visible, onCancel, estadoProcedure, id }) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openStatusModal, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getProcedureId();
    }
  }, [id]);

  const inserirProcedure = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await procedureRequest.postProcedure(data, id);
      if (response.status === 201) {
        const { data } = response;
        onCancel();
        estadoProcedure((prevState) => [data, ...prevState]);
        form.resetFields();
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        const { data } = response;
        onCancel();
        form.resetFields();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...data };
          } else {
            return { ...callback };
          }
        };
        estadoProcedure((prevState) => prevState.map(cb));
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { TextArea } = Input;

  const getProcedureId = async () => {
    setLoading(true);
    try {
      const response = await procedureRequest.getProcuredId(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          texto_firebird: data.texto_firebird,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={id ? "Editar" : "Inserir"}
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        width="50%"
        footer={null}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) => inserirProcedure(values))
            }
          >
            <Col span={0}>
              <Form.Item name="id" />
            </Col>
            <Form.Item
              name="nome"
              label="Nome:"
              style={{ fontWeight: "500", margin: 0 }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="texto_firebird"
              label="Texto Firebird:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <TextArea rows={19} />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
              valuePropName="checked"
            >
              <Switch style={{ marginTop: -20 }} />
            </Form.Item>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openStatusModal.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openStatusModal.edit}
      />
    </>
  );
};
