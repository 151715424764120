/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CartorioRequest from "../../../../CartorioRequest";

export const ModalDetalhes = ({ id, visible, onCancel }) => {
  const [conexoes, setConexoes] = useState({
    conexao_id: "",
    conexao_senha: "",
    windows_usuario: "",
    windows_senha: null,
    conexao_obs: "",
    tipo_conexao: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      getConnections();
    }
  }, [id, visible]);

  const getConnections = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.getAcessoRemoto(id);
      if (response.status === 200 || response.status === 201) {
        setConexoes(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { TextArea } = Input;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Detalhes"
      footer={null}
      width={800}
    >
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}

      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              width: "49%",
              flexDirection: "column",
              marginBottom: 10,
            }}
          >
            <text style={{ fontWeight: "500" }}>Tipo conexão</text>
            <Input value={conexoes.tipo_conexao} readOnly />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "49%" }}>
              <text style={{ fontWeight: "500" }}>Conexão id:</text>
              <Input value={conexoes.conexao_id} readOnly />
            </div>

            <div style={{ width: "49%" }}>
              <text style={{ fontWeight: "500" }}>Conexão senha:</text>
              <Input value={conexoes.conexao_senha} readOnly />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div style={{ width: "49%" }}>
              <text style={{ fontWeight: "500" }}>Windows usuário:</text>
              <Input value={conexoes.windows_usuario} readOnly />
            </div>

            <div style={{ width: "49%" }}>
              <text style={{ fontWeight: "500" }}>Windows senha:</text>
              <Input value={conexoes.windows_senha} readOnly />
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <text style={{ fontWeight: "500" }}>Observação</text>
            <TextArea rows={6} value={conexoes.conexao_obs} readOnly />
          </div>
        </>
      )}
    </Modal>
  );
};
