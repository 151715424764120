import { useEffect, useState, React } from "react";
import {
  Table,
  Button,
  Drawer,
  Form,
  Space,
  Input,
  Select,
  Radio,
  Tag,
  Divider,
  Tooltip,
  Tabs,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  KeyOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import CadastroEmolumento from "./CadastroEmolumento";
import EmolumentoRequest from "./EmolumentoRequest";
import { ufs } from "../../Constante/UFS";
import { tipos } from "../../Constante/TipoEmolumento";
import SistemaRequest from "../../Utils/sistemaRequest";
import { ModalDelete } from "./modalDelete";
import { colors } from "../../Utils/temas";

const { TabPane } = Tabs;

const Emolumento = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [emolumento, setEmolumento] = useState([]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);
  const [filtros, setFiltros] = useState({});

  const [totalRecords, setTotalRecords] = useState(0);

  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  const [form_pesquisa] = Form.useForm();
  const [form] = Form.useForm();

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await EmolumentoRequest.getListaEmolumento(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setEmolumento(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function CarregarSistema() {
    const response = await SistemaRequest.getSistemas();
    if (response.status === 200) {
      const { results } = response.data;
      const notSystems = [
        "ADMINISTRATIVO",
        "CAIXA",
        "FINANCEIRO",
        "LIVRO DIÁRIO",
      ];

      const sistemasFiltrados = results.filter(
        (item) => !notSystems.includes(item.nome_sistema)
      );
      setSistemas(sistemasFiltrados);
      setFiltros({ sistema_id: sistemasFiltrados[0].id });
    }
  }

  const optionsTipo = tipos.map((tipo) => {
    return {
      label: tipo.descricao,
      value: tipo.tipo,
    };
  });

  useEffect(() => {
    CarregarSistema();
  }, []);

  useEffect(() => {
    if (filtros.sistema_id) {
      FetchRecords(1, 10, filtros);
    }
  }, [filtros]);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
      ellipsis: true,
      width: 500,
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      render: (tipo) => {
        let tipo_decricao = "";

        if (tipo === "T") {
          tipo_decricao = "Título";
        } else if (tipo === "C") {
          tipo_decricao = "Certidão";
        } else if (tipo === "S") {
          tipo_decricao = "Sinal Público";
        }
        return <Tag color="magenta">{tipo_decricao}</Tag>;
      },
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      render: (situacao) => {
        let situacao_descricao = "";
        let color = "";

        if (situacao === "A") {
          situacao_descricao = "Ativo";
          color = "green";
        } else {
          situacao_descricao = "Inativo";
          color = "red";
        }
        return <Tag color={color}> {situacao_descricao} </Tag>;
      },
    },
    {
      title: "Calcula Outra TX",
      dataIndex: "calcular_valor_outra_taxa1",
      render: (calcular_valor_outra_taxa1) => {
        let calcular_valor_outra_taxa1_descricao = "";
        let color = "";

        if (calcular_valor_outra_taxa1 === true) {
          calcular_valor_outra_taxa1_descricao = "Sim";
          color = "green";
        } else {
          calcular_valor_outra_taxa1_descricao = "Não";
          color = "red";
        }
        return (
          <Tag color={color}> {calcular_valor_outra_taxa1_descricao} </Tag>
        );
      },
    },
    {
      title: "Sistema",
      dataIndex: "sistema_descricao",
    },
    {
      title: "Selo Grupo",
      dataIndex: "selo_grupo_descricao",
    },
    {
      title: "Selo Grupo Isento",
      dataIndex: "selo_grupo_isento_descricao",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditEmolumento(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarEmolumento(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  async function onEditEmolumento(id) {
    showModal();
    setId(id);
  }

  function onDeletarEmolumento(id) {
    setOpen(true);
    setIdDelete(id);
  }

  function onInserirClick() {
    showModal();
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function onPesquisarClick(value) {
    setFiltros((prevState) => ({ ...prevState, ...value }));
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros((prevState) => ({
      situacao: undefined,
      uf: undefined,
      descricao: undefined,
      sistema_id: prevState.sistema_id,
    }));
    form_pesquisa.resetFields();
    setDrawerVisible(false);
  };

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });
  const optionsSistema = sistemas.map((sistema) => {
    return {
      label: sistema.nome_sistema,
      value: sistema.id,
    };
  });

  const tags = {
    descricao: "Descrição",
    situacao: "Situação",
    uf: "UF",
  };

  const onRemoverFiltros = (tag) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];

    setFiltros(novosFiltros);
  };

  const getTagsTitle = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsValores = {
            A: "Ativo",
            I: "Inativo",
          };

          const tagsCores = () => {
            if (value === "I") {
              return "red";
            } else if (value === "A") {
              return "green";
            } else if (key === "uf") return "volcano";
            return "gold";
          };

          if (key === "situacao") {
            return (
              <Tag
                key={key}
                closable
                onClose={() => onRemoverFiltros(key)}
                color={tagsCores(key, tagsValores[value])}
              >
                <text>{tags[key] + ": " + tagsValores[value]}</text>
              </Tag>
            );
          }

          if (key === "sistema_id") {
            return null;
          }

          return (
            <Tag
              key={key}
              closable
              onClose={() => onRemoverFiltros(key)}
              color={tagsCores(key)}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={600}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <Form.Item
              name="descricao"
              label="Descrição"
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group>
                  <Radio value="A">Ativo</Radio>
                  <Radio value="I">Inativo</Radio>
                </Radio.Group>
              </Form.Item>

              <Divider
                type="vertical"
                style={{ height: 80, background: "#DCDCDC" }}
              />

              <Form.Item name="uf" label="UF" style={{ width: "38%" }}>
                <Select options={optionsUf} />
              </Form.Item>
            </div>

            <Divider style={{ background: "#DCDCDC" }} />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>

              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>

      <Tabs
        type="card"
        size="large"
        onChange={(e) =>
          setFiltros((prevState) => ({ ...prevState, sistema_id: e }))
        }
      >
        {sistemas.map((item) => (
          <TabPane tab={item.nome_sistema} key={item.id}>
            <Table
              style={{ marginTop: -18 }}
              loading={loading}
              columns={columns}
              rowKey="id"
              dataSource={emolumento}
              pagination={{
                total: totalRecords,
                onChange: (page, pagaSize) => {
                  FetchRecords(page, pagaSize, filtros);
                },
              }}
              title={getTagsTitle}
            />
          </TabPane>
        ))}
      </Tabs>

      <CadastroEmolumento
        optionsSistema={optionsSistema}
        optionsTipo={optionsTipo}
        optionsUf={optionsUf}
        visible={isModalVisible}
        form={form}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        estadoEmolumento={setEmolumento}
        id={id}
        sistemaId={filtros.sistema_id}
      />

      <ModalDelete
        onCancel={() => setOpen(false)}
        visible={open}
        estadoEmolumento={setEmolumento}
        id={idDelete}
      />
    </div>
  );
};

export default Emolumento;
