import { Button, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { requestReport } from "../../Paginas/Relatorios/request";
import { colors } from "../../Utils/temas";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";

export const SelValor = ({ visible, onCancel, onSelecionado }) => {
  const [relatorios, setRelatorios] = useState([
    {
      id: 0,
      descricao: "",
      link_arquivo: "",
      grupo_relatorio_id: 0,
      grupo_relatorio__descricao: "",
    },
  ]);
  const [gruposRelatorios, setGruposRelatorios] = useState([
    {
      id: 0,
      descricao: "",
      sistema_id: 0,
      sistema_descricao: "",
      em_teste: false,
      usuario_id: 0,
      usuario_nome: "",
      data_alteracao: "",
    },
  ]);

  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [idGrupo, setIdGrupo] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listarRelatorios();
    listarGruposRelatorios();
  }, []);

  const listarRelatorios = async (params) => {
    setLoading(true);
    try {
      const response = await requestReport.getReport(params);
      if (response.status === 200) {
        const { results } = response.data;
        setRelatorios(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const listarGruposRelatorios = async () => {
    try {
      const response = await requestReport.getGroupReport();
      if (response.status === 200) {
        const { results } = response.data;
        setGruposRelatorios(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { title: "Descrição", dataIndex: "descricao" },
    { title: "Sistema", dataIndex: "sistema__nome_sistema" },
  ];

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  const handleOk = () => {
    onSelecionado(linhaSelecionada.descricao, linhaSelecionada.id);
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Selecionar Valor"
      onOk={handleOk}
    >
      <div>
        <text style={{ fontWeight: "500" }}>Relatório Grupo:</text>
        <Select
          style={{ width: "100%" }}
          options={gruposRelatorios.map((item) => ({
            label: item.descricao,
            value: item.id,
          }))}
          onChange={(e) => setIdGrupo(e)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Button
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
          icon={<SearchOutlined />}
          onClick={() => listarRelatorios({ grupo_relatorio_id: idGrupo })}
        >
          Pesquisar
        </Button>

        <Button
          icon={<ClearOutlined />}
          onClick={() => {
            listarRelatorios();
            setIdGrupo(null);
          }}
        >
          Limpar
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={relatorios}
        size="small"
        rowKey="id"
        rowSelection={{ type: "radio", onChange: SelecionarLinha }}
        style={{ marginTop: 10 }}
        loading={loading}
      />
    </Modal>
  );
};
