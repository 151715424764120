/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Select, Form, Row, Col, Switch, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import ConfigGrupoRequest from "./ConfigGrupoRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

function CadastroConfigGrupo({
  id,
  visible,
  onCancel,
  sistemaId,
  optionsSistema,
  estadoConfigGrupo,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getConfigGrupoId();
    }
  }, [id]);

  async function CadastrarConfigGrupo(configGrupo) {
    setConfirmLoading(true);
    try {
      const response = await ConfigGrupoRequest.CadastrarConfigGrupo(
        configGrupo,
        id
      );

      const { data } = response;

      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoConfigGrupo((prevState) => [...prevState]);
        } else {
          estadoConfigGrupo((prevState) => [response.data, ...prevState]);
        }
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoConfigGrupo((prevState) =>
            prevState.filter((item) => item.id !== id)
          );
        } else {
          const cb = (callback) => {
            if (callback.id === id) {
              return { ...callback, ...response.data };
            }
            return { ...callback };
          };
          estadoConfigGrupo((prevState) => prevState.map(cb));
          setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CadastrarConfigGrupo(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  form.setFieldsValue({ sistema_id: parseInt(sistemaId) });

  const getConfigGrupoId = async () => {
    setLoading(true);
    try {
      const response = await ConfigGrupoRequest.getConfigGrupo(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title="Cadastro"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Row gutter={10}>
              <Col span={0}>
                <Form.Item name="id" />
              </Col>
              <Col span={16}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  rules={[
                    {
                      required: true,
                      message: "Informe a descrição",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="sistema_id"
                  label="Sistema"
                  rules={[
                    {
                      required: true,
                      message: "Informe o sistema",
                    },
                  ]}
                >
                  <Select options={optionsSistema} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="em_teste"
                  label="Em teste"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={true} unCheckedChildren={false} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroConfigGrupo;
