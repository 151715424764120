/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Skeleton, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { menuRequest } from "../../../../menuRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../../Utils/modalStatus";

export const ModalInserir = ({
  visible,
  onCancel,
  id,
  estadoPermissao,
  idItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openStatusModal, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (idItem) {
      detalhesMenu();
    }
  }, [idItem]);

  const inserirPermissao = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await menuRequest.postMenuItem(data, idItem);
      if (response.status === 201) {
        onCancel();
        estadoPermissao((prevMenu) => [response.data, ...prevMenu]);
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        const cb = (callback) => {
          if (callback.id === idItem) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoPermissao((prevState) => prevState.map(cb));
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const detalhesMenu = async () => {
    setLoading(true);
    try {
      const response = await menuRequest.getMenuItemId(idItem);
      if (response.status === 200 || response.status === 201) {
        const { descricao, chave_rotina, menu_id, em_teste } = response.data;
        setLoading(false);
        form.setFieldsValue({
          descricao: descricao,
          chave_rotina: chave_rotina,
          menu_id: menu_id,
          em_teste: em_teste,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        footer={null}
        title={id ? "Editar" : "Inserir"}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            style={{ fontWeight: "500" }}
            onFinish={() => {
              form
                .validateFields()
                .then((values) => inserirPermissao({ ...values, menu_id: id }));
            }}
          >
            <Form.Item
              name="descricao"
              label="Descrição:"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Input placeholder="Descrição" />
            </Form.Item>

            <Form.Item
              name="chave_rotina"
              label="Chave rotina:"
              rules={[{ required: true, message: "Este campo é obrigatório" }]}
            >
              <Input placeholder="Chave rotina" />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              valuePropName="checked"
            >
              <Switch
                style={{ marginTop: -20 }}
                checkedChildren={true}
                unCheckedChildren={false}
              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openStatusModal.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openStatusModal.edit}
      />
    </>
  );
};
