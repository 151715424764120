/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  FilterOutlined,
  CloseOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import TabelaRequest from "./tabelaRequest";
import { SelTabela } from "../../Selecao/Tabela/Sel_tabela";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../Utils/temas";
import { useNavigate } from "react-router-dom";
import { PiNotePencil } from "react-icons/pi";
import { ModalChangeTest } from "./components/modalChangeTest";

const { Group } = Radio;

export const CadastroTabela = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openModalDelete, setOpenDelete] = useState(false);
  const [openChangeTest, setOpenChangeTest] = useState(false);

  const [tabelas, setTabelas] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [changeTest, setChangeTest] = useState({
    idTabela: null,
    valorTeste: false,
  });

  const [formFiltros] = Form.useForm();

  const getInitialFilters = () => {
    const savedFilters = localStorage.getItem("tabela_filtros");

    if (savedFilters) return JSON.parse(savedFilters);
    return {};
  };

  const [filtros, setFiltros] = useState(getInitialFilters);

  const [tabelaId, setTabelaId] = useState(null);
  const [tabelaIdEdit, setTabelaIdEdit] = useState(null);

  const navigation = useNavigate();

  useEffect(() => {
    if (filtros) {
      listaTabelas(1, 10, filtros);
      saveFilters();
    }
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Em Teste",
      dataIndex: "em_teste",
      key: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      key: "Ações",
      render: (_, record) => {
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Tooltip title="Detalhes">
              <CopyOutlined
                style={{ marginRight: 10 }}
                onClick={() => getDetails(record)}
              />
            </Tooltip>

            <Tooltip title="Editar">
              <EditOutlined onClick={(_) => onEditId(record.id, record.nome)} />
            </Tooltip>

            <Tooltip title="Alterar em teste">
              <PiNotePencil
                style={{ marginLeft: 10, fontSize: 14, cursor: "pointer" }}
                onClick={() => onChangeTest(record)}
              />
            </Tooltip>

            <Tooltip title="Deletar">
              <DeleteOutlined
                style={{ marginLeft: 10 }}
                onClick={(_) => onDeleteId(record.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const listaTabelas = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await TabelaRequest.getTabelas(page, pageSize, params);
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setTabelas(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPesquisarClick = (values) => {
    setFiltros(values);
    listaTabelas(1, 10, values);
    setOpenFilter(false);
  };

  const limpar = () => {
    setFiltros({});
    formFiltros.resetFields();
    listaTabelas(1, 10, {});
    setOpenFilter(false);
  };

  const onDeleteId = (value) => {
    setTabelaId(value);
    setOpenDelete(true);
  };

  const onEditId = async (id) => {
    setTabelaIdEdit(id);
    setOpenModal(true);
  };

  const onChangeTest = (valor) => {
    setOpenChangeTest(true);
    setChangeTest({ idTabela: valor.id, valorTeste: valor.em_teste });
  };

  const getDetails = (value) => {
    navigation(`/tabela/${value.id}`);
  };

  const tags = {
    nome: "Nome",
  };

  const onCloseTags = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
    listaTabelas(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsColor = () => {
            if (key === "nome") return "orange";
          };

          if (key === "em_teste") {
            return (
              <Tag
                key={key}
                closable
                onClose={() => onCloseTags(key)}
                color={value ? "green" : "red"}
              >
                <text style={{ padding: 10 }}>
                  Em teste: {value ? "Sim" : "Não"}
                </text>
              </Tag>
            );
          }

          if (key === "nome" && value !== "") {
            return (
              <Tag
                closable
                onClose={() => onCloseTags(key)}
                color={tagsColor(key)}
              >
                <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
              </Tag>
            );
          }

          return null;
        })}
    </>
  );

  const saveFilters = () => {
    localStorage.setItem("tabela_filtros", JSON.stringify(filtros));
  };

  return (
    <div>
      <div
        style={{ padding: 5, display: "flex", justifyContent: "space-between" }}
      >
        <Button
          onClick={() => setOpenModal(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() => setOpenFilter(true)}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={tabelas}
        loading={loading}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => {
            listaTabelas(page, pageSize, filtros);
          },
        }}
        title={getTags}
      />

      <Drawer
        title="Filtro"
        placement="right"
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
      >
        <Form
          form={formFiltros}
          layout="vertical"
          preserve={true}
          onFinish={onPesquisarClick}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            fontWeight: "500",
          }}
        >
          <Form.Item name="nome" label="Nome tabela">
            <Input />
          </Form.Item>

          <Form.Item name="em_teste" label="Em teste:" style={{ margin: 0 }}>
            <Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Group>
          </Form.Item>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <SearchOutlined /> Pesquisar
              </Button>
              <Button onClick={limpar}>
                <CloseOutlined /> Limpar
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      <SelTabela
        visible={openModal}
        onCancel={() => {
          setOpenModal(false);
          setTabelaIdEdit(null);
        }}
        id={tabelaIdEdit}
        estadoTabela={setTabelas}
      />

      <ModalDelete
        visible={openModalDelete}
        onCancel={() => setOpenDelete(false)}
        tabelaId={tabelaId}
        tabelaState={setTabelas}
      />

      <ModalChangeTest
        visible={openChangeTest}
        onCancel={() => setOpenChangeTest(false)}
        id={changeTest.idTabela}
        value={changeTest.valorTeste}
        estadoEmTeste={setTabelas}
      />
    </div>
  );
};
