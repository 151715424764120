import axios from "axios";
import { urlBase } from "../../Constante/Url";
import { UserTockem } from "../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const id = localStorage.getItem("user");

const putAtualizarSenha = async (dados) => {
  const url = urlBase + `usuario/alterar-password/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, dados, config);
  return response;
};

const getInfos = async (id) => {
  const url = `${urlBase}usuario/registro/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

export const Usuario = {
  putAtualizarSenha,
  getInfos,
};
