export  const tipoCartorio = [
    {
        tipo: '1',
        descricao: 'Tabelionato de Notas e Registro de Contratos Marítimos'
    },
    {
        tipo: '2',
        descricao: 'Registro de Imóveis'
    },
    {
        tipo: '3',
        descricao: 'Registro Civil de Pessoas Naturais e de Interdições e Tutelas' 
    },
    {
        tipo: '4',
        descricao: 'Registro Civil das Pessoas Jurídicas, De Títulos e Documentos'
    },
    {
        tipo: '5',
        descricao: 'Protesto de Títulos'
    }
];

