import { Modal } from "antd";
import React, { useState } from "react";
import ConfigPadraoRequest from "../../ConfigPadraoRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoConfig }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteConfig = async () => {
    setConfirmLoading(true);
    try {
      const response = await ConfigPadraoRequest.ExcluirConfigPadrao(id);
      if (response.status === 204) {
        estadoConfig((prevState) => prevState.filter((item) => item.id !== id));
        setConfirmLoading(false);
        onCancel();
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deleteConfig}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja realmente excluir essa Config. Padrão?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
