/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumb, Input, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TabelaRequest from "./tabelaRequest";
import { ForeignKey } from "./foreignKey";
import { PrimaryKey } from "./primaryKey";
import { Index } from "./indexSreen";
import { ValoresDefault } from "./valoresDefault";
import { Trigger } from "./trigger";
import { TabCampos } from "./campos";
import { ErrorModal } from "../../Utils/errorModal";

export const DetalhesTabela = () => {
  const { tabelaId } = useParams();

  const [nome, setNome] = useState("");

  const [error, setError] = useState({ open: false, status: null });

  useEffect(() => {
    carregarDetalhes();
  }, []);

  const carregarDetalhes = async () => {
    try {
      const response = await TabelaRequest.getTabelaId(tabelaId);
      const detalhes = response.data;

      if (response.status === 200 || response.status === 201) {
        setNome(detalhes.nome);
      }
    } catch (error) {
      const { status } = error.response;
      setError({ open: true, status: status });
    }
  };

  const { TabPane } = Tabs;

  return (
    <div>
      <Breadcrumb style={{ padding: 10, fontSize: 14, fontWeight: "500" }}>
        <Breadcrumb.Item href="/tabela">Tabelas</Breadcrumb.Item>
        <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ padding: 5 }}>
        <h3>Nome:</h3>
        <Input value={nome} style={{ width: "40%" }} readOnly />
      </div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        size="large"
        style={{ padding: 5 }}
        destroyInactiveTabPane
      >
        <TabPane key="1" tab="Campos" style={{ marginTop: -16 }}>
          <TabCampos tabelaId={tabelaId} />
        </TabPane>

        <TabPane key="2" tab="Primary Key" style={{ marginTop: -16 }}>
          <PrimaryKey id={tabelaId} nomeTabela={nome} />
        </TabPane>

        <TabPane key="3" tab="Foreign Key" style={{ marginTop: -16 }}>
          <ForeignKey id={tabelaId} nomeTabela={nome} />
        </TabPane>
        <TabPane key="4" tab="Index" style={{ marginTop: -16 }}>
          <Index id={tabelaId} nomeTabela={nome} />
        </TabPane>
        <TabPane key="5" tab="ValoresDefault" style={{ marginTop: -16 }}>
          <ValoresDefault id={tabelaId} />
        </TabPane>
        <TabPane key="6" tab="Trigger" style={{ marginTop: -16 }}>
          <Trigger id={tabelaId} />
        </TabPane>
      </Tabs>

      <ErrorModal
        msg="Desculpe, parece que a Tabela que você está procurando não pode ser encontrada. Por favor, verifique a URL e tente novamente."
        onCancel={() => setError({ open: false, status: null })}
        status={error.status}
        visible={error.open}
      />
    </div>
  );
};
