import axios from "axios";
import { UserTockem } from "../../../Utils/autenticacao";
import { urlBase } from "../../../Constante/Url";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getIndex = async (page, pageSize, id, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}Index/?campo_tabela_id=${id}&limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getIndexId = async (id) => {
  const url = `${urlBase}Index/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postIndex = async (data, id) => {
  const url = id ? `${urlBase}Index/${id}` : `${urlBase}Index/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putIndex = async (id, data) => {
  const url = `${urlBase}Index/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteIndex = async (id) => {
  const url = `${urlBase}Index/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const indexRequest = {
  getIndex,
  getIndexId,
  postIndex,
  putIndex,
  deleteIndex,
};
