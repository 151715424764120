import { Button } from "antd";
import React from "react";

export const BotãoSelecionar = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      Selecionar Cartório
    </Button>
  );
};

export const BotãoIncluir = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      Incluir cartórios atualização
    </Button>
  );
};

export const BotãoExecutar = ({ disabled, onClick }) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      Executar
    </Button>
  );
};
