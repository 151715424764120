/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Input,
  Modal,
  Select,
  Switch,
  Form,
  Divider,
  Button,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { ListaTipoValor } from "../../../Constante/TipoValor";
import SelConfigPadrao from "../../../Selecao/ConfigPadrao/Sel_ConfigPadrao";
import { colors } from "../../../Utils/temas";
import { SelValor } from "../../../Selecao/Valor";
import { newConfigRequest } from "../request";
import { ModalStatusCreate, ModalStatusEdit } from "../../../Utils/modalStatus";
import { requestReport } from "../../Relatorios/request";

const { Search } = Input;

export const ModalAdd = ({
  id,
  secao,
  visible,
  idGrupo,
  onCancel,
  editarConfig,
  atualizarLista,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openValue, setOpenValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [valorRelatorio, setValorRelatorio] = useState({
    descricao: "",
    id: null,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ secao: secao });
  }, [secao]);

  useEffect(() => {
    if (id) {
      getConfigId();
    }
  }, [id]);

  const postConfig = async (data) => {
    setConfirmLoading(true);
    try {
      const dadosComGrupoId = { ...data, config_grupo_id: idGrupo };

      const response = await newConfigRequest.CadastrarConfig(
        dadosComGrupoId,
        id
      );

      if (response.status === 201) {
        const { data } = response;
        onCancel();
        form.resetFields([
          "nome",
          "valor",
          "descricao",
          "tipo_valor",
          "marcar_excluir",
          "marcar_atualizar",
          "producao",
          "implantacao",
          "categoria",
          "observacao",
          "config_padrao_id",
          "config_padrao_descricao",
          "config_padrao_tabela",
          "em_teste",
        ]);
        atualizarLista(data);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const { data } = response;
        onCancel();
        form.resetFields([
          "nome",
          "valor",
          "descricao",
          "tipo_valor",
          "marcar_excluir",
          "marcar_atualizar",
          "producao",
          "implantacao",
          "categoria",
          "observacao",
          "config_padrao_id",
          "config_padrao_descricao",
          "config_padrao_tabela",
          "em_teste",
        ]);
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...data };
          }
          return { ...callback };
        };
        editarConfig((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        setValorRelatorio({ descricao: "", id: null });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const optionsTipoValor = ListaTipoValor.map((tipoValor) => ({
    label: tipoValor.descricao,
    value: tipoValor.tipo,
  }));

  const getConfigId = async () => {
    setLoading(true);
    try {
      const response = await newConfigRequest.getConfig(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          secao: data.secao,
          nome: data.nome,
          valor: data.valor,
          descricao: data.descricao,
          tipo_valor: data.tipo_valor,
          marcar_excluir: data.marcar_excluir,
          marcar_atualizar: data.marcar_atualizar,
          producao: data.producao,
          implantacao: data.implantacao,
          categoria: data.categoria,
          observacao: data.observacao,
          config_padrao_id: data.config_padrao_id,
          config_padrao_descricao: data.config_padrao_descricao,
          config_padrao_tabela: data.config_padrao_tabela,
          em_teste: data.em_teste,
        });
        if (data.config_padrao_descricao === "CONFIG_RELATORIO") {
          const listarRelatorios = async (params) => {
            try {
              const response = await requestReport.getReport(params);
              if (response.status === 200) {
                const { results } = response.data;
                const resultadosFiltrados = results.filter(
                  (item) => item.id == data.valor
                );
                setValorRelatorio({
                  descricao: resultadosFiltrados[0].descricao,
                  id: resultadosFiltrados[0].id,
                });
              }
            } catch (error) {
              console.error(error);
            }
          };
          listarRelatorios();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (form.getFieldValue("config_padrao_tabela") === "G_CONFIG_RELATORIO") {
    form.resetFields(["valor"]);
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields([
            "nome",
            "valor",
            "descricao",
            "tipo_valor",
            "marcar_excluir",
            "marcar_atualizar",
            "producao",
            "implantacao",
            "categoria",
            "observacao",
            "config_padrao_id",
            "config_padrao_descricao",
            "config_padrao_tabela",
            "em_teste",
          ]);
          setValorRelatorio({ descricao: "", id: null });
        }}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) => {
                form.getFieldValue("config_padrao_tabela") ===
                "G_CONFIG_RELATORIO"
                  ? postConfig({ ...values, valor: valorRelatorio.id })
                  : postConfig(values);
              })
            }
          >
            <Form.Item
              name="secao"
              label="Seção:"
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Este campo não pode ser em branco.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="nome"
              label="Parâmetro:"
              style={{ fontWeight: "500", margin: 0, padding: 0 }}
              rules={[
                {
                  required: true,
                  message: "Este campo não pode ser em branco.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              style={{ marginTop: 10, fontWeight: "500" }}
              name="descricao"
              label="Descrição do Parâmetro"
              rules={[
                {
                  required: true,
                  message: "Este campo não pode ser em branco.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                style={{ width: "48%", fontWeight: "500" }}
                name="tipo_valor"
                label="Tipo valor:"
                rules={[
                  {
                    required: true,
                    message: "Este campo não pode ser em branco.",
                  },
                ]}
              >
                <Select options={optionsTipoValor} />
              </Form.Item>

              <Form.Item
                name="config_padrao_tabela"
                style={{ width: "48%", fontWeight: "500", marginBottom: 0 }}
              >
                <Form.Item name="config_padrao_id" style={{ marginBottom: 0 }}>
                  <Form.Item name="config_padrao_descricao" label="Padrão">
                    <Search
                      readOnly
                      allowClear
                      onSearch={() => setOpen(true)}
                    />
                  </Form.Item>
                </Form.Item>
              </Form.Item>
            </div>

            {form.getFieldValue("config_padrao_tabela") ===
            "G_CONFIG_RELATORIO" ? (
              <div style={{ paddingBottom: 16 }}>
                <text style={{ fontWeight: "500" }}>Valor</text>
                <Search
                  readOnly
                  allowClear
                  onSearch={() => setOpenValue(true)}
                  value={valorRelatorio.descricao}
                />
              </div>
            ) : (
              <Form.Item
                name="valor"
                label="Valor"
                style={{ fontWeight: "500" }}
              >
                <Input />
              </Form.Item>
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Item
                name="categoria"
                style={{ width: "74%", fontWeight: "500" }}
                label="Categoria:"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="implantacao"
                valuePropName="checked"
                style={{ marginTop: 20 }}
              >
                <Checkbox style={{ fontWeight: "500" }}>Implantação</Checkbox>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
                width: "60%",
              }}
            >
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "500",
                }}
                valuePropName="checked"
                label="Marcar excluir:"
                name="marcar_excluir"
              >
                <Switch
                  style={{ width: 53 }}
                  checkedChildren={true}
                  unCheckedChildren={false}
                />
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "500",
                }}
                valuePropName="checked"
                label="Marcar editar:"
                name="marcar_atualizar"
              >
                <Switch
                  style={{ width: 53 }}
                  checkedChildren={true}
                  unCheckedChildren={false}
                />
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "500",
                }}
                valuePropName="checked"
                label="Em teste:"
                name="em_teste"
              >
                <Switch
                  style={{ width: 53 }}
                  checkedChildren={true}
                  unCheckedChildren={false}
                />
              </Form.Item>
            </div>

            <Divider />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields([
                    "nome",
                    "valor",
                    "descricao",
                    "tipo_valor",
                    "marcar_excluir",
                    "marcar_atualizar",
                    "producao",
                    "implantacao",
                    "categoria",
                    "observacao",
                    "config_padrao_id",
                    "config_padrao_descricao",
                    "config_padrao_tabela",
                    "em_teste",
                  ]);
                  setValorRelatorio({ descricao: "", id: null });
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                  marginLeft: 10,
                }}
                htmlType="submit"
                loading={confirmLoading}
                className="botao"
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}

        <SelConfigPadrao
          visible={open}
          onCancel={() => setOpen(false)}
          onSelecionado={(e) => {
            form.setFieldsValue({
              config_padrao_id: e.id,
              config_padrao_descricao: e.descricao,
              config_padrao_tabela: e.tabela,
            });
            setOpen(false);
          }}
        />
        <SelValor
          onCancel={() => setOpenValue(false)}
          visible={openValue}
          onSelecionado={(descricao, id) => {
            setValorRelatorio({ descricao: descricao, id: id });
          }}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
