import { Modal } from "antd";
import React, { useState } from "react";
import { arquivosRequest } from "../arquivosRequest";
import { ModalStatusDelete } from "../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, estadoArquivos, id }) => {
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletarAtualizacao = async () => {
    setLoading(true);
    try {
      const response = await arquivosRequest.deleteArquivos(id);
      if (response.status === 204) {
        onCancel();
        estadoArquivos((prevArquivos) =>
          prevArquivos.filter((item) => item.id !== id)
        );
        setOpenStatusModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarAtualizacao}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja deletar esta atualização?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
