import { Form, Input } from "antd";
import React from "react";

export const InputForm = ({ name, label }) => {
  if (name === "data_cadastro") {
  }
  return (
    <Form.Item
      name={name}
      label={label}
      style={{ width: "100%", paddingRight: 5 }}
    >
      <Input readOnly />
    </Form.Item>
  );
};
