/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Form, Row, Col, Radio, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import GrupoFrasesRequest from "./GrupoFrasesRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

function CadastroGrupoFrases({ visible, onCancel, estadoGrupo, id }) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    edit: false,
    create: false,
  });

  useEffect(() => {
    if (id) {
      getGrupoFraseId();
    }
  }, [id]);

  const [form] = Form.useForm();

  async function CadastrarGrupoFrases(GrupoFrases) {
    setConfirmLoading(true);
    try {
      const response = await GrupoFrasesRequest.CadastrarGrupoFrases(
        GrupoFrases,
        id
      );
      if (response.status === 201) {
        form.resetFields();
        onCancel();
        estadoGrupo((prevState) => [response.data, ...prevState]);
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        form.resetFields();
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoGrupo((prevState) => prevState.map(cb));
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CadastrarGrupoFrases(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const getGrupoFraseId = async () => {
    setLoading(true);
    try {
      const response = await GrupoFrasesRequest.getGrupoFrases(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          dia_fim: data.dia_fim,
          dia_inicio: data.dia_inicio,
          mes: data.mes,
          situacao: data.situacao,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Row gutter={10}>
              <Col span={0}>
                <Form.Item name="id" />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  rules={[
                    {
                      required: true,
                      message: "Informe a descrição",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="dia_inicio" label="Dia Inicio">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="dia_fim" label="Dia Fim">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="mes" label="Mês">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="situacao" label="Situação">
                  <Radio.Group>
                    <Radio value={"A"}>Ativo</Radio>
                    <Radio value={"I"}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />

      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroGrupoFrases;
