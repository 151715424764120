import { Modal } from "antd";
import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

export const ModalError = ({ text, visible, onClose }) => (
  <Modal visible={visible} onCancel={onClose} footer={null}>
    <div
      style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
    >
      <CloseCircleOutlined style={{ color: "red", fontSize: 36 }} />
      <text style={{ fontWeight: "500", fontSize: 17 }}>{text}</text>
    </div>
  </Modal>
);
