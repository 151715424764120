import { Button, Divider, Drawer, Form, Input } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { colors } from "../../../../../../Utils/temas";

export const FiltrosEstacao = ({ visible, onClose, onFinish, onClear }) => {
  const [chave, setChave] = useState("");

  return (
    <Drawer visible={visible} onClose={onClose}>
      <Form
        onFinish={onFinish}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <text style={{ fontWeight: "500" }}>Chave de liberação:</text>
          <Form.Item name="chave_liberacao">
            <Input value={chave} onChange={(e) => setChave(e.target.value)} />
          </Form.Item>
        </div>

        <div>
          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              htmlType="submit"
            >
              Filtrar
            </Button>

            <Button onClick={onClear} icon={<ClearOutlined />}>
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
