import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function ExcluirCartorio(id) {
  const url = urlBase + `cartorio/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

async function ExcluirAcessoRemoto(id) {
  const url = urlBase + `acesso-remoto/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

async function putCartorio(id, data) {
  const url = urlBase + `cartorio/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
}

async function getCartorio(id) {
  const url = urlBase + `cartorio/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function getEmolumetosPeriodos() {
  const url = urlBase + "emolumento-periodo/";
  const params = {};
  params.situacao = "A";

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
}

async function getListaCartorio(page, pageSize, params) {
  let skip = (page - 1) * pageSize;
  const url = urlBase + "cartorio/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
}

async function getListaAcessoRemoto(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "acesso-remoto/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}
async function getAcessoRemoto(id) {
  const url = `${urlBase}acesso-remoto/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

async function CadastrarCartorio(data, id) {
  const url = id ? `${urlBase}cartorio/${id}/` : `${urlBase}cartorio/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
}

async function CadastrarAcessoRemoto(acessoRemoto, id) {
  const url = id ? `${urlBase}acesso-remoto/${id}` : `${urlBase}acesso-remoto/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, acessoRemoto, config);
  } else {
    response = await axios.post(url, acessoRemoto, config);
  }
  return response;
}

async function listarSistema() {
  const url = `${urlBase}sistema/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

const CartorioRequest = {
  getCartorio,
  putCartorio,
  listarSistema,
  getAcessoRemoto,
  ExcluirCartorio,
  getListaCartorio,
  CadastrarCartorio,
  ExcluirAcessoRemoto,
  getListaAcessoRemoto,
  CadastrarAcessoRemoto,
  getEmolumetosPeriodos,
};

export default CartorioRequest;
