import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

async function getSeloGrupos() {
  const url = urlBase + "selo-grupo/";
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}
async function getEmolumentos() {
  const url = urlBase + "emolumento/";
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}
async function getEmolumento(id) {
  const url = urlBase + `emolumento/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}
async function getListaEmolumento(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "emolumento/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}
const getListaEmolumentoItem = async (page, pageSize, params, id) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}emolumento-item/?emolumento_id=${id}&limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};

const atualizarEmolumento = async (id, data) => {
  const url = `${urlBase}emolumento/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

async function CadastrarEmolumento(emolumento, id) {
  const url = id ? `${urlBase}emolumento/${id}/` : `${urlBase}emolumento/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, emolumento, config);
  } else {
    response = await axios.post(url, emolumento, config);
  }
  return response;
}

async function ExcluirEmolumento(id) {
  const url = urlBase + `emolumento/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const EmolumentoRequest = {
  CadastrarEmolumento,
  getEmolumento,
  getEmolumentos,
  getListaEmolumento,
  getListaEmolumentoItem,
  getSeloGrupos,
  ExcluirEmolumento,
  atualizarEmolumento,
};

export default EmolumentoRequest;
