import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function CadastrarSeloGrupoFilho(seloGrupoFilho, id) {
  const url = id
    ? `${urlBase}selo-grupo-filho/${id}`
    : `${urlBase}selo-grupo-filho/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, seloGrupoFilho, config);
  } else {
    response = await axios.post(url, seloGrupoFilho, config);
  }
  return response;
}

async function getListaSeloGrupoFilho(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url =
    urlBase + "selo-grupo-filho/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function getSeloGrupoFilho(id) {
  const url = urlBase + `selo-grupo-filho/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirSeloGrupoFilho(id) {
  const url = urlBase + `selo-grupo-filho/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const putSeloGrupoFilho = async (id, data) => {
  const url = `${urlBase}selo-grupo-filho/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const SeloGrupoFilhoRequest = {
  CadastrarSeloGrupoFilho,
  getListaSeloGrupoFilho,
  getSeloGrupoFilho,
  ExcluirSeloGrupoFilho,
  putSeloGrupoFilho,
};

export default SeloGrupoFilhoRequest;
