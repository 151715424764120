import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import React from "react";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { colors } from "../../../Utils/temas";
const { Group } = Radio;

export const DrawerFiltro = ({ visible, onClose, onFinish, cleanFilter }) => {
  const [form] = Form.useForm();

  return (
    <Drawer title="Filtro" visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{
          height: "100%",
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="descricao"
          label="Descrição:"
          style={{ fontWeight: "500" }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="em_teste"
          label="Em teste:"
          style={{ fontWeight: "500" }}
        >
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "78%",
          }}
        >
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<SearchOutlined />}
              htmlType="submit"
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
            >
              Confirmar
            </Button>

            <Button
              icon={<ClearOutlined />}
              onClick={() => {
                onClose();
                form.resetFields();
                cleanFilter();
              }}
            >
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
