import axios from "axios";
import { urlBase } from "../../../../Constante/Url";
import { UserTockem } from "../../../../Utils/autenticacao";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getAplicacao = async (params) => {
  const url = `${urlBase}aplicacao/`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};

export const aplicacaoRequest = {
  getAplicacao,
};
