/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { requestReport } from "../../../request";
import { colors } from "../../../../../Utils/temas";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";

const { useForm, Item } = Form;

export const ModalInserir = ({
  visible,
  onCancel,
  estadoRelatorio,
  value,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [errorExist, setErrorExist] = useState({
    open: false,
    message: undefined,
  });
  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getRelatorioGrupo();
    }
  }, [id]);

  const inserirRelatorioGrupo = async (data) => {
    setConfirmLoading(true);
    try {
      const dataPost = {
        descricao: data.descricao,
        sistema_id: value,
      };

      const response = await requestReport.postReportGroup(dataPost, id);
      if (response.status === 201) {
        const { data } = response;
        onCancel();
        form.resetFields();
        setConfirmLoading(false);
        estadoRelatorio((prevState) => [data, ...prevState]);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        form.resetFields();
        setConfirmLoading(false);
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoRelatorio((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      const { detail } = error.response.data;
      setConfirmLoading(false);
      setErrorExist({ open: true, message: detail });
    }
  };

  const getRelatorioGrupo = async () => {
    setLoading(true);
    try {
      const response = await requestReport.getGroupReportId(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({ descricao: data.descricao });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
          setErrorExist({ open: false, message: undefined });
        }}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => inserirRelatorioGrupo(e)}
          >
            <Item
              label="Descrição:"
              name="descricao"
              style={{ fontWeight: "500", margin: 0 }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <Input />
            </Item>
            {errorExist.open && (
              <text style={{ color: "red", fontWeight: "500" }}>
                {errorExist.message}
              </text>
            )}

            <Divider />

            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                  setErrorExist({ open: false, message: undefined });
                }}
                style={{ marginRight: 10 }}
              >
                Cancelar
              </Button>
              <Button
                className="botao"
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
