import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

async function getEmolumento(params) {
  const url = `${urlBase}emolumento/`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
}

async function getEmolumentoPeriodo(params) {
  const url = `${urlBase}emolumento-periodo/`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
}

async function getEmolumentoItem(page, pageSize, params) {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}emolumento-item/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
}

async function getBuscarEmolumento(params) {
  const url = `${urlBase}emolumento/buscar-emolumento`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
}

async function getEmolumentoItemId(id) {
  const url = `${urlBase}emolumento-item/${id}/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

async function postEmolumentoItem(data, id) {
  const url = id
    ? `${urlBase}emolumento-item/${id}/`
    : `${urlBase}emolumento-item/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
}

async function deleteEmolumentoItem(id) {
  const url = `${urlBase}emolumento-item/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const EmolumentoItemRequest = {
  getEmolumento,
  getEmolumentoItem,
  getEmolumentoItemId,
  getBuscarEmolumento,
  getEmolumentoPeriodo,
  postEmolumentoItem,
  deleteEmolumentoItem,
};

export default EmolumentoItemRequest;
