/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Input, Form, Row, Col, Switch, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import ConfigPadraoRequest from "./ConfigPadraoRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

function CadastroConfigPadrao({ visible, onCancel, estadoPadrao, id }) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getConfigPadraoId();
    }
  }, [id]);

  async function CadastrarConfigPadrao(configPadrao) {
    const response = await ConfigPadraoRequest.CadastrarConfigPadrao(
      configPadrao,
      id
    );
    try {
      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        estadoPadrao((prevState) => [response.data, ...prevState]);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoPadrao((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarConfigPadrao(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const getConfigPadraoId = async () => {
    setLoading(true);
    try {
      const response = await ConfigPadraoRequest.getConfigPadrao(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          descricao: data.descricao,
          tabela: data.tabela,
          key_field: data.key_field,
          list_field: data.list_field,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title="Cadastro"
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        destroyOnClose
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Row gutter={10}>
              <Col span={0}>
                <Form.Item name="id" />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="descricao"
                  label="Descrição:"
                  rules={[
                    {
                      required: true,
                      message: "Informe a descrição",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="tabela" label="Tabela:">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="key_field" label="Key field:">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="list_field" label="List field:">
                  <Input />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="em_teste"
                  label="Em teste:"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={true} unCheckedChildren={false} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
}

export default CadastroConfigPadrao;
