/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Menu } from "antd";
import {
  SnippetsOutlined,
  ThunderboltOutlined,
  HomeOutlined,
  DollarOutlined,
  GroupOutlined,
  TeamOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  BookOutlined,
  RedoOutlined,
  TableOutlined,
  LaptopOutlined,
  FileDoneOutlined,
  DeliveredProcedureOutlined,
  FolderViewOutlined,
  SafetyOutlined,
  RetweetOutlined,
  FileSyncOutlined,
  MacCommandOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  FileZipOutlined,
  EyeOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./estilos.css";
import { useUserContext } from "../../contexts/usersContext";

const MenuPrincipal = () => {
  const { currentUser } = useUserContext();

  const items = [
    currentUser.is_superuser ||
    currentUser.is_manager ||
    currentUser.is_financial
      ? {
          label: "Cartorio",
          key: "CartorioMenu",
          icon: <HomeOutlined />,
          children: [
            {
              label: <Link to="/Cartorio">Cartorio</Link>,
              key: "Cartorio",
              icon: <HomeOutlined />,
            },
          ],
        }
      : null,
    currentUser.is_superuser || currentUser.is_manager
      ? {
          label: "Emolumento",
          key: "EmolumentoMenu",
          icon: <DollarOutlined />,
          children: [
            {
              label: "Selo Grupo",
              key: "SeloGrupoMenu",
              icon: <GroupOutlined />,
              children: [
                {
                  label: <Link to="/SeloGrupo">Selo Grupo</Link>,
                  key: "SeloGrupo",
                  icon: <GroupOutlined />,
                },
                {
                  label: <Link to="/SeloGrupoPai">Selo Grupo Pai</Link>,
                  key: "SeloGrupoPai",
                  icon: <TeamOutlined />,
                },
                {
                  label: <Link to="/SeloGrupoFilho">Selo Grupo Filho</Link>,
                  key: "SeloGrupoFilho",
                  icon: <TeamOutlined />,
                },
              ],
            },
            {
              label: <Link to="/EmolumentoPeriodo">Emolumento Período</Link>,
              key: "EmolumentoPeriodo",
              icon: <DollarOutlined />,
            },
            {
              label: <Link to="/Emolumento">Emolumento</Link>,
              key: "Emolumento",
              icon: <DollarOutlined />,
            },
            {
              label: <Link to="/EmolumentoItem">Emolumento Item</Link>,
              key: "EmolumentoItem",
              icon: <DollarOutlined />,
            },
          ],
        }
      : null,

    currentUser.is_superuser || currentUser.is_manager
      ? {
          label: "Sistema",
          key: "SistemaMenu",
          icon: <LaptopOutlined />,
          children: [
            {
              label: "Config",
              key: "ConfigMenu",
              icon: <ToolOutlined />,
              children: [
                {
                  label: <Link to="/Config">Config</Link>,
                  key: "Config",
                  icon: <ToolOutlined />,
                },
                {
                  label: <Link to="/ConfigGrupo">Config Grupo</Link>,
                  key: "ConfigGrupo",
                  icon: <UsergroupAddOutlined />,
                },
                {
                  label: <Link to="/ConfigPadrao">Config Padrão</Link>,
                  key: "ConfigPadrao",
                  icon: <ToolOutlined />,
                },
                {
                  label: (
                    <Link to="/ConfigPadraoValor">Config Padrão Valor</Link>
                  ),
                  key: "ConfigPadraoValor",
                  icon: <ToolOutlined />,
                },
              ],
            },
            {
              label: <Link to="/Permissoes">Permissões</Link>,
              key: "Permissoes",
              icon: <SafetyOutlined />,
            },
            {
              label: "Frases",
              Key: "FrasesMenu",
              icon: <SnippetsOutlined />,
              children: [
                {
                  label: <Link to="/Frase">Frase</Link>,
                  key: "Frase",
                  icon: <BookOutlined />,
                },
                {
                  label: <Link to="/GrupoFrase">Grupo</Link>,
                  key: "Grupo",
                  icon: <UsergroupAddOutlined />,
                },
              ],
            },
            {
              label: <Link to="/Versao-historico">Versão Histórico</Link>,
              key: "Verso Histórico",
              icon: <FileDoneOutlined />,
            },
          ],
        }
      : null,
    currentUser.is_superuser && {
      label: "Banco de Dados",
      key: "BancoMenu",
      icon: <DatabaseOutlined />,
      children: [
        {
          label: <Link to="/Dominio">Domínio</Link>,
          key: "Dominio",
          icon: <LaptopOutlined />,
        },
        {
          label: <Link to="/Tabela">Tabela</Link>,
          key: "Tabela",
          icon: <TableOutlined />,
        },
        {
          label: <Link to="/Procedure">Procedure</Link>,
          key: "Procedure",
          icon: <DeliveredProcedureOutlined />,
        },
        {
          label: <Link to="/View">View</Link>,
          key: "View",
          icon: <FolderViewOutlined />,
        },
        {
          label: <Link to="/Comandos">Comandos</Link>,
          key: "Comandos",
          icon: <MacCommandOutlined />,
        },
        {
          label: <Link to="/Sequences">Sequences</Link>,
          key: "Sequences",
          icon: <RetweetOutlined />,
        },
      ],
    },
    currentUser.is_superuser || currentUser.is_support || currentUser.is_manager
      ? {
          label: "Relatorios",
          icon: <FileTextOutlined />,
          children: [
            {
              label: <Link to="/RelatorioGrupo">Relatório Grupo</Link>,
              icon: <FileZipOutlined />,
            },
            {
              label: <Link to="/Relatorio">Relatório</Link>,
              icon: <FileTextOutlined />,
            },
          ],
        }
      : null,
    {
      label: "Monitoramento",
      icon: <EyeOutlined />,
      children: [
        {
          label: <Link to="/Aplicacoes">Aplicações</Link>,
          icon: <RetweetOutlined />,
        },
      ],
    },
    currentUser.is_superuser || currentUser.is_support || currentUser.is_manager
      ? {
          label: "Movimentação",
          key: "Movimentação",
          icon: <ThunderboltOutlined />,
          children: [
            {
              label: <Link to="/Atualizacao">Atualização</Link>,
              key: "Atualizacao",
              icon: <RedoOutlined />,
            },
            {
              label: <Link to="/Atualizacao-forcada">Atualização Forçada</Link>,
              key: "Atualizacao forcada",
              icon: <LoginOutlined />,
            },
            {
              label: <Link to="/Atualizar-executavel">Executáveis</Link>,
              key: "Atualizar executavel",
              icon: <FileSyncOutlined />,
            },
          ],
        }
      : null,
  ];

  return (
    <Menu items={items} theme={"dark"} mode={"inline"} className="ant-menu" />
  );
};

export default MenuPrincipal;
