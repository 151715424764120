import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

export const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getMenu = (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}menu/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};
const getMenuId = (id) => {
  const url = `${urlBase}menu/${id}/`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const getMenuItem = (page, pageSize, params, id) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}menu-item/?limit=${pageSize}&menu_id=${id}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};
const getMenuItemId = (id) => {
  const url = `${urlBase}menu-item/${id}/`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postMenu = (data, id) => {
  const url = id ? `${urlBase}menu/${id}` : `${urlBase}menu/`;
  const config = getConfig();
  let response;
  if (id) {
    response = axios.put(url, data, config);
  } else {
    response = axios.post(url, data, config);
  }
  return response;
};

const postMenuItem = (data, id) => {
  const url = id ? `${urlBase}menu-item/${id}` : `${urlBase}menu-item/`;
  const config = getConfig();
  let response;
  if (id) {
    response = axios.put(url, data, config);
  } else {
    response = axios.post(url, data, config);
  }
  return response;
};

const putMenu = (id, data) => {
  const url = `${urlBase}menu/${id}`;
  const config = getConfig();
  const response = axios.put(url, data, config);
  return response;
};

const putMenuItem = (id, data) => {
  const url = `${urlBase}menu-item/${id}`;
  const config = getConfig();
  const response = axios.put(url, data, config);
  return response;
};

const deleteMenu = (id) => {
  const url = `${urlBase}menu/${id}`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};
const deleteMenuItem = (id) => {
  const url = `${urlBase}menu-item/${id}`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};

export const menuRequest = {
  getMenu,
  getMenuId,
  getMenuItem,
  getMenuItemId,
  postMenu,
  postMenuItem,
  putMenu,
  putMenuItem,
  deleteMenu,
  deleteMenuItem,
};
