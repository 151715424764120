/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Input, Modal, Skeleton, Switch } from "antd";
import React, { useEffect, useState } from "react";
import TabelaRequest from "../../Paginas/CadastroTabelas/tabelaRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

const { useForm } = Form;

export const SelTabela = ({ visible, onCancel, id, estadoTabela }) => {
  const [loading, setLoading] = useState(false);
  const [emTeste, setEmTeste] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getTabelaId();
    }
  }, [id]);

  const getTabelaId = async () => {
    setLoading(true);
    try {
      const response = await TabelaRequest.getTabelaId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({ nome: data.nome });
        setEmTeste(data.em_teste);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cadastrarTabela = async (dados) => {
    setConfirmLoading(true);
    try {
      const response = await TabelaRequest.postTabela(dados, id);
      if (response.status === 201) {
        estadoTabela((prevAtualizacoes) => [
          response.data,
          ...prevAtualizacoes,
        ]);
        onCancel();
        form.resetFields();
        setEmTeste(false);
        setOpenStatusModal((prevState) => ({ ...prevState, create: true }));
        setConfirmLoading(false);
      }
      if (response.status === 200) {
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoTabela((prevState) => prevState.map(cb));
        onCancel();
        setEmTeste(false);
        form.resetFields();
        setOpenStatusModal((prevState) => ({ ...prevState, edit: true }));
        setConfirmLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
          setEmTeste(false);
        }}
        title={id ? "Editar tabela" : "Criar tabela"}
        destroyOnClose={true}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            preserve={true}
            onFinish={() => {
              form.validateFields().then((value) => {
                cadastrarTabela({ ...value, em_teste: emTeste });
              });
            }}
          >
            <Form.Item
              label="Nome tabela:"
              name="nome"
              rules={[{ required: true, message: "Campo obrigatório!" }]}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "max-content",
              }}
            >
              <text>Em teste:</text>
              <Switch onChange={(e) => setEmTeste(e)} checked={emTeste} />
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "34%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                    setEmTeste(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  {id ? "Editar" : "Criar"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        visible={openStatusModal.create}
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, create: false }))
        }
      />
      <ModalStatusEdit
        visible={openStatusModal.edit}
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, edit: false }))
        }
      />
    </>
  );
};
