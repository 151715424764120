import { Button, Space, Table, Tabs, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import versaoHistorico from "./versao";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ModalInserir } from "./components/modalInserir";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../Utils/temas";

const { TabPane } = Tabs;

export const VersaoHistorico = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [versao, setVersao] = useState([]);

  const [filtro, setFiltro] = useState({});

  const [totalRecords, setTotalRecords] = useState(0);
  const [idDelete, setIdDelete] = useState(null);

  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);

  const [valuesVersion, setValuesVersion] = useState({
    id: 0,
  });

  useEffect(() => {
    fetchSistema();
  }, []);

  useEffect(() => {
    if (filtro.sistema_id) {
      getVersao(1, 10, filtro);
    }
  }, [filtro]);

  const dataFormatada = (data) => {
    const date = new Date(data);
    const dataFormatada = data === null ? "" : date.toLocaleString().split(",");
    return dataFormatada;
  };

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Sistema Descrição",
      dataIndex: "sistema_descricao",
      key: "sistema_descricao",
    },
    {
      title: "Número versão",
      dataIndex: "numero_versao",
      key: "numero_versao",
    },
    {
      title: "Data versão",
      dataIndex: "data_versao",
      key: "data_versao",
      render: (record) => dataFormatada(record),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <EditOutlined
              onClick={(_) => {
                onEditVersao(record.id);
              }}
            />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => onDeleteVersao(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onEditVersao = (id) => {
    setValuesVersion({
      id: id,
    });
    setOpenModal(true);
  };

  const onDeleteVersao = (id) => {
    setIdDelete(id);
    setOpenModalDelete(true);
  };

  const getVersao = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await versaoHistorico.getVersaoHistorico(
        page,
        pageSize,
        params
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setTotalRecords(count);
        setVersao(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSistema = async () => {
    try {
      const response = await versaoHistorico.getSistema();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        const notSystems = ["LIVRO DIÁRIO"];

        const sistemasFiltrados = results.filter(
          (item) => !notSystems.includes(item.nome_sistema)
        );
        setSistemas(sistemasFiltrados);
        setFiltro({ sistema_id: sistemasFiltrados[0].id });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div style={{ padding: 10 }}>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          style={{
            color: "white",
            fontWeight: "500",
            background: colors.secondary,
          }}
          className="botao"
        >
          <PlusOutlined /> Inserir
        </Button>
      </div>

      <Tabs
        type="card"
        size="large"
        onChange={(e) => setFiltro({ sistema_id: e })}
      >
        {sistemas.map((item) => (
          <TabPane
            tab={item.nome_sistema}
            key={item.id}
            style={{ marginTop: -18 }}
          >
            <Table
              columns={columns}
              dataSource={versao}
              pagination={{
                total: totalRecords,
                onChange: (page, pageSize) => {
                  getVersao(page, pageSize, filtro);
                },
              }}
              loading={loading}
            />
          </TabPane>
        ))}
      </Tabs>

      <ModalInserir
        onCancel={() => {
          setOpenModal(false);
          setValuesVersion({ id: null });
        }}
        openModal={openModal}
        estadoVersao={setVersao}
        id={valuesVersion.id ? valuesVersion.id : null}
        sistemas={sistemas}
        sistemaSelecionado={filtro.sistema_id}
      />

      <ModalDelete
        onCancel={() => setOpenModalDelete(false)}
        visible={openModalDelete}
        id={idDelete}
        onSave={setVersao}
      />
    </div>
  );
};
