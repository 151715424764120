import { Button, Divider, Drawer, Input, Radio, Select } from "antd";
import React, { useState } from "react";
import { colors } from "../../../../../Utils/temas";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";
import { ufs } from "../../../../../Constante/UFS";
const { Group } = Radio;

export const DrawerFilter = ({
  visible,
  onClose,
  estadoFiltros,
  limparFiltro,
}) => {
  const [valuesFilter, setValuesFilter] = useState({
    nome_aplicacao: undefined,
    nome_cartorio: undefined,
    nome_cidade: undefined,
    uf_cartorio: undefined,
    status: undefined,
  });

  const handleSearch = () => {
    estadoFiltros(valuesFilter);
    onClose();
  };

  const limpar = () => {
    onClose();
    limparFiltro();
    setValuesFilter({ cidade: undefined, situacao: undefined, uf: undefined });
  };

  const optionsUfs = ufs.map((item) => ({
    value: item.sigla,
    label: item.sigla,
  }));

  return (
    <Drawer visible={visible} onClose={onClose} title="Filtros">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
          <text style={{ fontWeight: "500" }}>Cidade:</text>
          <Input
            value={valuesFilter.nome_cidade}
            onChange={(e) =>
              setValuesFilter((prevState) => ({
                ...prevState,
                nome_cidade: e.target.value === "" ? undefined : e.target.value,
              }))
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "20%",
          }}
        >
          <text style={{ fontWeight: "500" }}>UF:</text>
          <Select
            value={valuesFilter.uf_cartorio}
            options={optionsUfs}
            style={{ width: "100%" }}
            onChange={(e) =>
              setValuesFilter((prevState) => ({ ...prevState, uf_cartorio: e }))
            }
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 20,
        }}
      >
        <text style={{ fontWeight: "500" }}>Cartório:</text>
        <Input
          value={valuesFilter.nome_cartorio}
          onChange={(e) =>
            setValuesFilter((prevState) => ({
              ...prevState,
              nome_cartorio: e.target.value === "" ? undefined : e.target.value,
            }))
          }
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
          fontWeight: "500",
        }}
      >
        <text>Status:</text>
        <Group
          value={valuesFilter.status}
          onChange={(e) =>
            setValuesFilter((prevState) => ({
              ...prevState,
              status: e.target.value,
            }))
          }
        >
          <Radio value="ATIVO">ATIVO</Radio>
          <Radio value="INATIVO">INATIVO</Radio>
        </Group>
      </div>

      <div
        style={{
          height: "74%",
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Divider />

          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
              icon={<FilterOutlined />}
              onClick={handleSearch}
            >
              Filtrar
            </Button>
            <Button
              style={{ fontWeight: "500" }}
              icon={<ClearOutlined />}
              onClick={limpar}
            >
              Limpar
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
