import { Modal } from "antd";
import React, { useState } from "react";
import { estacaoRequest } from "../../estacaoRequest";
import { ModalStatusDelete } from "../../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoEstacao }) => {
  const [loading, setLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const deletarEstacao = async () => {
    setLoading(true);
    try {
      const response = await estacaoRequest.deleteEstacao(id);
      if (response.status === 204) {
        onCancel();
        setLoading(false);
        estadoEstacao((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        setOpenStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarEstacao}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja deletar esta Estação?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatus(false)}
        visible={openStatus}
      />
    </>
  );
};
