import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function getListaSeloGrupo(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "selo-grupo/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function CadastrarSeloGrupo(seloGrupo, id) {
  const url = id ? `${urlBase}selo-grupo/${id}/` : `${urlBase}selo-grupo/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, seloGrupo, config);
  } else {
    response = await axios.post(url, seloGrupo, config);
  }
  return response;
}

async function getSeloGrupo(id) {
  const url = urlBase + `selo-grupo/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirSeloGrupo(id) {
  const url = urlBase + `selo-grupo/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const putSeloGrupo = async (id, data) => {
  const url = `${urlBase}selo-grupo/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const SeloGrupoRequest = {
  CadastrarSeloGrupo,
  getListaSeloGrupo,
  ExcluirSeloGrupo,
  getSeloGrupo,
  putSeloGrupo,
};

export default SeloGrupoRequest;
