import { useEffect, useState, React } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import SeloGrupoFilhoRequest from "./SeloGrupoFilhoRequest";
import sistemaRequest from "../../Utils/sistemaRequest";
import { ufs } from "../../Constante/UFS";
import CadastroSeloGrupoFilho from "./CadastroSeloGrupoFilho";
import SelSeloGrupo from "../../Selecao/SeloGrupo/Sel_SeloGrupo";
import { SelSeloGrupoPai } from "../../Selecao/SeloGrupo/SeloGrupoPai/index";
import { colors } from "../../Utils/temas";
import "./estilo.css";
import { ModalDelete } from "./modalDelete";

const SeloGrupoFilho = () => {
  const [seloGrupoFilho, setSeloGrupoFilho] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [filtros, setFiltros] = useState([]);

  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isModalSeloGrupoPaiVisible, setIsModalSeloGrupoPaiVisible] =
    useState(false);
  const [isModalSeloGrupoVisible, setIsModalSeloGrupoVisible] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [id, setId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  const [form] = Form.useForm();
  const [form_pesquisa] = Form.useForm();

  const { Search } = Input;

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await SeloGrupoFilhoRequest.getListaSeloGrupoFilho(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setSeloGrupoFilho(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  async function CarregarSistema() {
    const response = await sistemaRequest.getSistemas();
    if (response.status === 200) {
      setSistemas(response.data.results);
    }
  }

  function onInserirClick() {
    setId(null);
    setIsModalVisible(true);
  }

  async function onEditSeloGrupoFilho(id) {
    setIsModalVisible(true);
    setId(id);
  }

  function onDeletarSeloGrupoFilho(id) {
    setOpenModalDelete(true);
    setIdDelete(id);
  }

  const optionsSistema = sistemas.map((sistema) => {
    return {
      label: sistema.nome_sistema,
      value: sistema.id,
    };
  });

  const optionsUf = ufs.map((uf) => {
    return {
      label: uf.nome,
      value: uf.sigla,
    };
  });

  useEffect(() => {
    CarregarSistema();
    FetchRecords(1, 10, {});
  }, []);

  const columns = [
    {
      title: "Decrição pai",
      dataIndex: "selo_grupo_pai_descricao",
      width: 300,
    },
    {
      title: "Número pai",
      dataIndex: "selo_grupo_pai_numero",
    },
    {
      title: "Decrição",
      dataIndex: "selo_grupo_descricao",
    },
    {
      title: "Número",
      dataIndex: "selo_grupo_numero",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditSeloGrupoFilho(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined
              onClick={(_) => onDeletarSeloGrupoFilho(record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const buscarSeloGrupoPaiId = () => {
    setIsModalSeloGrupoPaiVisible(true);
  };

  function onSelecionarSeloGrupoPai(valor) {
    setIsModalSeloGrupoPaiVisible(false);
    form_pesquisa.setFieldsValue({
      selo_grupo_pai_id: valor.id,
      selo_grupo_pai_descricao: valor.selo_grupo_descricao,
    });
  }

  const buscarSeloGrupoId = () => {
    setIsModalSeloGrupoVisible(true);
  };

  function onSelecionarSeloGrupo(valor) {
    setIsModalSeloGrupoVisible(false);
    form_pesquisa.setFieldsValue({
      selo_grupo_id: valor.id,
      selo_grupo_descricao: valor.descricao_completa,
    });
  }

  const tags = {
    selo_grupo__numero: "Número",
    selo_grupo_id: "Grupo",
    selo_grupo_pai_id: "Grupo Pai",
  };

  const onRemoveTags = (tag) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];
    if (tag === "selo_grupo_id") {
      form_pesquisa.resetFields([tag, "selo_grupo_descricao"]);
    }
    if (tag === "selo_grupo_pai_id") {
      form_pesquisa.resetFields([
        "selo_grupo_pai_id",
        "selo_grupo_pai_descricao",
      ]);
    }

    setFiltros(novosFiltros);
    FetchRecords(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagColors = () => {
            if (key === "selo_grupo__numero") return "purple";
            else if (key === "selo_grupo_id") return "magenta";
            else if (key === "selo_grupo_pai_id") return "volcano";
            else return "orange";
          };

          if (key === "selo_grupo_descricao") return null;
          if (key === "selo_grupo_pai_descricao") return null;

          if (key === "selo_grupo_id") {
            return (
              <Tag
                closable
                color={tagColors(key)}
                onClose={() => onRemoveTags(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] +
                    ": " +
                    form_pesquisa.getFieldValue("selo_grupo_descricao")}
                </text>
              </Tag>
            );
          } else if (key === "selo_grupo_pai_id")
            return (
              <Tag
                closable
                color={tagColors(key)}
                onClose={() => onRemoveTags(key)}
                key={key}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] +
                    ": " +
                    form_pesquisa.getFieldValue("selo_grupo_pai_descricao")}
                </text>
              </Tag>
            );

          return (
            <Tag
              closable
              color={tagColors(key)}
              onClose={() => onRemoveTags(key)}
              key={key}
            >
              <text style={{ padding: 10 }}>{tags[key] + ": " + value}</text>
            </Tag>
          );
        })}
    </>
  );

  return (
    <div style={{ paddingBottom: 40 }}>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          className="botao"
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            fontWeight: "500",
            color: "white",
            background: colors.secondary,
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={600}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="selo_grupo__numero" label="Número">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="selo_grupo_id" label="Grupo">
                  <Form.Item name="selo_grupo_descricao">
                    <Search readOnly allowClear onSearch={buscarSeloGrupoId} />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="selo_grupo_pai_id">
              <Form.Item name="selo_grupo_pai_descricao" label="Grupo Pai">
                <Search readOnly allowClear onSearch={buscarSeloGrupoPaiId} />
              </Form.Item>
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>

              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </div>
          </Form>
          <SelSeloGrupo
            onSelecionado={(valor) => onSelecionarSeloGrupo(valor)}
            visible={isModalSeloGrupoVisible}
            onCancel={() => setIsModalSeloGrupoVisible(false)}
            optionsSistema={optionsSistema}
            optionsUf={optionsUf}
          />
          <SelSeloGrupoPai
            onSelecionado={(valor) => onSelecionarSeloGrupoPai(valor)}
            visible={isModalSeloGrupoPaiVisible}
            onCancel={() => setIsModalSeloGrupoPaiVisible(false)}
            optionsSistema={optionsSistema}
            optionsUf={optionsUf}
          />
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={seloGrupoFilho}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
      />
      <CadastroSeloGrupoFilho
        visible={isModalVisible}
        form={form}
        afterPost={setSeloGrupoFilho}
        onCancel={() => {
          setIsModalVisible(false);
          setId(null);
        }}
        optionsUf={optionsUf}
        optionsSistema={optionsSistema}
        id={id}
      />
      <ModalDelete
        visible={openModalDelete}
        onCancel={() => {
          setOpenModalDelete(false);
          setIdDelete(null);
        }}
        id={idDelete}
        estadoSeloGrupoFilho={setSeloGrupoFilho}
      />
    </div>
  );
};

export default SeloGrupoFilho;
