import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import React from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { colors } from "../../../Utils/temas";
const { useForm, Item } = Form;
const { Group } = Radio;

export const DrawerEdit = ({ onClose, visible, onFinish, limpar }) => {
  const [form] = useForm();

  const onClear = () => {
    limpar();

    form.resetFields();
  };
  return (
    <Drawer onClose={onClose} visible={visible} title="Filtros">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ height: "100%" }}
      >
        <Item name="nome" label="Nome:" style={{ fontWeight: "500" }}>
          <Input />
        </Item>
        <Item name="secao" label="Seção:" style={{ fontWeight: "500" }}>
          <Input />
        </Item>
        <Item name="em_teste" label="Em teste:" style={{ fontWeight: "500" }}>
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Item>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "68%",
          }}
        >
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
              className="botao"
              icon={<SearchOutlined />}
              htmlType="submit"
            >
              Confirmar
            </Button>
            <Button icon={<CloseOutlined />} onClick={onClear}>
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
