import { UserTockem } from "../../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getTrigger = (page, pageSize, id, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}Trigger/?limit=${pageSize}&offset=${skip}&tabela_id=${id}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};
const getTriggerId = (id) => {
  const url = `${urlBase}Trigger/${id}`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postTrigger = async (data, id) => {
  const url = id ? `${urlBase}Trigger/${id}` : `${urlBase}Trigger/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putTrigger = async (id, data) => {
  const url = `${urlBase}Trigger/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteTrigger = async (id) => {
  const url = `${urlBase}Trigger/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const triggerRequest = {
  getTrigger,
  getTriggerId,
  postTrigger,
  putTrigger,
  deleteTrigger,
};
