/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, React } from "react";
import {
  Table,
  Button,
  Drawer,
  Tag,
  Space,
  Form,
  Input,
  Radio,
  Select,
  Collapse,
  Divider,
  Tooltip,
} from "antd";
import CadastroCartorio from "./CadastroCartorio";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  CloseOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import CartorioRequest from "./CartorioRequest";
import moment from "moment";
import { ufs } from "../../Constante/UFS";
import "./estilos.css";
import { ModalDelete } from "./modaDelete";
import { colors } from "../../Utils/temas";
import { useNavigate } from "react-router-dom";
import { ModalBlock } from "./modalBlock";
import { useUserContext } from "../../contexts/usersContext";
import { useErrorBoundary } from "react-error-boundary";

const Cartorio = () => {
  const [loading, setLoading] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getInitialFilters = () => {
    const savedFilters = localStorage.getItem("cartorio_filtros");

    if (savedFilters) return JSON.parse(savedFilters);
    return {
      situacao: "A",
    };
  };

  const [filtros, setFiltros] = useState(getInitialFilters());
  const [cartorios, setCartorios] = useState([]);
  const [sistemas, setSistemas] = useState([{ id: 0, nome_sistema: "" }]);

  const [id, setId] = useState(0);
  const [valuesEdit, setValuesEdit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [form_pesquisa] = Form.useForm();

  const navigation = useNavigate();

  const { currentUser } = useUserContext();

  const { showBoundary } = useErrorBoundary();

  const { Panel } = Collapse;

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await CartorioRequest.getListaCartorio(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setCartorios(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  const formatarVersaoBanco = (versao) => {
    if (versao === "FB25") {
      return "Firebird 2.5";
    }
    if (versao === "FB30") {
      return "Firebird 3.0";
    }
    if (versao === "FB50") {
      return "Firebird 5.0";
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Nome",
      dataIndex: "nome_cartorio",
      key: "nome_cartorio",
      width: "12%",
      render: (render) => {
        const formattString = (text) => {
          if (text.length > 40) {
            return (
              <Tooltip title={text} arrowContent={false} color="#333">
                <text>{`${text.slice(0, 40)}...`}</text>
              </Tooltip>
            );
          }
          return <text>{text}</text>;
        };

        return formattString(render);
      },
    },
    {
      title: "CNS",
      dataIndex: "cns",
      key: "cns",
      width: 1,
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
      key: "cidade",
      width: "8%",
      render: (render) => {
        const formattString = (text) => {
          if (text.length > 40) {
            return (
              <Tooltip title={text} arrowContent={false} color="#333">
                <text>{`${text.slice(0, 40)}...`}</text>
              </Tooltip>
            );
          }
          return <text>{text}</text>;
        };

        return formattString(render);
      },
    },
    { title: "UF", dataIndex: "uf", key: "uf", width: 1 },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
      width: 1,
      render: (_, render) => {
        return (
          <Tag color={render.situacao === "A" ? "green" : "red"}>
            {render.situacao === "A" ? "Ativo" : "Inativo"}
          </Tag>
        );
      },
    },
    {
      title: "Situação Serventia",
      dataIndex: "situacao_serventia",
      key: "situacao_serventia",
      width: 1,
      render: (_, render) => {
        const formattSituation = (situacao) => {
          if (situacao === "P") {
            return "Provido";
          } else if (situacao === "V") {
            return "Vago";
          }
        };
        return <text>{formattSituation(render.situacao_serventia)}</text>;
      },
    },
    {
      title: "Qtd.",
      dataIndex: "qtde_licenca",
      key: "qtde_licenca",
      width: 1,
    },
    {
      title: "Atualizar com erro",
      dataIndex: "atualizar_com_erro",
      key: "atualizar_com_erro",
      width: "8%",
      render: (value) => (
        <Tag color={value ? "green" : "red"}> {value ? "Sim" : "Não"} </Tag>
      ),
    },
    {
      title: "Bloquear atualização",
      dataIndex: "bloquear_atualizacao",
      key: "bloquear_atualizacao",
      width: "1%",
      render: (value) => (
        <Tag color={value ? "green" : "red"}> {value ? "Sim" : "Não"} </Tag>
      ),
      ellipsis: true,
    },
    {
      title: "Versão DB",
      dataIndex: "versao_banco",
      width: 1,
      render: (versao) => formatarVersaoBanco(versao),
    },
    {
      title: "Ações",
      key: "acoes",
      width: 120,
      render: (record) => (
        <Space>
          <Tooltip title="Ver detalhes">
            <CopyOutlined onClick={() => navigation(`${record.id}`)} />
          </Tooltip>
          <Tooltip title="Editar">
            <EditOutlined
              onClick={() => {
                onEditCartorio(record.id);
              }}
            />
          </Tooltip>
          <Tooltip title="Deletar">
            <DeleteOutlined onClick={() => handleDelete(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  async function onEditCartorio(value) {
    setValuesEdit(value);
    setIsModalVisible(true);
  }

  const handleDelete = (value) => {
    setOpenDelete(true);
    setId(value);
  };

  function onRemoverFiltro(tag) {
    const novosFiltros = { ...filtros };
    delete novosFiltros[tag];

    setFiltros(novosFiltros);
  }

  async function onPesquisarClick(value) {
    setFiltros({
      bloquear_atualizacao: value.bloquear_atualizacao,
      bloqueio: value.bloqueio,
      cidade: value.cidade,
      em_aberto: value.em_aberto,
      nome_cartorio: value.nome_cartorio,
      sistemas: value.sistemas && value.sistemas.join(","),
      situacao: value.situacao,
      situacao_serventia: value.situacao_serventia,
      uf: value.uf,
      usa_api: value.usa_api,
      usa_bi: value.usa_bi,
      usa_cad_ato: value.usa_cad_ato,
      usa_gsti: value.usa_gsti,
      usa_os: value.usa_os,
      usa_whatszap: value.usa_whatszap,
      usa_workflow: value.usa_workflow,
      versao_banco: value.versao_banco,
      usa_pix: value.usa_pix,
    });
    setDrawerVisible(false);
  }

  useEffect(() => {
    getSistemas();
  }, []);

  useEffect(() => {
    if (filtros.situacao || filtros) {
      saveFilters();
    }
    if (filtros) {
      FetchRecords(1, 10, filtros);
    }
  }, [filtros]);

  const Limpar = () => {
    setFiltros({});
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const options = ufs.map((uf) => {
    return {
      label: uf.sigla,
      value: uf.sigla,
    };
  });

  const tags = {
    situacao: "Situação",
    nome_cartorio: "Nome cartório",
    uf: "UF",
    cidade: "Cidade",
    usa_api: "Usa API",
    usa_cad_ato: "Usa cad. ato",
    usa_os: "Usa OS",
    usa_sms: "Usa SMS",
    usa_whatszap: "Usa Whatsapp",
    bloquear_atualizacao: "Bloqueio atualização",
    bloqueio: "Bloqueio",
    em_aberto: "Em aberto",
    usa_bi: "Usa BI",
    usa_workflow: "Usa Workflow",
    usa_gsti: "Usa GSTI",
    sistemas: "Sistemas",
    situacao_serventia: "Situação Serventia",
    versao_banco: "Versão DB",
    usa_pix: "Usa PIX",
  };

  const getTagsTitle = () => (
    <div>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const tagsValores = {
            true: "Sim",
            false: "Não",
            A: "Ativo",
            I: "Inativo",
            GO: "GO",
            AL: "AL",
            CE: "CE",
            PA: "PA",
            P: "Provido",
            V: "Vago",
            FB25: "Firebird 2.5",
            FB30: "Firebird 3.0",
            FB50: "Firebird 5.0",
          };

          const tagCor = () => {
            if (value === true || value === "A") {
              return "green";
            } else if (value === false || value === "I") {
              return "red";
            } else if (key === "nome_cartorio") {
              return "orange";
            } else if (key === "uf") {
              return "purple";
            } else if (key === "cidade") {
              return "magenta";
            } else if (key === "situacao_serventia") {
              return "volcano";
            } else if (key === "versao_banco") {
              return "cyan";
            } else {
              return "gold";
            }
          };

          let idsSistemas = value;

          if (key === "sistemas") {
            idsSistemas = value?.split(",").map((item) => parseInt(item));
          }

          if (value === "") {
            return null;
          }

          const getKeys = (keys) => {
            if (keys === "nome_cartorio") {
              return value;
            }
            if (keys === "cidade") {
              return value;
            }
            if (keys === "sistemas") {
              return `${sistemas
                .filter((item) => idsSistemas?.includes(item.id))
                .map((item) => item.nome_sistema)}`;
            }
            return tagsValores[value];
          };

          return (
            <Tag
              key={key}
              closable
              onClose={() => onRemoverFiltro(key)}
              color={tagCor(key, tagsValores[value])}
            >
              <text
                style={{
                  padding: 10,
                }}
              >
                {tags[key] + `: ` + getKeys(key)}
              </text>
            </Tag>
          );
        })}
    </div>
  );

  const saveFilters = () => {
    localStorage.setItem("cartorio_filtros", JSON.stringify(filtros));
  };

  window.onerror = (msg, url, line, col, error) => {
    showBoundary(error);
  };

  const getSistemas = async () => {
    const response = await CartorioRequest.listarSistema();
    if (response.status === 200) {
      const { data } = response;
      setSistemas(data.results);
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={() => showModal()}
          style={{
            background: colors.secondary,
            color: "white",
            fontWeight: "500",
          }}
          className="botao"
        >
          <PlusOutlined color="white" style={{ color: "white" }} />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{ background: colors.secondary }}
          className="botao"
        >
          <FilterOutlined style={{ color: "white" }} />
        </Button>
        <Drawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          width={500}
        >
          <div style={{ marginBottom: 56 }}>
            <Form
              form={form_pesquisa}
              layout="vertical"
              onFinish={onPesquisarClick}
              preserve={true}
              style={{ height: "20%" }}
            >
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  zIndex: 100,
                  width: "23%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "435px",
                    background: "white",
                    alignItems: "center",
                    height: 64,
                  }}
                >
                  <Button
                    htmlType="submit"
                    style={{ background: colors.secondary, color: "white" }}
                    className="botao"
                  >
                    <KeyOutlined />
                    Pesquisar
                  </Button>

                  <Button onClick={Limpar}>
                    <CloseOutlined />
                    Limpar
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  style={{ margin: 0, width: "60%" }}
                  name="cidade"
                  label="Cidade:"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="uf"
                  label="UF:"
                  style={{ margin: 0, width: "20%" }}
                >
                  <Select name="uf" options={options} />
                </Form.Item>
              </div>

              <Form.Item
                style={{ margin: 0, marginTop: 2 }}
                name="nome_cartorio"
                label="Nome cartorio:"
              >
                <Input />
              </Form.Item>

              <Divider
                style={{ margin: "10px 0px 10px 0px", background: "#E8E8E8" }}
              />

              <Form.Item
                name="sistemas"
                label="Sistemas:"
                style={{ margin: 0 }}
              >
                <Select
                  style={{ width: "100%" }}
                  options={sistemas.map((item) => ({
                    label: item.nome_sistema,
                    value: item.id,
                  }))}
                  mode="multiple"
                />
              </Form.Item>

              <Form.Item
                name="versao_banco"
                label="Versão DB:"
                style={{ margin: 0, marginTop: 4 }}
              >
                <Select
                  options={[
                    { value: "FB25", label: "Firebird 2.5" },
                    { value: "FB30", label: "Firebird 3.0" },
                    { value: "FB50", label: "Firebird 5.0" },
                  ]}
                  style={{ width: 120 }}
                />
              </Form.Item>

              <Divider
                style={{ margin: "10px 0px 5px 0px", background: "#E8E8E8" }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="situacao"
                  label="Situação:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={"A"}>Ativo</Radio>
                    <Radio value={"I"}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80, marginLeft: -17 }}
                  type="vertical"
                />

                <Form.Item
                  name="em_aberto"
                  label="Em aberto:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="usa_api"
                  label="Usa api:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80 }}
                  type="vertical"
                />

                <Form.Item
                  name="usa_os"
                  label="Usa OS:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="usa_cad_ato"
                  label="Usa cad. ato:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80 }}
                  type="vertical"
                />

                <Form.Item
                  name="usa_whatszap"
                  label="Usa WhatsZap:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="bloquear_atualizacao"
                  label="Bloqueio atualização:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80, marginRight: 10 }}
                  type="vertical"
                />

                <Form.Item
                  name="bloqueio"
                  label="Bloqueio:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="usa_bi"
                  label="Usa BI:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80 }}
                  type="vertical"
                />

                <Form.Item
                  name="usa_workflow"
                  label="Usa Workflow:"
                  style={{ margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item name="usa_gsti" label="Usa GSTI:">
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80 }}
                  type="vertical"
                />

                <Form.Item
                  name="situacao_serventia"
                  label="Situação Serventia:"
                  style={{ width: 128, margin: 0, padding: 0 }}
                >
                  <Radio.Group>
                    <Radio value={"P"}>Provido</Radio>
                    <Radio value={"V"}>Vago</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "52%",
                  marginTop: -10,
                  marginBottom: 16,
                }}
              >
                <Form.Item name="usa_pix" label="Usa PIX:">
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Divider
                  style={{ background: "#E8E8E8", height: 80 }}
                  type="vertical"
                />
              </div>
            </Form>
          </div>
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        title={getTagsTitle}
        rowKey={"id"}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Collapse>
                <Panel header="Atualização de Emolumentos" key="1">
                  <h4>
                    Atualizar Emolumento:{" "}
                    {record.atualizar_emolumento ? "Sim" : "Não"}
                  </h4>
                  <h4>
                    Data Atualização:{" "}
                    {record.data_atualizacao
                      ? moment(record.data_atualizacao).format("DD/MM/YYYY")
                      : "Data indisponível"}
                  </h4>
                </Panel>
                <Panel header="Serviços" key="2">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <text style={{ fontWeight: "500" }}>API:</text>
                    <Tag
                      color={record.usa_api ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_api ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>OS:</text>
                    <Tag
                      color={record.usa_os ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_os ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>Whatsapp:</text>
                    <Tag
                      color={record.usa_whatszap ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_whatszap ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>Cad. Ato:</text>
                    <Tag
                      color={record.cad_ato ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.cad_ato ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>BI:</text>
                    <Tag
                      color={record.usa_bi ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_bi ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>Workflow:</text>
                    <Tag
                      color={record.usa_workflow ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_workflow ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>GSTI:</text>
                    <Tag
                      color={record.usa_gsti ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.usa_gsti ? "Sim" : "Não"}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <text style={{ fontWeight: "500" }}>PIX:</text>
                    <Tag
                      color={record.pix ? "green" : "red"}
                      style={{ marginLeft: 5 }}
                    >
                      {record.pix ? "Sim" : "Não"}
                    </Tag>
                  </div>
                </Panel>
                <Panel header="Bloqueio" key="3">
                  <h4>Em aberto: {record.em_aberto ? "Sim" : "Não"}</h4>
                  <h4>Bloqueio: {record.bloqueio ? "Sim" : "Não"}</h4>
                  <h4>Chave: {record.chave}</h4>
                  <h4>
                    Dia Vencimento:{" "}
                    {record.dia_vencimento
                      ? record.dia_vencimento
                      : "Não Definida"}
                  </h4>
                  <h4>
                    Data Vencimento:{" "}
                    {record.data_vencimento
                      ? moment(record.data_vencimento).format("DD-MM-YYYY")
                      : "Não Definida"}
                  </h4>
                  <h4>
                    Data Bloqueio:{" "}
                    {record.data_bloqueio
                      ? moment(record.data_bloqueio).format("DD-MM-YYYY")
                      : "Não Definida"}
                  </h4>
                  <h4>
                    Data Liberação:{" "}
                    {record.data_liberacao
                      ? moment(record.data_liberacao).format("DD-MM-YYYY")
                      : "Não Definida"}
                  </h4>
                </Panel>
              </Collapse>

              <div style={{ marginTop: 20 }}>
                {currentUser.is_financial ||
                currentUser.is_manager ||
                currentUser.is_superuser ? (
                  <Button
                    onClick={() => {
                      setOpenBlock(true);
                      setId(record.id);
                    }}
                  >
                    Bloquear cartório
                  </Button>
                ) : null}
              </div>
            </>
          ),
        }}
        dataSource={cartorios}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        scroll={{ x: true }}
      />
      <CadastroCartorio
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setValuesEdit(null);
        }}
        estadoCartorio={setCartorios}
        id={valuesEdit}
      />

      <ModalDelete
        onCancel={() => setOpenDelete(false)}
        visible={openDelete}
        id={id}
        estadoCartorio={setCartorios}
      />
      <ModalBlock
        onCancel={() => setOpenBlock(false)}
        visible={openBlock}
        id={id}
        atualizarEstados={setCartorios}
      />
    </div>
  );
};

export default Cartorio;
