import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalAtualizacao = ({
  visible,
  onClose,
  onOk,
  erro,
  erroMessage,
}) => {
  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      footer={
        erro
          ? null
          : [
              <Button key="cancelar" onClick={onClose}>
                Cancelar
              </Button>,
              <Button key="ok" type="primary" onClick={onOk}>
                OK
              </Button>,
            ]
      }
    >
      {erro ? (
        <Result
          status="warning"
          title={erroMessage}
          extra={
            <Button type="primary" key="console" onClick={onClose}>
              Fechar
            </Button>
          }
        />
      ) : (
        <text style={{ fontWeight: "600", fontSize: 16 }}>
          Deseja montar essa atualização?
        </text>
      )}
    </Modal>
  );
};
