import { urlBase } from "../../Constante/Url";
import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getSequences = (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}Sequence/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};
const getSequenceId = (id) => {
  const url = `${urlBase}Sequence/${id}/`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

const postSequence = (data, id) => {
  const url = id ? `${urlBase}Sequence/${id}` : `${urlBase}Sequence/`;
  const config = getConfig();
  let response;
  if (id) {
    response = axios.put(url, data, config);
  } else {
    response = axios.post(url, data, config);
  }
  return response;
};

const putSequence = (id, data) => {
  const url = `${urlBase}Sequence/${id}/`;
  const config = getConfig();
  const response = axios.put(url, data, config);
  return response;
};

const deleteSequence = (id) => {
  const url = `${urlBase}Sequence/${id}/`;
  const config = getConfig();
  const response = axios.delete(url, config);
  return response;
};

export const sequencesRequest = {
  getSequences,
  getSequenceId,
  postSequence,
  putSequence,
  deleteSequence,
};
