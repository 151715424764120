/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { colors } from "../../../../../Utils/temas";
import { primaryRequest } from "../../primaryRequest";
import { ErrorModal } from "../../../../../Utils/errorModal";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";
import { SelectCampos } from "../../../foreignKey/components/selectCampos";
import { Button, Divider, Form, Input, Modal, Skeleton, Switch } from "antd";
const { useForm } = Form;
const { Search } = Input;

export const ModalInsert = ({
  visible,
  onCancel,
  nomeTabela,
  id,
  estadoPk,
  idPK,
}) => {
  const [loading, setLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [error, setError] = useState({ openModal: false, msg: "" });

  const [form] = useForm();

  useEffect(() => {
    if (idPK) {
      getPkValues();
    }
  }, [visible]);

  const onSelect = (valor) => {
    form.setFieldsValue({
      campo__nome: valor.nome,
      campo_id: valor.id,
    });
    setOpenSelect(false);
  };

  const postPk = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await primaryRequest.postPrimaryKey(data, idPK);
      if (response.status === 201) {
        onCancel();
        form.resetFields();
        const responseData = response.data;
        estadoPk((prevPk) => [responseData, ...prevPk]);
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        form.resetFields();
        const responseData = response.data;
        const cb = (callback) => {
          if (callback.id === idPK) {
            return { ...callback, ...responseData };
          } else {
            return { ...callback };
          }
        };
        estadoPk((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      const { detail } = error.response.data;
      setError({ openModal: true, msg: detail });
    } finally {
      setConfirmLoading(false);
    }
  };

  const getPkValues = async () => {
    setLoading(true);
    try {
      const response = await primaryRequest.getPrimaryKeyId(idPK);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          campo_id: data.campo_id,
          campo__nome: data.campo__nome,
          campo__tabela_id: data.campo__tabela_id,
          campo__tabela_nome: data.campo__tabela_nome,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title={idPK ? "Editar" : "Inserir"}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form.validateFields().then((values) => postPk(values));
            }}
            initialValues={{ em_teste: true }}
          >
            <Form.Item
              name="nome"
              label="Nome:"
              style={{ fontWeight: "500" }}
              rules={[
                { required: true, message: "Informe o nome da Primary Key" },
              ]}
              initialValue={`${nomeTabela}_PK`}
            >
              <Input />
            </Form.Item>

            <Form.Item name="campo_id">
              <Form.Item
                name="campo__nome"
                label="Campo:"
                style={{ fontWeight: "500", margin: 0 }}
                rules={[{ required: true, message: "Informe o campo" }]}
              >
                <Search readOnly onSearch={() => setOpenSelect(true)} />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em Teste"
              style={{ fontWeight: "500", padding: 0, margin: 0 }}
              valuePropName="checked"
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}

        <SelectCampos
          openModal={openSelect}
          onCancel={() => setOpenSelect(false)}
          id={id}
          onSelecionado={(value) => onSelect(value)}
        />

        <ErrorModal
          visible={error.openModal}
          onCancel={() => setError({ msg: "", openModal: false })}
          msg={error.msg}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
