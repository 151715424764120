/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { valoresDefaultRequest } from "./valoresDefaultRequest";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../../Utils/temas";
import { DrawerFilter } from "./components/drawerFilter";
import { ModalInsert } from "./components/modalInsert";

export const ValoresDefault = ({ id }) => {
  const [valoresDefault, setValoresDefault] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [idEdit, setIdEdit] = useState(null);
  const [idDelete, setIdDelete] = useState(null);

  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    fetchRequest(1, 10, filtros);
  }, [filtros]);

  const fetchRequest = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await valoresDefaultRequest.getValoresDefault(
        page,
        pageSize,
        id,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setValoresDefault(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Valor", dataIndex: "valor" },
    { title: "Campo", dataIndex: "campo__nome" },
    { title: "Tabela", dataIndex: "tabela__nome" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space>
          <EditOutlined onClick={() => handleEdit(record.id)} />
          <DeleteOutlined onClick={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (values) => {
    setIdEdit(values);
    setOpen(true);
  };

  const handleDelete = (values) => {
    setIdDelete(values);
    setOpenDelete(true);
  };

  const tags = {
    campo_nome: "Campo",
    em_teste: "Em teste",
    tabela_nome: "Tabela",
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const colorsTag = () => {
            if (key === "campo_nome") {
              return "magenta";
            } else if (key === "em_teste" && !value) {
              return "red";
            }
            return "green";
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  key={key}
                  color={colorsTag()}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? (value ? "Sim" : "Não") : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  const clearFilters = () => {
    setFiltros({});
    setOpenFilter(false);
  };

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              className="botao"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
            >
              <PlusOutlined /> Inserir
            </Button>

            <Button
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
                marginLeft: 10,
              }}
              icon={<SearchOutlined />}
              className="botao"
              onClick={() => setOpenFilter(true)}
            >
              Filtros
            </Button>

            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        dataSource={valoresDefault}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => fetchRequest(page, pageSize, filtros),
        }}
        columns={columns}
        loading={loading}
      />

      <ModalDelete
        id={idDelete}
        onCancel={() => setOpenDelete(false)}
        onDelete={setValoresDefault}
        visible={openDelete}
      />

      <DrawerFilter
        onClose={() => setOpenFilter(false)}
        visible={openFilter}
        onFinish={(e) => {
          setFiltros(e);
          setOpenFilter(false);
        }}
        limpar={clearFilters}
      />

      <ModalInsert
        estadoValores={setValoresDefault}
        id={id}
        onCancel={() => {
          setIdEdit(null);
          setOpen(false);
        }}
        visible={open}
        idValDef={idEdit}
      />
    </>
  );
};
