import axios from "axios";
import { urlBase } from "../../Constante/Url";
import { UserTockem } from "../../Utils/autenticacao";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getComandos = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}Comando/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getComandosId = async (id) => {
  const url = `${urlBase}Comando/${id}/`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postComandos = async (data, id) => {
  const url = id ? `${urlBase}Comando/${id}` : `${urlBase}Comando/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putComandos = async (id, data) => {
  const url = `${urlBase}Comando/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteComando = async (id) => {
  const url = `${urlBase}Comando/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const comandosRequest = {
  getComandos,
  getComandosId,
  postComandos,
  putComandos,
  deleteComando,
};
