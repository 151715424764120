/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import versaoHistorico from "../../versao";
import { colors } from "../../../../Utils/temas";
import moment from "moment";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../Utils/modalStatus";

export const ModalInserir = ({
  openModal,
  onCancel,
  estadoVersao,
  id,
  sistemas,
  sistemaSelecionado,
}) => {
  const [form] = Form.useForm();

  const [errorMsg, setErrorMsg] = useState({ texto: [] });

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const user = localStorage.getItem("user");
  const userId = JSON.parse(user);

  useEffect(() => {
    if (id) {
      getVersaoHistorico();
    }
  }, [id]);

  const inserirVersao = async ({ dados }) => {
    const dadosInserir = {
      id: dados.id,
      data_versao: dados.data_versao,
      numero_versao: dados.numero_versao,
      sistema_id: dados.sistema_id,
      texto: dados.texto,
      texto_tecnico: dados.texto_tecnico,
      usuario_id: userId.id,
    };
    setConfirmLoading(true);
    try {
      const response = await versaoHistorico.postVersao(dadosInserir, id);

      const { data } = response;

      if (response.status === 201) {
        onCancel();
        form.resetFields();
        if (data.sistema_id !== parseInt(sistemaSelecionado)) {
          estadoVersao((prevState) => [...prevState]);
        } else {
          estadoVersao((prevState) => [response.data, ...prevState]);
        }
        setErrorMsg({ texto: [] });
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        onCancel();
        form.resetFields();
        const cb = (prevCb) => {
          if (prevCb.id === id) {
            return { ...prevCb, ...response.data };
          } else {
            return { ...prevCb };
          }
        };
        if (data.sistema_id !== parseInt(sistemaSelecionado)) {
          estadoVersao((prevState) =>
            prevState.filter((item) => item.id !== id)
          );
        } else {
          estadoVersao((prevState) => prevState.map(cb));
        }
        setErrorMsg({ texto: [] });
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      const { texto } = error.response.data;
      setErrorMsg({ texto: texto });
    } finally {
      setConfirmLoading(false);
    }
  };

  function handleClose() {
    onCancel();
    form.resetFields();
    setErrorMsg({
      sistema_id: [],
      numero_versao: [],
      data_versao: [],
      texto: [],
    });
  }

  const getVersaoHistorico = async () => {
    setLoading(true);
    try {
      const response = await versaoHistorico.getVersaoId(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        form.setFieldsValue({
          numero_versao: data.numero_versao,
          texto: data.texto,
          texto_tecnico: data.texto_tecnico,
          data_versao: moment(data.data_versao),
          sistema_id: data.sistema_id,
          sistema_descricao: data.sistema_descricao,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (id === null) {
    form.setFieldsValue({
      sistema_id: parseInt(sistemaSelecionado),
      data_versao: moment(),
    });
  }

  return (
    <>
      <Modal
        visible={openModal}
        onCancel={handleClose}
        footer={null}
        title={id ? "Editar versão" : "Inserir nova versão"}
        width={1000}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form
                .validateFields()
                .then((values) => inserirVersao({ dados: values }));
            }}
          >
            <div>
              <Form.Item name="id" style={{ display: "none" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "60%",
                }}
              >
                <div style={{ width: "14%" }}>
                  <Form.Item
                    name="numero_versao"
                    label="N° Versão:"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Informe o número da versão.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div>
                  <Form.Item
                    name="data_versao"
                    label="Data versão:"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Informe a data da versão.",
                      },
                    ]}
                  >
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                </div>

                <div style={{ width: "50%" }}>
                  <Form.Item
                    name="sistema_id"
                    label="Sistema:"
                    style={{ width: "100%", margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Selecione ao menos um sistema.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione um sistema"
                      options={sistemas.map((item) => {
                        return { value: item.id, label: item.nome_sistema };
                      })}
                    />
                  </Form.Item>
                </div>
              </div>

              <Divider />

              <Form.Item
                name="texto"
                label="Texto:"
                style={{ margin: 0, width: "100%", height: 400 }}
              >
                <ReactQuill theme="snow" style={{ height: 320 }} />
              </Form.Item>
              {errorMsg.texto && (
                <text style={{ color: "red" }}>{errorMsg.texto}</text>
              )}
            </div>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  fontWeight: "500",
                  color: "white",
                }}
                className="botao"
                loading={confirmLoading}
              >
                {id ? "Editar" : "Cadastrar"}
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
