/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Switch, Table, Tag } from "antd";
import { FilterOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { estacaoRequest } from "./estacaoRequest";
import { colors } from "../../../../Utils/temas";
import { FiltrosEstacao } from "./componentes/filtros";
import { ModalDelete } from "./componentes/modalDelete";

export const Estacao = ({ id }) => {
  const [estacoes, setEstacoes] = useState([
    {
      id: null,
      cartorio_id: null,
      cartorio_descricao: "",
      nome_estacao: "",
      ip_estacao: "",
      data_acesso: "",
      bloqueio: false,
      chave_liberacao: "",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [idEstacao, setIdEstacao] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    getEstacao(1, 10, id, filtros);
  }, [filtros]);

  const formatacaoData = (data) => {
    const date = new Date(data);
    const dataFormatada = date.toLocaleString().split(",");
    return dataFormatada;
  };

  const columns = [
    {
      key: "nome_estacao",
      dataIndex: "nome_estacao",
      title: "Nome Estação",
    },
    {
      key: "ip_estacao",
      dataIndex: "ip_estacao",
      title: "IP Estação",
    },
    {
      key: "data_acesso",
      dataIndex: "data_acesso",
      title: "Data Acesso",
      render: (value) => formatacaoData(value),
    },
    {
      key: "bloqueio",
      dataIndex: "bloqueio",
      title: "Bloqueio",
      render: (blocked) => (
        <Tag color={blocked ? "green" : "red"}>{blocked ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      key: "chave_liberacao",
      dataIndex: "chave_liberacao",
      title: "Chave de liberação",
    },
    {
      title: "Ações",
      render: (values) => (
        <Space size="middle">
          <DeleteOutlined onClick={() => handleDelete(values.id)} />
        </Space>
      ),
    },
  ];

  const handleDelete = (id) => {
    setOpenDelete(true);
    setIdEstacao(id);
  };

  const getEstacao = async (page, pageSize, id, params) => {
    setLoading(true);
    try {
      const response = await estacaoRequest.getEstacao(
        page,
        pageSize,
        id,
        params
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setEstacoes(results);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const atualizarBloqueio = async (idEstacao, data) => {
    setLoadingUpdate(true);
    try {
      const response = await estacaoRequest.putEstacao(idEstacao, data);
      if (response.status === 201 || response.status === 200) {
        const cb = (prevState) => {
          if (prevState.id === data.id) {
            return { ...prevState, ...data };
          }
          return { ...prevState };
        };
        setEstacoes((prevState) => prevState.map(cb));
        setLoadingUpdate(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={estacoes}
        loading={loading}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => getEstacao(page, pageSize, id),
        }}
        title={() => (
          <Button
            icon={<FilterOutlined />}
            style={{
              background: colors.secondary,
              color: "white",
              fontWeight: "500",
            }}
            onClick={() => setOpen(true)}
            className="botao"
          >
            Filtros
          </Button>
        )}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <text style={{ fontWeight: "500", paddingRight: 10 }}>
                {!record.bloqueio ? "Liberar" : "Bloquear"}
              </text>
              <Switch
                checked={record.bloqueio}
                onChange={(e) => {
                  const dataBlocked = { ...record, bloqueio: e };
                  atualizarBloqueio(record.id, dataBlocked);
                }}
                loading={loadingUpdate}
              />
            </div>
          ),
        }}
        rowKey="id"
      />

      <FiltrosEstacao
        onClose={() => setOpen(false)}
        visible={open}
        onFinish={(e) => setFiltros(e)}
        onClear={() => {
          setFiltros({});
          setOpen(false);
        }}
      />
      <ModalDelete
        visible={openDelete}
        onCancel={() => setOpenDelete(false)}
        id={idEstacao}
        estadoEstacao={setEstacoes}
      />
    </>
  );
};
