/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  Input,
  Modal,
  Switch,
  Button,
  Divider,
  Skeleton,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { sequencesRequest } from "../../../sequencesRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";

export const ModalInserir = ({ visible, onCancel, estadoSequence, id }) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getSequenceId();
    }
  }, [id]);

  const inserirSequence = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await sequencesRequest.postSequence(data, id);

      if (response.status === 201) {
        onCancel();
        form.resetFields();
        estadoSequence((prevSequence) => [response.data, ...prevSequence]);
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        form.resetFields();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        estadoSequence((prevState) => prevState.map(cb));
        setConfirmLoading(false);
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSequenceId = async () => {
    setLoading(true);
    try {
      const response = await sequencesRequest.getSequenceId(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          valor_padrao: data.valor_padrao,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title={id ? "Editar" : "Inserir"}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) => inserirSequence(values))
            }
          >
            <Form.Item
              name="nome"
              label="Nome:"
              style={{ width: "100%", fontWeight: "500", margin: 0 }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="valor_padrao"
              label="Valor padrão:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
            >
              <InputNumber max={2147483647} style={{ width: "30%" }} />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              style={{ fontWeight: "500", marginTop: 10 }}
              valuePropName="checked"
            >
              <Switch style={{ marginTop: -20 }} />
            </Form.Item>

            <Divider />

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        visible={openModalStatus.create}
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
