/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { colors } from "../../../../../Utils/temas";
import { triggerRequest } from "../../triggerRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";
const { TextArea } = Input;
const { useForm } = Form;

export const ModalInsert = ({
  visible,
  onCancel,
  id,
  estadoTriggers,
  idTrigger,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (idTrigger) {
      getValuesTrigger();
    }
  }, [idTrigger]);

  const postTrigger = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await triggerRequest.postTrigger(data, idTrigger);
      if (response.status === 201) {
        const { data } = response;
        estadoTriggers((prevState) => [data, ...prevState]);
        onCancel();
        setConfirmLoading(false);
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const { data } = response;
        const cb = (callback) => {
          if (callback.id === idTrigger) {
            return { ...callback, ...data };
          }
          return { ...callback };
        };
        estadoTriggers((prevState) => prevState.map(cb));
        form.resetFields();
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const options = [
    { value: "AI", label: "After Insert" },
    { value: "AU", label: "After Update" },
    { value: "AD", label: "After Delete" },
    { value: "AIU", label: "After Insert Update" },
    { value: "ATD", label: "After TODOS" },
    { value: "BI", label: "Before Insert" },
    { value: "BU", label: "Before Update" },
    { value: "BD", label: "Before Delete" },
  ];

  const getValuesTrigger = async () => {
    setLoading(true);
    try {
      const response = await triggerRequest.getTriggerId(idTrigger);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          tipo_trigger: data.tipo_trigger,
          texto_firebird: data.texto_firebird,
          em_teste: data.em_teste,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title={idTrigger ? "Editar" : "Inserir"}
        footer={null}
        width="50%"
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form
                .validateFields()
                .then((values) => postTrigger({ ...values, tabela_id: id }));
            }}
            initialValues={{ em_teste: true }}
          >
            <Form.Item name="tabela_id" style={{ display: "none" }} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="nome"
                label="Nome:"
                style={{ fontWeight: "500", width: "56%" }}
                rules={[
                  { required: true, message: "Informe o nome da Trigger" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="tipo_trigger"
                label="Tipo Trigger:"
                style={{ fontWeight: "500", width: "40%" }}
                rules={[
                  { required: true, message: "Informe o tipo da Trigger" },
                ]}
              >
                <Select options={options} />
              </Form.Item>
            </div>

            <Form.Item
              label="Texto Firebird"
              name="texto_firebird"
              style={{ fontWeight: "500" }}
              rules={[
                { required: true, message: "Informe o texto do Firebird" },
              ]}
            >
              <TextArea rows={19} />
            </Form.Item>

            <Form.Item
              label="Em Teste"
              name="em_teste"
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields(["nome", "texto_firebird", "tipo_trigger"]);
                }}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
