/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { aplicacaoRequest } from "./aplicacaoRequest";

export const Aplicacao = ({ id }) => {
  const [aplicacao, setAplicacao] = useState([
    {
      nome_aplicacao: "",
      intervalo_atividade: "",
      data_hora_atualizacao: "",
      status: "",
    },
  ]);

  useEffect(() => {
    getListaAplicacao();
  }, []);

  const getListaAplicacao = async () => {
    try {
      const response = await aplicacaoRequest.getAplicacao({ cartorio_id: id });
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setAplicacao(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dataFormatada = (data) => {
    const date = new Date(data);
    const dateFormatted = date.toLocaleString().replace(",", "");
    return dateFormatted;
  };

  const columns = [
    { title: "Aplicação", dataIndex: "nome_aplicacao" },
    {
      title: "Data atualização",
      dataIndex: "data_hora_atualizacao",
      render: (value) => dataFormatada(value),
    },
    { title: "Intervalo atividade", dataIndex: "intervalo_atividade" },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <Tag color={value === "INATIVO" ? "red" : "green"}>{value}</Tag>
      ),
    },
  ];

  return <Table dataSource={aplicacao} columns={columns} />;
};
