import { Modal } from "antd";
import React from "react";
import TabelaRequest from "../../tabelaRequest";

export const ModalChangeTest = ({
  visible,
  onCancel,
  value,
  id,
  estadoEmTeste,
}) => {
  const changeInTest = async () => {
    const data = {
      em_teste: !value,
      tabela_id: id,
    };

    const response = await TabelaRequest.putEmTeste(data);
    if (response.status === 200) {
      onCancel();
      const cb = (callback) => {
        if (callback.id === id) {
          return { ...callback, ...data };
        } else {
          return { ...callback };
        }
      };
      estadoEmTeste((prevState) => prevState.map(cb));
    }
  };

  return (
    <Modal visible={visible} onCancel={onCancel} onOk={changeInTest}>
      <text style={{ fontWeight: "500", fontSize: 18 }}>
        Deseja {value ? "DESABILITAR" : "HABILITAR"} o campo Em Teste?
      </text>
    </Modal>
  );
};
