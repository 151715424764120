import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function getListaFrases(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "frase/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function CadastrarFrases(Frases, id) {
  const url = id ? `${urlBase}frase/${id}` : `${urlBase}frase/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, Frases, config);
  } else {
    response = await axios.post(url, Frases, config);
  }
  return response;
}

async function getFrases(id) {
  const url = urlBase + `frase/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirFrases(id) {
  const url = urlBase + `frase/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const putFrase = async (id, data) => {
  const url = `${urlBase}frase/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const FrasesRequest = {
  getListaFrases,
  getFrases,
  CadastrarFrases,
  ExcluirFrases,
  putFrase,
};

export default FrasesRequest;
