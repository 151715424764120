import { urlBase } from "../../../Constante/Url";
import axios from "axios";
import { UserTockem } from "../../../Utils/autenticacao";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getAtualizacaoForcada = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;

  const url = `${urlBase}atualizacao-cartorio-forcada/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};

export const requestForcada = {
  getAtualizacaoForcada,
};
