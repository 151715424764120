import { Modal } from "antd";
import React, { useState } from "react";
import { procedureRequest } from "../../../procedureRequest";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, onDelete }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarProcedure = async () => {
    setConfirmLoading(true);
    try {
      const response = await procedureRequest.deleteProcedure(id);
      if (response.status === 204) {
        onDelete((prevState) => {
          return prevState.filter((prevProcedure) => {
            return prevProcedure.id !== id;
          });
        });
        onCancel();
        setConfirmLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarProcedure}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500" }}>
          Deseja excluir esta Procedure?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
