/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  Input,
  Select,
  Form,
  Switch,
  Radio,
  Button,
  Skeleton,
} from "antd";
import { useState, React, useEffect } from "react";
import EmolumentoRequest from "./EmolumentoRequest";
import SelSeloGrupo from "../../Selecao/SeloGrupo/Sel_SeloGrupo";
import SelSeloGrupoIsento from "../../Selecao/SeloGrupo/Sel_SeloGrupo";
import { CloseOutlined } from "@ant-design/icons";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

const { useForm } = Form;
const { Search } = Input;

function CadastroEmolumento({
  id,
  visible,
  onCancel,
  optionsUf,
  sistemaId,
  optionsTipo,
  optionsSistema,
  estadoEmolumento,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalSeloGrupoVisible, setIsModalSeloGrupoVisible] = useState(false);
  const [isModalSeloIsentoGrupoVisible, setIsModalSeloGrupoIsentoVisible] =
    useState(false);
  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getEmolumento();
    }
  }, [id]);

  async function CadastrarEmolumento(emolumento) {
    try {
      const response = await EmolumentoRequest.CadastrarEmolumento(
        emolumento,
        id
      );
      if (response.status === 201) {
        const { data } = response;
        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoEmolumento((prevState) => [...prevState]);
        } else {
          estadoEmolumento((prevState) => [data, ...prevState]);
        }
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const { data } = response;
        if (data.sistema_id !== parseInt(sistemaId)) {
          estadoEmolumento((prevState) =>
            prevState.filter((item) => item.id !== id)
          );
        } else {
          const cb = (emolumentoPrev) => {
            if (emolumentoPrev.id === id) return { ...emolumentoPrev, ...data };
            else return { ...emolumentoPrev };
          };
          estadoEmolumento((prevState) => prevState.map(cb));
          setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        }
        setConfirmLoading(false);
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarEmolumento(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const buscarSeloGrupoId = () => {
    setIsModalSeloGrupoVisible(true);
  };

  const buscarSeloGrupoIsentoId = () => {
    setIsModalSeloGrupoIsentoVisible(true);
  };

  function onSelecionarSeloGrupo(valor) {
    setIsModalSeloGrupoVisible(false);
    form.setFieldsValue({
      selo_grupo_id: valor.id,
      selo_grupo_descricao: valor.descricao,
    });
  }

  const LimparGrupo = () => {
    form.setFieldsValue({
      selo_grupo_id: null,
      selo_grupo_descricao: null,
    });
  };

  function onSelecionarSeloGrupoIsento(valor) {
    setIsModalSeloGrupoIsentoVisible(false);
    form.setFieldsValue({
      selo_grupo_isento_id: valor.id,
      selo_grupo_isento_descricao: valor.descricao,
    });
  }

  const LimparGrupoIsento = () => {
    form.setFieldsValue({
      selo_grupo_isento: null,
      selo_grupo_isento_descricao: null,
    });
  };

  const getEmolumento = async () => {
    setLoading(true);
    try {
      const response = await EmolumentoRequest.getEmolumento(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        form.setFieldsValue({
          calcular_valor_outra_taxa1: data.calcular_valor_outra_taxa1,
          descricao: data.descricao,
          selo_grupo_descricao: data.selo_grupo_descricao,
          selo_grupo_id: data.selo_grupo_id,
          selo_grupo_isento_descricao: data.selo_grupo_isento_descricao,
          selo_grupo_isento_id: data.selo_grupo_isento_id,
          selo_grupo_isento_numero: data.selo_grupo_isento_numero,
          selo_grupo_numero: data.selo_grupo_numero,
          sistema_descricao: data.sistema_descricao,
          situacao: data.situacao,
          tipo: data.tipo,
          uf: data.uf,
        });

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  form.setFieldsValue({ sistema_id: parseInt(sistemaId) });

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Form.Item name="id" style={{ display: "none" }} />

            <div style={{ display: "flex", gap: 16 }}>
              <Form.Item
                name="descricao"
                label="Descrição"
                rules={[
                  {
                    required: true,
                    message: "Informe a descrição",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="sistema_id"
                label="Sistema"
                rules={[
                  {
                    required: true,
                    message: "Informe a descrição",
                  },
                ]}
              >
                <Select options={optionsSistema} style={{ width: "200px" }} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", gap: 16 }}>
              <div style={{ display: "flex", gap: 8, width: "100%" }}>
                <Form.Item
                  name="selo_grupo_id"
                  style={{ width: "100%", marginBottom: 0 }}
                >
                  <Form.Item
                    name="selo_grupo_descricao"
                    label="Selo Grupo"
                    rules={[
                      {
                        required: true,
                        message: "Informe a descrição",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Search readOnly allowClear onSearch={buscarSeloGrupoId} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label="." style={{ marginBottom: 0 }}>
                  <Button onClick={LimparGrupo} icon={<CloseOutlined />} />
                </Form.Item>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 8,
                  width: "100%",
                  marginBottom: 0,
                }}
              >
                <Form.Item
                  name="selo_grupo_isento_id"
                  style={{ width: "100%", marginBottom: 0 }}
                >
                  <Form.Item
                    name="selo_grupo_isento_descricao"
                    label="Selo Grupo isento"
                    rules={[
                      {
                        required: true,
                        message: "Informe a descrição",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Search
                      readOnly
                      allowClear
                      onSearch={buscarSeloGrupoIsentoId}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item label="." style={{ marginBottom: 0 }}>
                  <Button
                    onClick={LimparGrupoIsento}
                    icon={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
            </div>

            <div style={{ display: "flex", gap: 16, marginTop: 32 }}>
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  width: "49%",
                }}
              >
                <Form.Item name="uf" label="UF" style={{ width: "100%" }}>
                  <Select name="uf" options={optionsUf} />
                </Form.Item>
                <Form.Item name="tipo" label="Tipo" style={{ width: "100%" }}>
                  <Select options={optionsTipo} />
                </Form.Item>
              </div>

              <div style={{ display: "flex", gap: 0 }}>
                <Form.Item
                  name="situacao"
                  label="Situação"
                  style={{ width: "max-content" }}
                >
                  <Radio.Group>
                    <Radio value={"A"}>Ativo</Radio>
                    <Radio value={"I"}>Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="calcular_valor_outra_taxa1"
                  label="Calcular Outra Taxa"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={true} unCheckedChildren={false} />
                </Form.Item>
              </div>
            </div>
          </Form>
        )}

        <SelSeloGrupo
          onSelecionado={(valor) => {
            onSelecionarSeloGrupo(valor);
          }}
          visible={isModalSeloGrupoVisible}
          onCancel={() => setIsModalSeloGrupoVisible(false)}
          optionsSistema={optionsSistema}
          optionsUf={optionsUf}
        />
        <SelSeloGrupoIsento
          onSelecionado={(valor) => {
            onSelecionarSeloGrupoIsento(valor);
          }}
          visible={isModalSeloIsentoGrupoVisible}
          onCancel={() => setIsModalSeloGrupoIsentoVisible(false)}
          optionsSistema={optionsSistema}
          optionsTipo={optionsTipo}
          optionsUf={optionsUf}
        />
      </Modal>

      <ModalStatusEdit
        visible={openModalStatus.edit}
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
      />
      <ModalStatusCreate
        visible={openModalStatus.create}
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
      />
    </>
  );
}

export default CadastroEmolumento;
