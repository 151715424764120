import { Button, Modal } from "antd";
import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";

export const ErrorModal = ({ visible, onCancel, msg, status }) => (
  <Modal visible={visible} onCancel={onCancel} footer={null}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CloseCircleFilled style={{ fontSize: 36, color: "#b43126" }} />
      <text style={{ fontWeight: "500", fontSize: 18, textAlign: "center" }}>
        {msg}
      </text>

      {status === 404 && (
        <Button
          style={{
            background: "#b43126",
            color: "white",
            fontWeight: "500",
            marginTop: 10,
            borderColor: "#b43126",
          }}
          href="/"
        >
          Voltar para o início
        </Button>
      )}
    </div>
  </Modal>
);
