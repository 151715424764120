import { Modal } from "antd";
import React, { useState } from "react";
import { viewRequest } from "../../../viewRequest";
import { ModalStatusDelete } from "../../../../../Utils/modalStatus";

export const ModalDelete = ({ onCancel, visible, id, estadoView }) => {
  const [loading, setLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteView = async () => {
    setLoading(true);
    try {
      const response = await viewRequest.deleteView(id);
      if (response.status === 204) {
        estadoView((prevState) =>
          prevState.filter((item) => {
            return item.id !== id;
          })
        );
        onCancel();
        setLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        onOk={deleteView}
        onCancel={onCancel}
        visible={visible}
        confirmLoading={loading}
      >
        <text style={{ fontSize: 16, fontWeight: "500" }}>
          Deseja deletar esta View?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
