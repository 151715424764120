/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import EmolumentoItemRequest from "../../EmolumentoItemRequest";
import { colors } from "../../../../Utils/temas";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../Utils/modalStatus";

const { useForm } = Form;

export const ModalEmolumentItem = ({
  id,
  visible,
  onCancel,
  emolumento_id,
  emolumento_periodo_id,
  estadoEmolumentoItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getEmolumentoItem();
    }
  }, [id]);

  const inserirEmolumentoItem = async (data) => {
    setLoadingConfirm(true);
    try {
      const response = await EmolumentoItemRequest.postEmolumentoItem(data, id);
      if (response.status === 201) {
        estadoEmolumentoItem();
        form.resetFields();
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
        estadoEmolumentoItem();
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConfirm(false);
    }
  };

  const getEmolumentoItem = async () => {
    setLoading(true);
    try {
      const response = await EmolumentoItemRequest.getEmolumentoItemId(id);
      if (response.status === 200) {
        const { data } = response;

        form.setFieldsValue({
          codigo_selo: data.codigo_selo,
          codigo_selo_anterior: data.codigo_selo_anterior,
          valor_emolumento: data.valor_emolumento,
          valor_fim: data.valor_fim,
          valor_inicio: data.valor_inicio,
          valor_taxa_judiciaria: data.valor_taxa_judiciaria,
        });

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title="Inserir"
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            style={{ fontWeight: "500" }}
            onFinish={(e) =>
              inserirEmolumentoItem({
                ...e,
                emolumento_id: emolumento_id,
                emolumento_periodo_id: emolumento_periodo_id,
              })
            }
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                label="Valor inicio:"
                style={{ width: "48%" }}
                name="valor_inicio"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Valor fim:"
                style={{ width: "48%" }}
                name="valor_fim"
              >
                <Input />
              </Form.Item>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                label="Valor emolumento:"
                style={{ width: "48%" }}
                name="valor_emolumento"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Valor taxa judiciária:"
                style={{ width: "48%" }}
                name="valor_taxa_judiciaria"
              >
                <Input />
              </Form.Item>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                label="Código selo:"
                style={{ width: "48%" }}
                name="codigo_selo"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Código selo anterior:"
                style={{ width: "48%" }}
                name="codigo_selo_anterior"
              >
                <Input />
              </Form.Item>
            </div>

            <Divider />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "36%",
                }}
              >
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{
                    background: colors.secondary,
                    color: "white",
                    fontWeight: "500",
                  }}
                  htmlType="submit"
                  loading={loadingConfirm}
                >
                  Inserir
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
