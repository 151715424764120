/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Row, Col, Input, Select, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import CartorioRequest from "../../../../CartorioRequest";
import { ModalStatusCreate, ModalStatusEdit } from "../../../../../../Utils/modalStatus";
const { useForm } = Form;

function CadastroAcessoRemoto({
  visible,
  onCancel,
  estadoAcesso,
  id,
  idAcessoRemoto,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (idAcessoRemoto) {
      getAcessoId();
    }
  }, [visible]);

  async function CadastrarAcessoRemoto(acessoRemoto) {
    try {
      const response = await CartorioRequest.CadastrarAcessoRemoto(
        acessoRemoto,
        idAcessoRemoto
      );
      if (response.status === 201) {
        estadoAcesso((prevState) => [response.data, ...prevState]);
        onCancel();
        setOpenStatusModal((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const cb = (callback) => {
          if (callback.id === idAcessoRemoto) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        setOpenStatusModal((prevState) => ({ ...prevState, edit: true }));
        estadoAcesso((prevState) => prevState.map(cb));
        onCancel();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarAcessoRemoto({ ...values, cartorio_id: id });
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const { TextArea } = Input;

  const getAcessoId = async () => {
    setLoading(true);
    try {
      const response = await CartorioRequest.getAcessoRemoto(idAcessoRemoto);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          tipo_conexao: data.tipo_conexao,
          conexao_id: data.conexao_id,
          conexao_senha: data.conexao_senha,
          windows_usuario: data.windows_usuario,
          windows_senha: data.windows_senha,
          conexao_obs: data.conexao_obs,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={idAcessoRemoto ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Form.Item label="Tipo Conexão" name="tipo_conexao">
              <Select
                style={{ width: "50%" }}
                options={[
                  {
                    value: "AnyDesk",
                    label: "AnyDesk",
                  },
                  { value: "TeamViewer", label: "TeamViewer" },
                  { value: "LogMein", label: "LogMein" },
                  { value: "RDP", label: "RDP" },
                ]}
              />
            </Form.Item>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item name="conexao_id" label="Conexão ID">
                  <Input />
                </Form.Item>

                <Form.Item name="windows_usuario" label="Windows usuário">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="conexao_senha" label="Conexão senha">
                  <Input />
                </Form.Item>

                <Form.Item name="windows_senha" label="Windows senha">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="conexao_obs" label="Observação">
                  <TextArea rows={6} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, create: false }))
        }
        visible={openStatusModal.create}
      />
       <ModalStatusEdit
        onCancel={() =>
          setOpenStatusModal((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openStatusModal.edit}
      />
    </>
  );
}

export default CadastroAcessoRemoto;
