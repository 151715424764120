import React, { useState } from "react";
import { Avatar, Button } from "antd";
import { LogoutOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { colors } from "../../Utils/temas";
import { ModalTrocarSenha } from "./componentes/modal/modalTrocarSenha";
import "./estilo.css";
import { useUserContext } from "../../contexts/usersContext";

const MenuSuperior = () => {
  const [open, setOpen] = useState(false);
  const [openChange, setOpenChange] = useState(false);

  const navigate = useNavigate();

  const { logout, currentUser } = useUserContext();

  const sairSistema = () => {
    logout();
    navigate("/");
    window.location.reload(false);
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(!open)}
        type="link"
        icon={
          <div
            style={{
              background: open ? colors.terciary : "transparent",
              transition: "background 0.2s",
              borderRadius: "100%",
              width: 44,
              height: 43.4,
              padding: "1.8px 1.8px 1.8px 2px",
            }}
          >
            <Avatar
              icon={
                <text style={{ fontWeight: "500" }}>
                  {currentUser.nome[0].toLocaleUpperCase()}
                </text>
              }
              style={{
                background: colors.secondary,
                border: "2px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              size={40}
            />
          </div>
        }
        style={{ alignItems: "center" }}
      />

      <div
        style={{
          position: "fixed",
          background: "red",
          height: open ? "100%" : 0,
          left: 0,
          top: 0,
          right: 6,
          bottom: 0,
          zIndex: 100,
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClick={() => setOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            background: colors.primary,
            borderRadius: 4,
            zIndex: 2,
            alignItems: "flex-start",
            transition: "height 0.08s, width 0.1s linear",
            height: open ? 80 : 0,
            overflow: "hidden",
            marginTop: 60,
          }}
        >
          <Button
            onClick={sairSistema}
            type="text"
            icon={
              <LogoutOutlined
                style={{
                  color: "white",
                  paddingRight: 10,
                }}
              />
            }
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 10px 20px 10px",
            }}
            className="botaoHeader"
          >
            <text style={{ color: "white", fontWeight: "600" }}>
              Sair da conta
            </text>
          </Button>

          <Button
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 10px 20px 10px",
              borderTop: "1px solid #0d484a",
              width: "100%",
            }}
            type="text"
            icon={
              <EditOutlined
                style={{
                  color: "white",
                  paddingRight: 10,
                }}
              />
            }
            onClick={() => setOpenChange(true)}
            className="botaoHeader"
          >
            <text style={{ color: "white", fontWeight: "600" }}>
              Mudar senha
            </text>
          </Button>
        </div>
      </div>

      <ModalTrocarSenha
        onCancel={() => setOpenChange(false)}
        visible={openChange}
      />
    </div>
  );
};

export default MenuSuperior;
