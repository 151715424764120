import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import React from "react";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { colors } from "../../../../Utils/temas";
const { useForm, Item } = Form;
const { Group } = Radio;

export const DrawerFilter = ({ visible, onClose, onPesquisar, onCancel }) => {
  const [form] = useForm();
  return (
    <Drawer visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          fontWeight: "500",
        }}
        onFinish={onPesquisar}
      >
        <Item name="nome" label="Nome:">
          <Input />
        </Item>

        <Item name="em_teste" label="Em teste:">
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Item>

        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<SearchOutlined />}
              htmlType="submit"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
              className="botao"
            >
              Pesquisar
            </Button>
            <Button
              icon={<ClearOutlined />}
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
            >
              Limpar
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};
