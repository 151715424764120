import { Button, Divider, Drawer, Form, Input, Radio } from "antd";
import { PlusOutlined, ClearOutlined } from "@ant-design/icons";
import React from "react";
import { colors } from "../../../../../Utils/temas";
const { useForm, Item } = Form;
const { Group } = Radio;

export const DrawerFilter = ({ visible, onClose, filtros, limpar }) => {
  const [form] = useForm();
  return (
    <Drawer title="Filtros" visible={visible} onClose={onClose}>
      <Form
        form={form}
        layout="vertical"
        style={{ fontWeight: "500" }}
        onFinish={filtros}
      >
        <Item label="Nome:" name="nome">
          <Input />
        </Item>

        <Item name="em_teste" label="Em teste:" style={{ fontWeight: "500" }}>
          <Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Group>
        </Item>

        <Divider />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{
              background: colors.secondary,
              color: "white",
              fontWeight: "500",
            }}
            className="botao"
            icon={<PlusOutlined />}
            htmlType="submit"
          >
            Filtros
          </Button>
          <Button
            icon={<ClearOutlined />}
            onClick={() => {
              limpar();
              form.resetFields();
            }}
          >
            Limpar
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
