export const styles = {
  containerButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
  },
  containerLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cartorioSelecionado: {
    fontWeight: "600",
    fontSize: 16,
  },
  containerCartorioSelecionados: {
    background: "#FFFFFF",
    padding: 15,
    borderBottom: "1px solid #FAFAFA",
  },
  containerCartorio: {
    background: "#FFFFFF",
    padding: 15,
    borderBottom: "1px solid #FAFAFA",
  },
  espacamentos: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "94.6%",
  },
  textCartorios: {
    fontWeight: "600",
    fontSize: 16,
    marginBottom: 15,
  },
  containerLista: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px 0px 15px",
  },
};
