export const styles = {
  containerLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  containerButtons: {
    padding: "5px 5px 5px 5px",
    display: "flex",
    justifyContent: "space-between",
  },
};
