/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Switch, Skeleton, Button, Divider } from "antd";
import { viewRequest } from "../../../viewRequest";
import { colors } from "../../../../../Utils/temas";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";
const { useForm } = Form;
const { TextArea } = Input;

export const ModalInsert = ({ visible, onCancel, estadoView, id }) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      getViewId();
    }
  }, [id]);

  const inserirView = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await viewRequest.postView(data, id);
      if (response.status === 201) {
        const { data } = response;
        estadoView((prevState) => [data, ...prevState]);
        form.resetFields();
        setConfirmLoading(false);
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        const { data } = response;
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...data };
          } else {
            return { ...callback };
          }
        };
        estadoView((prevState) => prevState.map(cb));
        onCancel();
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getViewId = async () => {
    setLoading(true);
    try {
      const response = await viewRequest.getViewId(id);
      if (response.status === 200) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          em_teste: data.em_teste,
          texto_firebird: data.texto_firebird,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={id ? "Editar" : "Inserir"}
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        width="50%"
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" onFinish={(e) => inserirView(e)}>
            <Form.Item
              name="nome"
              label="Nome:"
              style={{ fontWeight: "500", margin: 0 }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="texto_firebird"
              label="Texto Firebird:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
              rules={[{ required: true, message: "Este campo é obrigatório." }]}
            >
              <TextArea rows={19} />
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em Teste:"
              style={{ fontWeight: "500", margin: "10px 0px 0px 0px" }}
              valuePropName="checked"
            >
              <Switch style={{ marginTop: -20 }} />
            </Form.Item>

            <Divider />

            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 20 }}
            >
              <Button onClick={onCancel}>Cancelar</Button>

              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusCreate
        visible={openModalStatus.create}
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
      />
      <ModalStatusEdit
        visible={openModalStatus.edit}
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
      />
    </>
  );
};
