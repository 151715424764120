import { Modal } from "antd";
import React from "react";

export const ModalCreatePk = ({ visible, onCancel, onOk }) => (
  <Modal visible={visible} onCancel={onCancel} onOk={onOk}>
    <text style={{ fontWeight: "500", fontSize: 16 }}>
      Deseja Realmente criar uma Pk para esse campo?
    </text>
  </Modal>
);
