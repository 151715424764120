/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Skeleton,
  Button,
  Divider,
} from "antd";
import { useState, React, useEffect } from "react";
import EmolumentoPeriodoRequest from "./EmolumentoPeriodoRequest";
import moment from "moment";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

const { useForm } = Form;

function CadastroEmolumentoPeriodo({
  visible,
  onCancel,
  optionsUf,
  estadoEmolumento,
  id,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (id) {
      fetchRequest();
    }
  }, [id]);

  async function CadastrarEmolumentoPeriodo(emolumentoPeriodo) {
    setConfirmLoading(true);
    try {
      const response =
        await EmolumentoPeriodoRequest.CadastrarEmolumentoPeriodo(
          emolumentoPeriodo,
          id
        );
      if (response.status === 201) {
        form.resetFields();
        setConfirmLoading(false);
        estadoEmolumento((prevState) => [response.data, ...prevState]);
        onCancel();
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }

      if (response.status === 200) {
        const { data } = response;
        setConfirmLoading(false);
        onCancel();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...data };
          } else
            return {
              ...callback,
            };
        };
        estadoEmolumento((prevState) => prevState.map(cb));
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchRequest = async () => {
    setLoading(true);
    try {
      const response = await EmolumentoPeriodoRequest.getEmolumentoPeriodo(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;

        setLoading(false);
        form.setFieldsValue({
          descricao: data.descricao,
          situacao: data.situacao,
          uf: data.uf,
          data_inicial: data.data_inicial ? moment(data.data_inicial) : null,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        confirmLoading={confirmLoading}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() =>
              form.validateFields().then((values) =>
                CadastrarEmolumentoPeriodo({
                  ...values,
                  data_inicial: values?.data_inicial?.startOf("day"),
                })
              )
            }
          >
            <Form.Item name="id" style={{ display: "none" }} />

            <div style={{ display: "flex", gap: 16 }}>
              <Form.Item
                name="descricao"
                label="Descrição"
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item name="uf" label="UF" style={{ width: "10%" }}>
                <Select name="uf" options={optionsUf} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", gap: 32 }}>
              <Form.Item
                name="situacao"
                label="Situação"
                style={{ marginBottom: 0 }}
              >
                <Radio.Group>
                  <Radio value={"A"}>Ativo</Radio>
                  <Radio value={"I"}>Inativo</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="data_inicial"
                label="Data inicial"
                style={{ marginBottom: 0 }}
              >
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </div>

            <Divider />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  width: "26%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={onCancel}>Cancelar</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal>

      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevStatus) => ({ ...prevStatus, edit: false }))
        }
        visible={openModalStatus.edit}
      />
      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevStatus) => ({ ...prevStatus, create: false }))
        }
        visible={openModalStatus.create}
      />
    </>
  );
}

export default CadastroEmolumentoPeriodo;
