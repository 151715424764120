import { Modal } from "antd";
import React, { useState } from "react";
import { newConfigRequest } from "../request";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoConfig }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deleteConfig = async () => {
    setConfirmLoading(true);
    try {
      const response = await newConfigRequest.ExcluirConfig(id);
      if (response.status === 204) {
        onCancel();
        estadoConfig(response);
        setConfirmLoading(false);
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deleteConfig}
        confirmLoading={confirmLoading}
      >
        <text style={{ fontWeight: "500", fontSize: 16 }}>
          Deseja realmente deletar esta Config?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
