import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalExecutar = ({ visible, onCancel, onOk }) => {
  return (
    <Modal visible={visible} onCancel={onCancel} footer={false}>
      <Result
        status="warning"
        title="Tem certeza que quer executar essa atualização?"
        extra={[
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button onClick={onCancel}>Cancelar</Button>
            <Button type="primary" onClick={onOk}>
              Confirmar
            </Button>
          </div>,
        ]}
      />
    </Modal>
  );
};
