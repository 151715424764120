import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function getListaEmolumentoPeriodo(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url =
    urlBase + "emolumento-periodo/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

async function CadastrarEmolumentoPeriodo(emolumentoPeriodo, id) {
  let url = id
    ? `${urlBase}emolumento-periodo/${id}/`
    : `${urlBase}emolumento-periodo/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, emolumentoPeriodo, config);
  } else {
    response = await axios.post(url, emolumentoPeriodo, config);
  }
  return response;
}

async function getEmolumentoPeriodo(id) {
  const url = urlBase + `emolumento-periodo/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function ExcluirEmolumentoPeriodo(id) {
  const url = urlBase + `emolumento-periodo/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const editarEmolumentoPeriodo = async (id, data) => {
  const url = `${urlBase}emolumento-periodo/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const EmolumentoPeriodoRequest = {
  getListaEmolumentoPeriodo,
  getEmolumentoPeriodo,
  CadastrarEmolumentoPeriodo,
  ExcluirEmolumentoPeriodo,
  editarEmolumentoPeriodo,
};

export default EmolumentoPeriodoRequest;
