import React, { useState } from "react";
import Image from "../../Imagens/background.png";
import { Button, Divider, Input } from "antd";
import { esqueciSenhaRequest } from "./esqueciSenhaRequest";

export const EsqueciSenha = () => {
  const [email, setEmail] = useState("");
  const [codigo, setCodigo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);

  const [errorsExists, setErrorsExists] = useState({});

  const postReset = async () => {
    setLoading(true);
    try {
      const response = await esqueciSenhaRequest.passwordReset({
        email: email,
      });
      if (response.status === 200 || response.status === 201) {
        setStep(step + 1);
        setLoading(false);
        setErrorsExists({});
      }
    } catch (error) {
      const { data } = error.response;
      setErrorsExists(data);
    } finally {
      setLoading(false);
    }
  };

  const postToken = async () => {
    setLoading(true);
    try {
      const response = await esqueciSenhaRequest.validateToken({
        token: codigo,
      });
      if (response.status === 200 || response.status === 201) {
        setStep(step + 1);
        setLoading(false);
        setErrorsExists({});
      }
    } catch (error) {
      const { data } = error.response;
      setErrorsExists(data);
    } finally {
      setLoading(false);
    }
  };

  const postConfirmPassword = async () => {
    setLoading(true);
    try {
      const data = {
        password: password,
        token: codigo,
      };
      const response = await esqueciSenhaRequest.confirmPassword(data);
      if (response.status === 200 || response.status === 201) {
        window.location.href = "/Login";
        setLoading(false);
        setErrorsExists({});
      }
    } catch (error) {
      const { data } = error.response;
      setErrorsExists(data);
    } finally {
      setLoading(false);
    }
  };

  const passwordEquals = () => {
    if (password === confirmPassword) {
      postConfirmPassword();
    } else {
      setErrorsExists({ password: "As senhas devem ser iguais." });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${Image})`,
        height: "100vh",
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "#F3F3F1",
          borderRadius: 37,
          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.10)",
          padding: "38px 49px",
          textAlign: "center",
          width: 500,
        }}
      >
        {step === 1 && (
          <>
            <text
              style={{
                color: "#222",
                fontSize: 36,
                fontWeight: "500",
              }}
            >
              Perdeu a senha?
            </text>
            <div
              style={{
                marginTop: 41,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Input
                style={{ borderRadius: 10, height: 53 }}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorsExists && (
                <text style={{ textAlign: "left", color: "red" }}>
                  {errorsExists.email}
                </text>
              )}
              <Button
                type="primary"
                style={{ width: "100%", marginTop: 15 }}
                onClick={postReset}
                loading={loading}
              >
                Continuar
              </Button>

              <Divider />

              <Button type="text" href="/Login">
                Cancelar
              </Button>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <text
              style={{
                color: "#222",
                fontSize: 36,
                fontWeight: "500",
              }}
            >
              Inserir código
            </text>
            <div
              style={{
                marginTop: 41,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Input
                style={{ borderRadius: 10, height: 53 }}
                placeholder="Código"
                onChange={(e) => setCodigo(e.target.value)}
              />
              {errorsExists && (
                <text style={{ textAlign: "left", color: "red" }}>
                  {errorsExists.token || errorsExists.detail}
                </text>
              )}
              <Button
                type="primary"
                style={{ width: "100%", marginTop: 15 }}
                onClick={postToken}
                loading={loading}
              >
                Continuar
              </Button>

              <Divider />

              <Button type="text" onClick={() => setStep(step - 1)}>
                Voltar
              </Button>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <text
              style={{
                color: "#222",
                fontSize: 36,
                fontWeight: "500",
              }}
            >
              Resetar senha
            </text>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 120,
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Input
                style={{ borderRadius: 10, height: 53 }}
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              {errorsExists && (
                <text style={{ textAlign: "left", color: "red" }}>
                  {errorsExists.password}
                </text>
              )}
              <Input
                style={{ borderRadius: 10, height: 53 }}
                placeholder="Confirmar senha"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
              />
            </div>

            <Button
              type="primary"
              style={{ width: "100%", marginTop: 15 }}
              onClick={passwordEquals}
              loading={loading}
            >
              Criar nova senha
            </Button>

            <Divider />

            <Button type="text" href="/Login" onClick={() => setStep(1)}>
              Voltar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
