import axios from "axios";
import { urlBase } from "../../../Constante/Url";
import { UserTockem } from "../../../Utils/autenticacao";

const getConfig = (params) => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
};

const getAplicacao = (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}aplicacao/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = axios.get(url, config);
  return response;
};

const getListarAplicacao = () => {
  const url = `${urlBase}aplicacao/listar-aplicacao`;
  const config = getConfig();
  const response = axios.get(url, config);
  return response;
};

export const aplicacaoRequest = { getAplicacao, getListarAplicacao };
