import { Button, Modal, Result } from "antd";
import React from "react";

export const ModalNovaAtualizacao = ({
  visible,
  onCancel,
  enviar,
  success,
  onClick,
}) => {
  return (
    <Modal visible={visible} onCancel={onCancel} footer={false}>
      {success ? (
        <Result
          status="success"
          title="Atualização criada com sucesso!"
          extra={[
            <Button onClick={onClick} type="primary" key="console">
              Ok
            </Button>,
          ]}
        />
      ) : (
        <Result
          status="warning"
          title="Tem certeza que quer criar uma nova atualização?"
          extra={
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button onClick={onCancel}>Cancelar</Button>
              <Button type="primary" onClick={enviar}>
                Confirmar
              </Button>
            </div>
          }
        />
      )}
    </Modal>
  );
};
