import { useEffect, useState, React } from "react";
import {
  Button,
  Drawer,
  Form,
  Space,
  Table,
  Col,
  Row,
  Input,
  Tag,
  Radio,
  Divider,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import FrasesRequest from "./FrasesRequest";
import CadastroFrases from "./CadastroFrases";
import SelGrupoFrase from "../../Selecao/GrupoFrase/Sel_GrupoFrase";
import { ModalDelete } from "./modais/modalDelete";
import { colors } from "../../Utils/temas";

const Frases = () => {
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalGrupoFraseVisible, setIsModalGrupoFraseVisible] =
    useState(false);

  const [form_pesquisa] = Form.useForm();

  const [Frases, setFrases] = useState([]);
  const [filtros, setFiltros] = useState([]);

  const [FrasesId, setFrasesId] = useState(null);
  const [idDelete, setIdDelete] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  const { Search } = Input;

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await FrasesRequest.getListaFrases(page, pageSize, params);
    if (response.status === 200) {
      setFrases(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
    setDrawerVisible(false);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
    setDrawerVisible(false);
  };

  const OnCancel = () => {
    setIsModalVisible(false);
    setFrasesId(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  function onInserirClick() {
    showModal();
  }

  async function onEditFrases(id) {
    setFrasesId(id);
    showModal();
  }

  function onDeletarFrases(id) {
    setIdDelete(id);
    setOpenDelete(true);
  }

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  const columns = [
    {
      title: "Texto",
      dataIndex: "texto",
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
      render: (situacao) => {
        let situacao_descricao = "";
        let color = "";

        if (situacao === "A") {
          situacao_descricao = "Ativo";
          color = "green";
        } else {
          situacao_descricao = "Inativo";
          color = "red";
        }
        return <Tag color={color}> {situacao_descricao} </Tag>;
      },
    },
    {
      title: "Grupo",
      dataIndex: "frase_grupo_descricao",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (record) => (
        <Space>
          <Tooltip title="Editar">
            <EditOutlined onClick={(_) => onEditFrases(record.id)} />
          </Tooltip>

          <Tooltip title="Deletar">
            <DeleteOutlined onClick={(_) => onDeletarFrases(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const buscarGrupoFraseId = () => {
    setIsModalGrupoFraseVisible(true);
  };

  function onSelecionarGrupoFrase(valor) {
    setIsModalGrupoFraseVisible(false);
    form_pesquisa.setFieldsValue({
      frase_grupo_id: valor.id,
      frase_grupo_descricao: valor.descricao,
    });
  }

  const tags = {
    situacao: "Situação",
    frase_grupo_id: "Grupo",
  };

  const onRemoverFiltros = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];
    if (key === "frase_grupo_id") {
      form_pesquisa.resetFields([key, "frase_grupo_descricao"]);
    }

    setFiltros(novosFiltros);
    FetchRecords(1, 10, novosFiltros);
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const colorsTag = () => {
            if (value === "A") return "green";
            else if (value === "I") return "red";
            else return "geekblue";
          };

          if (value === "A")
            return (
              <Tag
                closable
                onClose={() => onRemoverFiltros(key)}
                key={key}
                color={colorsTag(key)}
              >
                {tags[key] + ": Ativo"}
              </Tag>
            );
          else if (value === "I")
            return (
              <Tag
                closable
                onClose={() => onRemoverFiltros(key)}
                key={key}
                color={colorsTag(key)}
              >
                {tags[key] + ": Inativo"}
              </Tag>
            );

          if (key === "frase_grupo_id")
            return (
              <Tag
                closable
                onClose={() => onRemoverFiltros(key)}
                key={key}
                color={colorsTag(key)}
              >
                <text style={{ padding: 10 }}>
                  {tags[key] +
                    ": " +
                    form_pesquisa.getFieldValue("frase_grupo_descricao")}
                </text>
              </Tag>
            );
          else if (key === "frase_grupo_descricao") return null;

          return (
            <Tag
              closable
              onClose={() => onRemoverFiltros(key)}
              key={key}
              color={colorsTag(key)}
            >
              {tags[key] + ": " + value}
            </Tag>
          );
        })}
    </>
  );

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between", padding: 5 }}
      >
        <Button
          onClick={onInserirClick}
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <PlusOutlined />
          Inserir
        </Button>

        <Button
          onClick={() =>
            setDrawerVisible((prevDrawerVisible) => !prevDrawerVisible)
          }
          style={{
            background: colors.secondary,
            fontWeight: "500",
            color: "white",
          }}
          className="botao"
        >
          <FilterOutlined />
          Filtros
        </Button>
        <Drawer
          width={600}
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <Form
            form={form_pesquisa}
            layout="vertical"
            onFinish={onPesquisarClick}
            preserve={true}
          >
            <Form.Item name="frase_grupo_id">
              <Form.Item name="frase_grupo_descricao" label="Grupo">
                <Search readOnly allowClear onSearch={buscarGrupoFraseId} />
              </Form.Item>
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <Form.Item name="situacao" label="Situação">
              <Radio.Group>
                <Radio value={"A"}>Ativo</Radio>
                <Radio value={"I"}>Inativo</Radio>
              </Radio.Group>
            </Form.Item>

            <Divider style={{ background: "#DCDCDC" }} />

            <Row gutter={10}>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  style={{
                    background: colors.secondary,
                    fontWeight: "500",
                    color: "white",
                  }}
                  className="botao"
                >
                  <KeyOutlined />
                  Pesquisar
                </Button>
              </Col>

              <Col span={5} push={7}>
                <Button onClick={Limpar}>
                  <CloseOutlined />
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form>
          <SelGrupoFrase
            onSelecionado={(valor) => onSelecionarGrupoFrase(valor)}
            visible={isModalGrupoFraseVisible}
            onCancel={() => setIsModalGrupoFraseVisible(false)}
          />
        </Drawer>
      </div>
      <Table
        loading={loading}
        columns={columns}
        rowKey={"id"}
        dataSource={Frases}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
        title={getTags}
      />
      <CadastroFrases
        visible={isModalVisible}
        onCancel={OnCancel}
        estadoFrases={setFrases}
        id={FrasesId}
      />

      <ModalDelete
        id={idDelete}
        estadoFrase={setFrases}
        onCancel={() => {
          setOpenDelete(false);
          setIdDelete(null);
        }}
        visible={openDelete}
      />
    </div>
  );
};

export default Frases;
