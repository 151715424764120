import axios from "axios";
import { urlBase } from "../../Constante/Url";
import { UserTockem } from "../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

async function getVersaoHistorico(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  let url = urlBase + `versao-historico/?limit=${pageSize}&offset=${skip}`;

  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
}
async function getVersaoId(id) {
  const url = urlBase + `versao-historico/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}
async function getSistema() {
  const url = urlBase + "sistema/";
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

async function postVersao(data, id) {
  const url = id
    ? `${urlBase}versao-historico/${id}/`
    : `${urlBase}versao-historico/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
}

async function deleteVersao(id) {
  const url = urlBase + `versao-historico/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

async function putVersao(id, data) {
  const url = urlBase + `versao-historico/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
}

const versaoHistorico = {
  getVersaoHistorico,
  getSistema,
  getVersaoId,
  postVersao,
  deleteVersao,
  putVersao,
};

export default versaoHistorico;
