import { Modal } from "antd";
import React, { useState } from "react";
import SeloGrupoPaiRequest from "../SeloGrupoPaiRequest";
import { ModalStatusDelete } from "../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, afterDelete }) => {
  const [confirmLoading, setConfirmLoadig] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const deletarGrupoPai = async () => {
    setConfirmLoadig(true);
    try {
      const response = await SeloGrupoPaiRequest.ExcluirSeloGrupoPai(id);
      if (response.status === 204) {
        setConfirmLoadig(false);
        afterDelete((prevState) => prevState.filter((item) => item.id !== id));
        onCancel();
        setOpenModalStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={deletarGrupoPai}
      >
        <text style={{ fontWeight: "500", fontSize: 17 }}>
          Deseja realmente excluir este Selo Grupo Pai?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenModalStatus(false)}
        visible={openModalStatus}
      />
    </>
  );
};
