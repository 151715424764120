/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CartorioRequest from "../CartorioRequest";
import { AcessoRemotoTab } from "./acessoRemoto";
import { Estacao } from "./estacao";
import { VersaoCart } from "./versão";
import { Aplicacao } from "./aplicacao";
import { ErrorModal } from "../../../Utils/errorModal";

export const DetalhesCartorio = () => {
  const { cartorioId } = useParams();

  const [cartorio, setCartorio] = useState({ nome_cartorio: "", cpfcnpj: "" });

  const [error, setError] = useState({ status: null, open: false });

  useEffect(() => {
    getCartorio();
  }, []);

  const getCartorio = async () => {
    try {
      const response = await CartorioRequest.getCartorio(cartorioId);
      if (response.status === 200 || response.status === 201) {
        setCartorio(response.data);
      }
    } catch (error) {
      const { status } = error.response;
      setError({ status: status, open: true });
    }
  };

  const { TabPane } = Tabs;

  return (
    <>
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "49%" }}>
            <text style={{ fontWeight: "500" }}>Cartório:</text>
            <Input readOnly value={cartorio.nome_cartorio} />
          </div>

          <div style={{ width: "49%" }}>
            <text style={{ fontWeight: "500" }}>CPF/CNPJ</text>
            <Input readOnly value={cartorio.cpfcnpj} />
          </div>
        </div>
      </div>
      <Tabs type="card" size="large" style={{ marginTop: 20 }}>
        <TabPane key="1" tab="Acesso Remoto" style={{ marginTop: -16 }}>
          <AcessoRemotoTab id={cartorioId} />
        </TabPane>

        <TabPane key="2" tab="Estação" style={{ marginTop: -16 }}>
          <Estacao id={cartorioId} />
        </TabPane>

        <TabPane key="3" tab="Versão" style={{ marginTop: -16 }}>
          <VersaoCart id={cartorioId} />
        </TabPane>

        <TabPane key="4" tab="Aplicação" style={{ marginTop: -16 }}>
          <Aplicacao id={cartorioId} />
        </TabPane>
      </Tabs>

      <ErrorModal
        msg="Desculpe, parece que o cartório que você está procurando não pode ser encontrado. Por favor, verifique o URL e tente novamente."
        onCancel={() => setError({ open: false, status: null })}
        visible={error.open}
        status={error.status}
      />
    </>
  );
};
