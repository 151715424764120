/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Skeleton } from "antd";
import { useState, React, useEffect } from "react";
import SeloGrupoFilhoRequest from "./SeloGrupoFilhoRequest";
import SelSeloGrupo from "../../Selecao/SeloGrupo/Sel_SeloGrupo";
import { SelSeloGrupoPai } from "../../Selecao/SeloGrupo/SeloGrupoPai/index";
import { ModalStatusCreate, ModalStatusEdit } from "../../Utils/modalStatus";

function CadastroSeloGrupoFilho({
  id,
  form,
  visible,
  onCancel,
  afterPost,
  optionsUf,
  optionsSistema,
}) {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalSeloGrupoPaiVisible, setIsModalSeloGrupoPaiVisible] =
    useState(false);
  const [isModalSeloGrupoVisible, setIsModalSeloGrupoVisible] = useState(false);

  const [errorExists, setErrorExists] = useState("");

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  useEffect(() => {
    if (id) {
      getSeloGrupoFilhoId();
    }
  }, [id]);

  const { Search } = Input;

  async function CadastrarSeloGrupoFilho(seloGrupoFilho) {
    setConfirmLoading(false);
    try {
      const response = await SeloGrupoFilhoRequest.CadastrarSeloGrupoFilho(
        seloGrupoFilho,
        id
      );
      if (response.status === 201) {
        form.resetFields();
        afterPost((prevState) => [response.data, ...prevState]);
        onCancel();
        setErrorExists("");
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        form.resetFields();
        const cb = (callback) => {
          if (callback.id === id) {
            return { ...callback, ...response.data };
          } else {
            return { ...callback };
          }
        };
        afterPost((prevState) => prevState.map(cb));
        onCancel();
        setErrorExists("");
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      const { non_field_errors } = error.response.data;
      if (non_field_errors) {
        setErrorExists(non_field_errors[0]);
      }
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        CadastrarSeloGrupoFilho(values);
      })
      .catch((info) => {
        console.error("erro:", info);
      });
  };

  const buscarSeloGrupoPaiId = () => {
    setIsModalSeloGrupoPaiVisible(true);
  };

  function onSelecionarSeloGrupoPai(valor) {
    setIsModalSeloGrupoPaiVisible(false);
    form.setFieldsValue({
      selo_grupo_pai_id: valor?.id,
      selo_grupo_pai_descricao: valor?.selo_grupo_descricao,
    });
  }

  const buscarSeloGrupoId = () => {
    setIsModalSeloGrupoVisible(true);
  };

  function onSelecionarSeloGrupo(valor) {
    setIsModalSeloGrupoVisible(false);
    form.setFieldsValue({
      selo_grupo_id: valor.id,
      selo_grupo_descricao: valor.descricao,
    });
  }

  const getSeloGrupoFilhoId = async () => {
    setLoading(true);
    try {
      const response = await SeloGrupoFilhoRequest.getSeloGrupoFilho(id);
      if (response.status) {
        const {
          selo_grupo_pai_id,
          selo_grupo_pai_descricao,
          selo_grupo_id,
          selo_grupo_descricao,
        } = response.data;
        form.setFieldsValue({
          selo_grupo_descricao: selo_grupo_descricao,
          selo_grupo_pai_id: selo_grupo_pai_id,
          selo_grupo_id: selo_grupo_id,
          selo_grupo_pai_descricao: selo_grupo_pai_descricao,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        width={800}
        title={id ? "Editar" : "Cadastro"}
        visible={visible}
        onOk={handleOk}
        onCancel={onCancel}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form form={form} layout="vertical" preserve={false}>
            <Form.Item name="selo_grupo_pai_id" style={{ display: "none" }} />
            <Form.Item
              name="selo_grupo_pai_descricao"
              label="Grupo Pai"
              rules={[
                { required: true, message: "Por favor, informe o Grupo Pai" },
              ]}
            >
              <Search readOnly allowClear onSearch={buscarSeloGrupoPaiId} />
            </Form.Item>

            <Form.Item name="selo_grupo_id" style={{ display: "none" }} />
            <Form.Item
              name="selo_grupo_descricao"
              label="Grupo"
              style={{ margin: 0 }}
              rules={[
                { required: true, message: "Por favor, informe o Grupo" },
              ]}
            >
              <Search readOnly allowClear onSearch={buscarSeloGrupoId} />
            </Form.Item>
            {errorExists && (
              <text style={{ color: "red", marginTop: 2 }}>{errorExists}</text>
            )}
          </Form>
        )}

        <SelSeloGrupo
          onSelecionado={(valor) => {
            onSelecionarSeloGrupo(valor);
          }}
          visible={isModalSeloGrupoVisible}
          onCancel={() => setIsModalSeloGrupoVisible(false)}
          optionsSistema={optionsSistema}
          optionsUf={optionsUf}
        />
        <SelSeloGrupoPai
          onSelecionado={(valor) => {
            onSelecionarSeloGrupoPai(valor);
          }}
          visible={isModalSeloGrupoPaiVisible}
          onCancel={() => setIsModalSeloGrupoPaiVisible(false)}
          optionsSistema={optionsSistema}
          optionsUf={optionsUf}
        />
      </Modal>
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
    </>
  );
}

export default CadastroSeloGrupoFilho;
