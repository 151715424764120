import axios from "axios";
import { urlBase } from "../../../Constante/Url";
import { UserTockem } from "../../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getForeign = async (id, page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}ForeignKey/?campo_tabela_id=${id}&limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getCampos = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}campo/?limit=${pageSize}&offset=${skip}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getForeignId = async (id) => {
  const url = `${urlBase}ForeignKey/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postForeign = async (data, id) => {
  const url = id ? `${urlBase}ForeignKey/${id}/` : `${urlBase}ForeignKey/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putForeign = async (data, id) => {
  const url = `${urlBase}ForeignKey/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteForeign = async (id) => {
  const url = `${urlBase}ForeignKey/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

const foreignRequest = {
  getForeign,
  getCampos,
  getForeignId,
  postForeign,
  putForeign,
  deleteForeign,
};

export default foreignRequest;
