import { Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { requestReport } from "../../../request";
import { ModalStatusCreate } from "../../../../../Utils/modalStatus";

export const ModalInserirRelatorios = ({
  visible,
  onCancel,
  id,
  estadoRelatorio,
}) => {
  const [datas, setDatas] = useState({
    descricao: "",
    arquivo_stream: "",
  });

  const [reportGroup, setReportGroup] = useState("");

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  useEffect(() => {
    if (visible) {
      getRelatorioGrupo();
    }
  }, [visible]);

  const inserirRelatorio = async () => {
    setConfirmLoading(true);
    try {
      const formData = new FormData();
      formData.append("descricao", datas.descricao);
      formData.append("grupo_relatorio_id", id);
      formData.append("arquivo_stream", datas.arquivo_stream);
      const response = await requestReport.postReport(formData);
      if (response.status === 201) {
        onCancel();
        estadoRelatorio();
        setDatas({ descricao: "", arquivo_stream: "" });
        setConfirmLoading(false);
        setOpenStatusModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const selecionarArquivoClick = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const base64String = await convertFileToBase64(file);
      setDatas((prevState) => ({
        ...prevState,
        arquivo_stream: base64String,
      }));
    }
  };

  const getRelatorioGrupo = async () => {
    setLoading(true);
    try {
      const response = await requestReport.getGroupReportId(id);
      if (response.status === 200) {
        const { descricao } = response.data;
        setReportGroup(descricao);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          setDatas((prevState) => ({ arquivo_stream: "", descricao: "" }));
        }}
        onOk={inserirRelatorio}
        confirmLoading={confirmLoading}
        destroyOnClose
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <div>
              <text style={{ fontWeight: "500" }}>Descrição:</text>
              <Input
                onChange={(e) =>
                  setDatas((prevState) => ({
                    ...prevState,
                    descricao: e.target.value,
                  }))
                }
                value={datas.descricao}
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <text style={{ fontWeight: "500" }}>Relatório Grupo:</text>
              <Input readOnly value={reportGroup} />
            </div>

            <input
              type="file"
              accept=".zip"
              style={{ marginTop: 30 }}
              onChange={selecionarArquivoClick}
            />
          </>
        )}
      </Modal>

      <ModalStatusCreate
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
