/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserTockem } from "../Utils/autenticacao";

export const MovimentContext = () => {
  const { currentUser } = useContext(UserContext);
  if (
    currentUser.is_support ||
    currentUser.is_superuser ||
    currentUser.is_manager
  ) {
    return <Outlet />;
  }

  return <Navigate to="/Cartorio" />;
};

export const DataBaseContext = () => {
  const { currentUser } = useUserContext();

  if (currentUser.is_superuser) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export const SystemContext = () => {
  const { currentUser } = useUserContext();

  if (currentUser.is_superuser || currentUser.is_manager) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export const EmolumentContext = () => {
  const { currentUser } = useUserContext();

  if (currentUser.is_superuser || currentUser.is_manager) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export const RegistryContext = () => {
  const { currentUser } = useUserContext();

  if (
    currentUser.is_superuser ||
    currentUser.is_manager ||
    currentUser.is_financial
  ) {
    return <Outlet />;
  }

  return <Navigate to="" />;
};

export const ReportContext = () => {
  const { currentUser } = useUserContext();

  if (
    currentUser.is_superuser ||
    currentUser.is_manager ||
    currentUser.is_support
  ) {
    return <Outlet />;
  }

  return <Navigate to="" />;
};

export const MonitoringContext = () => {
  const { currentUser } = useUserContext();

  if (currentUser) {
    return <Outlet />;
  }

  return <Navigate to="" />;
};

export const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      return null;
    }
    return JSON.parse(user);
  });

  const login = (token, user) => {
    localStorage.setItem(UserTockem, token);
    localStorage.setItem("user", JSON.stringify(user));
    setCurrentUser(user);
  };

  const logout = () => {
    localStorage.removeItem(UserTockem);
    localStorage.removeItem("user");
    localStorage.removeItem("cartorio_filtros");
    setCurrentUser(null);
  };

  return (
    <UserContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const { currentUser, login, logout } = useContext(UserContext);

  return {
    currentUser,
    login,
    logout,
  };
};
