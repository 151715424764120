/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { primaryRequest } from "./primaryRequest";
import { ModalDelete } from "./components/modalDelete";
import { colors } from "../../../Utils/temas";
import { DrawerFilter } from "./components/drawerFilter";
import { ModalInsert } from "./components/modalInsert";

export const PrimaryKey = ({ id, nomeTabela }) => {
  const [pk, setPk] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [pkId, setPkId] = useState(null);
  const [filtros, setFiltros] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    getPk(1, 10, filtros);
  }, [filtros]);

  const columns = [
    { title: "Usuário", dataIndex: "usuario_nome" },
    {
      title: "Data alteração",
      dataIndex: "data_alteracao",
      render: (value) => {
        const data = new Date(value);
        const dataFormatada = data.toLocaleString().replace(",", "");
        return <text>{dataFormatada}</text>;
      },
    },
    { title: "Nome", dataIndex: "nome" },
    { title: "Campo", dataIndex: "campo__nome" },
    {
      title: "Em teste",
      dataIndex: "em_teste",
      render: (value) => (
        <Tag color={value ? "green" : "red"}>{value ? "Sim" : "Não"}</Tag>
      ),
    },
    {
      title: "Ações",
      render: (record) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEdit(record.id)} />
          <DeleteOutlined onClick={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const getPk = async (page, pageSize, params) => {
    setLoading(true);
    try {
      const response = await primaryRequest.getPrimaryKey(
        page,
        pageSize,
        params,
        id
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setPk(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (value) => {
    setPkId(value);
    setOpen(true);
  };

  const handleDelete = (value) => {
    setPkId(value);
    setOpenDelete(true);
  };

  const onRemoverFiltro = (key) => {
    const novosFiltros = { ...filtros };
    delete novosFiltros[key];

    setFiltros(novosFiltros);
  };

  const tags = {
    nome: "Nome",
    campo_nome: "Campo",
    em_teste: "Em teste",
  };

  const getTags = () => (
    <>
      {Object.entries(filtros)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => {
          const getColors = () => {
            if (key === "nome") {
              return "gold";
            } else if (key === "campo_nome") {
              return "orange";
            }
          };

          const tagsValores = {
            true: "Sim",
            false: "Não",
          };

          return (
            <>
              {value || value === false ? (
                <Tag
                  closable
                  onClose={() => onRemoverFiltro(key)}
                  color={
                    key === "em_teste"
                      ? value
                        ? "green"
                        : "red"
                      : getColors(key)
                  }
                  key={key}
                >
                  <text style={{ padding: 10 }}>
                    {tags[key] +
                      ": " +
                      (key === "em_teste" ? tagsValores[value] : value)}
                  </text>
                </Tag>
              ) : null}
            </>
          );
        })}
    </>
  );

  return (
    <div>
      <Table
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setOpen(true)}
              className="botao"
              style={{
                background: colors.secondary,
                fontWeight: "500",
                color: "white",
              }}
            >
              <PlusOutlined /> Incluir
            </Button>

            <div style={{ marginLeft: 10 }}>
              <Button
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                icon={<SearchOutlined />}
                onClick={() => setOpenDrawer(true)}
              >
                Filtros
              </Button>
            </div>

            <div style={{ marginLeft: 10 }}>{getTags()}</div>
          </div>
        )}
        columns={columns}
        dataSource={pk}
        pagination={{
          total: totalRecords,
          onChange: (page, pageSize) => getPk(page, pageSize),
        }}
        loading={loading}
      />

      <ModalDelete
        onCancel={() => {
          setOpenDelete(false);
          setPkId(null);
        }}
        visible={openDelete}
        id={pkId}
        onDelete={setPk}
      />
      <ModalInsert
        onCancel={() => {
          setOpen(false);
          form.resetFields();
          setPkId(null);
        }}
        visible={open}
        id={id}
        estadoPk={setPk}
        idPK={pkId}
        nomeTabela={nomeTabela}
      />

      <DrawerFilter
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}
        onFinish={(value) => setFiltros(value)}
        limpar={() => setFiltros({})}
      />
    </div>
  );
};
