import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getDominios = async (page, pageSize, params) => {
  let skip = (page - 1) * pageSize;
  const url = urlBase + "dominio/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
  const response = await axios.get(url, config);
  return response;
};
const getDominiosId = async (id) => {
  const url = urlBase + `dominio/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postDominios = async (data, id) => {
  const url = id ? `${urlBase}dominio/${id}` : `${urlBase}dominio/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const deleteDominios = async (id) => {
  const url = urlBase + `dominio/${id}`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

const putDominios = async (id, data) => {
  const url = urlBase + `dominio/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const dominiosRequest = {
  getDominios,
  postDominios,
  deleteDominios,
  putDominios,
  getDominiosId,
};

export default dominiosRequest;
