export const tipos = [
    {
       tipo: 'T',
       descricao:'Título' 
    },
    {
        tipo:'C',
        descricao:'Certidão'
    },
    {
        tipo:'S',
        descricao:'Sinal Público'
    }

]