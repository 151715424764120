import { UserTockem } from "./autenticacao";
import axios from "axios";
import { urlBase } from "../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function getSistemas() {
  const url = urlBase + "sistema/";
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
}

export const sistemaRequest = {
  getSistemas,
};

export default sistemaRequest;
