import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import ptBr from "antd/lib/locale/pt_BR";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./Utils/errorPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <ConfigProvider locale={ptBr}>
      <App />
    </ConfigProvider>
  </ErrorBoundary>
);
