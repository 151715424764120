import { Button, Divider, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import sistemaRequest from "../../../../Utils/sistemaRequest";
import { arquivosRequest } from "../arquivosRequest";
import { colors } from "../../../../Utils/temas";
import { ModalStatusCreate } from "../../../../Utils/modalStatus";

export const ModalInserir = ({
  visible,
  onCancel,
  atualizarLista,
  tipoArquivo,
}) => {
  const [selectedFile, setSelectedFile] = useState();

  const [sistemasArquivos, setSistemasArquivos] = useState([
    {
      id: 0,
      nome_sistema: "",
    },
  ]);

  const [sistemaSelecionado, setSistemaSelecionado] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const [errorExist, setErrorExist] = useState("");

  const inputFile = useRef(null);

  useEffect(() => {
    sistemas();
  }, []);

  const sistemas = async () => {
    try {
      const response = await sistemaRequest.getSistemas();
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setSistemasArquivos(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionSistemas = sistemasArquivos.map((item) => {
    return {
      value: item.id,
      label: item.nome_sistema,
    };
  });

  const inserirArquivos = async () => {
    setLoading(true);
    try {
      const base64String = await convertFileToBase64(selectedFile);

      const formData = new FormData();
      formData.append("tipo_arquivo", tipoArquivo);
      formData.append("sistema_Id", sistemaSelecionado);
      formData.append("arquivo_stream", base64String);
      const response = await arquivosRequest.postArquivos(formData);
      if (response.status === 200 || response.status === 201) {
        setLoading(false);
        onCancel();
        setSelectedFile(null);
        setSistemaSelecionado([
          {
            id: 0,
            nome_sistema: "",
          },
        ]);
        setSistemaSelecionado(null);
        atualizarLista();
        setOpenStatusModal(true);
      }
    } catch (error) {
      const { Erro } = error.response && error.response.data;
      setErrorExist(Erro);
    } finally {
      setLoading(false);
    }
  };

  const refInput = () => {
    inputFile.current.click();
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const selecionarArquivoClick = async (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <Modal
        title="Inserir"
        visible={visible}
        onCancel={() => {
          onCancel();
          setSistemaSelecionado(null);
          setSelectedFile("");
        }}
        onOk={inserirArquivos}
        confirmLoading={loading}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            style={{ width: "40%", fontWeight: "500" }}
            value={tipoArquivo === "S" ? "Sistema" : "Dependência"}
            readOnly
          />
          {tipoArquivo === "S" ? (
            <Select
              options={optionSistemas}
              style={{ width: "56%" }}
              onChange={(e) => setSistemaSelecionado(e)}
              value={sistemaSelecionado}
              placeholder="Selecione o sistema"
              listHeight={300}
            />
          ) : null}
        </div>

        <Divider />

        <div
          style={{ marginTop: 20, display: "flex", flexDirection: "column" }}
        >
          <input
            type="file"
            accept=".zip"
            onChange={selecionarArquivoClick}
            style={{ display: "none" }}
            ref={inputFile}
          />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={refInput}
              className="botao"
              style={{
                background: colors.secondary,
                color: "white",
                fontWeight: "500",
              }}
            >
              Selecione um arquivo
            </Button>
            <text style={{ marginLeft: 10 }}>
              {selectedFile ? selectedFile.name : "Nenhum arquivo selecionado"}
            </text>
          </div>
          {errorExist && <text style={{ color: "red" }}>{errorExist}</text>}
        </div>
      </Modal>

      <ModalStatusCreate
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
