import { UserTockem } from "../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../Constante/Url";

function getConfig() {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
  };
}

async function CadastrarSeloGrupoPai(seloGrupoPai, id) {
  const url = id
    ? `${urlBase}selo-grupo-pai/${id}`
    : `${urlBase}selo-grupo-pai/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, seloGrupoPai, config);
  } else {
    response = await axios.post(url, seloGrupoPai, config);
  }
  return response;
}

async function getSeloGrupoPai(id) {
  const url = urlBase + `selo-grupo-pai/${id}/`;
  const config = getConfig();

  const response = await axios.get(url, config);
  return response;
}

async function getListaSeloGrupoPai(page, pageSize, params) {
  let skip = (page - 1) * pageSize;

  const url = urlBase + "selo-grupo-pai/?limit=" + pageSize + "&offset=" + skip;

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };

  const response = await axios.get(url, config);
  return response;
}

const atualizarSeloGrupoPai = async (id, data) => {
  let url = `${urlBase}selo-grupo-pai/${id}`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

async function ExcluirSeloGrupoPai(id) {
  const url = urlBase + `selo-grupo-pai/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
}

const SeloGrupoPaiRequest = {
  CadastrarSeloGrupoPai,
  getSeloGrupoPai,
  getListaSeloGrupoPai,
  ExcluirSeloGrupoPai,
  atualizarSeloGrupoPai,
};

export default SeloGrupoPaiRequest;
