import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button } from "antd";

export const ErrorPage = ({ error }) => (
  <div
    style={{
      backgroundColor: "#F0F2F5",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    }}
  >
    <div
      style={{
        background: "#fcfcfd",
        width: 500,
        borderRadius: 10,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          background: "#94281F",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px 10px 0px 0px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <text style={{ color: "white", fontSize: 32, fontWeight: "500" }}>
          Erro!
        </text>
        <CloseCircleFilled
          style={{ fontSize: 32, color: "white", marginBottom: 10 }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 10px 10px 10px",
          alignItems: "center",
        }}
      >
        <text
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          {error.message}
        </text>

        <Button
          style={{
            background: "#94281F",
            color: "white",
            borderColor: "#94281F",
            marginTop: 20,
            fontWeight: "500",
          }}
          href="/"
        >
          Tente novamente
        </Button>
      </div>
    </div>
  </div>
);
