/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SelectCampos } from "../../../foreignKey/components/selectCampos";
import { Button, Divider, Form, Input, Modal, Skeleton, Switch } from "antd";
import { colors } from "../../../../../Utils/temas";
import { indexRequest } from "../../indexRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";
const { Search } = Input;
const { useForm } = Form;

export const ModalInsert = ({
  id,
  visible,
  idIndex,
  onCancel,
  estadoIndex,
  initialValue,
}) => {
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  useEffect(() => {
    if (idIndex) {
      getValuesIndex();
    }
  }, [idIndex]);

  const postIndex = async (data) => {
    setConfirmLoading(true);
    try {
      const response = await indexRequest.postIndex(data, idIndex);
      if (response.status === 201) {
        onCancel(false);
        const { data } = response;
        estadoIndex((prevIndex) => [data, ...prevIndex]);
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
      if (response.status === 200) {
        onCancel(false);
        const cb = (callback) => {
          if (callback.id === idIndex) {
            return { ...callback, ...response.data };
          }
          return { ...callback };
        };
        estadoIndex((prevIndex) => prevIndex.map(cb));
        form.resetFields();
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const onSelect = (valor) => {
    form.setFieldsValue({
      campo__nome: valor.nome,
      campo_id: valor.id,
    });
    setOpenSelect(false);
  };

  const getValuesIndex = async () => {
    try {
      setLoading(true);
      const response = await indexRequest.getIndexId(idIndex);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          campo__nome: data.campo__nome,
          campo__tabela__nome: data.campo__tabela__nome,
          campo__tabela_id: data.campo__tabela_id,
          campo_id: data.campo_id,
          data_ateracao: data.data_ateracao,
          em_teste: data.em_teste,
          id: data.id,
          nome: data.nome,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel();
          form.resetFields();
        }}
        title={idIndex ? "Editar" : "Inserir"}
        footer={null}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form.validateFields().then((values) => postIndex(values));
            }}
            initialValues={{ em_teste: true }}
          >
            <Form.Item
              name="nome"
              label="Nome:"
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Informe o nome do Index" }]}
              initialValue={initialValue}
            >
              <Input />
            </Form.Item>

            <Form.Item name="campo_id" style={{ margin: 0 }}>
              <Form.Item
                name="campo__nome"
                label="Campo:"
                style={{ fontWeight: "500" }}
                rules={[{ required: true, message: "Informe o campo" }]}
              >
                <Search readOnly onSearch={() => setOpenSelect(true)} />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="em_teste"
              label="Em teste:"
              valuePropName="checked"
              style={{ fontWeight: "500" }}
            >
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  onCancel();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}

        <SelectCampos
          openModal={openSelect}
          onCancel={() => setOpenSelect(false)}
          id={id}
          onSelecionado={(value) => onSelect(value)}
        />
      </Modal>

      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
    </>
  );
};
