export  const ufs = [
    {
        sigla: 'GO',
        nome: 'Goias'
    },
    {
        sigla: 'AL',
        nome: 'Alagoas'
    },
    {
        sigla: 'CE',
        nome: 'Ceará'
    },
    {
        sigla: 'PA',
        nome: 'Pará'
    },
];

