import { UserTockem } from "../../../Utils/autenticacao";
import axios from "axios";
import { urlBase } from "../../../Constante/Url";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getValoresDefault = async (page, pageSize, id, params) => {
  let skip = (page - 1) * pageSize;
  const url = `${urlBase}ValoresDefault/?limit=${pageSize}&offset=${skip}&tabela_id=${id}`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};
const getValoresDefaultId = async (id) => {
  const url = `${urlBase}ValoresDefault/${id}`;
  const config = getConfig();
  const response = await axios.get(url, config);
  return response;
};

const postValoresDefault = async (data, id) => {
  const url = id
    ? `${urlBase}ValoresDefault/${id}`
    : `${urlBase}ValoresDefault/`;
  const config = getConfig();
  let response;
  if (id) {
    response = await axios.put(url, data, config);
  } else {
    response = await axios.post(url, data, config);
  }
  return response;
};

const putValoresDefault = async (id, data) => {
  const url = `${urlBase}ValoresDefault/${id}/`;
  const config = getConfig();
  const response = await axios.put(url, data, config);
  return response;
};

const deleteValoresDefault = async (id) => {
  const url = `${urlBase}ValoresDefault/${id}/`;
  const config = getConfig();
  const response = await axios.delete(url, config);
  return response;
};

export const valoresDefaultRequest = {
  getValoresDefault,
  getValoresDefaultId,
  postValoresDefault,
  putValoresDefault,
  deleteValoresDefault,
};
