import { useState, useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import SeloGrupoPaiRequest from "../../../Paginas/CadastroSeloGrupoPai/SeloGrupoPaiRequest";
import SelSeloGrupo from "../Sel_SeloGrupo";
import { colors } from "../../../Utils/temas";

export const SelSeloGrupoPai = ({
  onSelecionado,
  visible,
  onCancel,
  optionsUf,
  optionsSistema,
}) => {
  const [seloGrupo, setSeloGrupo] = useState([]);
  const [filtros, setFiltros] = useState([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

  const [linhaSelecionada, setLinhaSelecionada] = useState(null);

  const [form_pesquisa] = Form.useForm();

  function handleOk() {
    onSelecionado(linhaSelecionada);
  }

  const columns = [
    {
      title: "Descrição",
      dataIndex: "selo_grupo_descricao",
    },
    {
      title: "Número",
      dataIndex: "selo_grupo_numero",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await SeloGrupoPaiRequest.getListaSeloGrupoPai(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      setSeloGrupo(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
  };

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  const { Search } = Input;

  return (
    <Modal
      width={800}
      title="Selecionar Selo Grupo Pai"
      visible={visible}
      onOk={() => {
        handleOk();
      }}
      onCancel={onCancel}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "80vh", overflowY: "scroll" }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item name="selo_grupo__numero" label="Número:">
              <Input />
            </Form.Item>

            <Form.Item
              name="selo_grupo__situacao"
              label="Situação:"
              style={{ width: "20%" }}
            >
              <Select
                options={[
                  { value: "A", label: "Ativo" },
                  { value: "F", label: "Inativo" },
                ]}
                placeholder="Selecione a situação"
              />
            </Form.Item>
            <Form.Item
              name="selo_grupo__uf"
              label="Situação:"
              style={{ width: "20%" }}
            >
              <Select options={optionsUf} placeholder="Selecione a UF" />
            </Form.Item>
            <Form.Item name="selo_grupo_id" style={{ margin: 0, height: 0 }} />
            <Form.Item name="selo_grupo_descricao" label="Selo Grupo:">
              <Search readOnly onSearch={() => setOpen(true)} />
            </Form.Item>
          </div>
          <Row gutter={10}>
            <Col span={12}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={3} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="large"
        rowKey={"id"}
        rowSelection={{
          type: "radio",
          onChange: SelecionarLinha,
        }}
        dataSource={seloGrupo}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
      />

      <SelSeloGrupo
        visible={open}
        onCancel={() => setOpen(false)}
        optionsSistema={optionsSistema}
        optionsUf={optionsUf}
        onSelecionado={(value) => {
          form_pesquisa.setFieldsValue({
            selo_grupo_id: value.numero,
            selo_grupo_descricao: value.descricao,
          });
        }}
      />
    </Modal>
  );
};
