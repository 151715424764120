import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import { KeyOutlined, CloseOutlined } from "@ant-design/icons";
import CartorioRequest from "../../Paginas/CadastroCartorio/CartorioRequest";
import { colors } from "../../Utils/temas";

function SelCartorio({
  onSelecionado,
  visible,
  onCancel,
  optionsUf,
  defaultValue,
}) {
  const [Cartorio, SetCartorio] = useState([]);
  const [loading, setLoading] = useState(false);
  const [switchValues, setSwitchValues] = useState({
    switchApi: false,
    switchAto: false,
    switchOS: false,
    switchSMS: false,
    switchWhats: false,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [form_pesquisa] = Form.useForm();
  const [filtros, setFiltros] = useState([]);

  const [situacao, setSituacao] = useState("");

  function handleOk() {
    onSelecionado(linhaSelecionada);
  }

  const columns = [
    {
      title: "Nome Cartorio",
      dataIndex: "nome_cartorio",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cpfcnpj",
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
    },
    {
      title: "UF",
      dataIndex: "uf",
    },
  ];

  async function FetchRecords(page, pageSize, params) {
    setLoading(true);
    const response = await CartorioRequest.getListaCartorio(
      page,
      pageSize,
      params
    );
    if (response.status === 200) {
      SetCartorio(response.data.results);
      setTotalRecords(response.data.count);
      setLoading(false);
    }
  }

  function SelecionarLinha(selectedRowKeys, selectedRows) {
    setLinhaSelecionada(selectedRows[0]);
  }

  async function onPesquisarClick(value) {
    setFiltros(value);
    FetchRecords(1, 10, value);
  }

  const Limpar = () => {
    setFiltros([]);
    form_pesquisa.resetFields();
    FetchRecords(1, 10, {});
  };

  useEffect(() => {
    FetchRecords(1, 10, {});
  }, []);

  const handleSwitchChange = (name, checked) => {
    setSwitchValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <Modal
      width={900}
      title="Selecionar Cartório"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      forceRender={true}
      destroyOnClose={true}
      centered
      bodyStyle={{ height: "70vh", overflowX: "scroll" }}
    >
      <>
        <Form
          form={form_pesquisa}
          layout="vertical"
          onFinish={onPesquisarClick}
          preserve={true}
        >
          <Row justify="space-between">
            <Col span={10}>
              <Form.Item name="nome_cartorio" label="Nome cartorio:">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="uf" label="UF">
                <Select
                  name="uf"
                  options={optionsUf}
                  defaultValue={defaultValue}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="cidade" label="Cidade:">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} justify="space-around">
            <Col>
              <Form.Item name="situacao" label="Situação">
                <Radio.Group
                  value={situacao}
                  onChange={(e) => setSituacao(e.target.value)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Radio value="A">Ativo</Radio>
                  <Radio value="I">Inativo</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_api"
                label="Usa api"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchApi}
                  onChange={(checked) =>
                    handleSwitchChange("switchApi", checked)
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="usa_cad_ato"
                label="Usa cad ato"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchAto}
                  onChange={(checked) =>
                    handleSwitchChange("switchAto", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_os"
                label="Usa OS"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchOS}
                  onChange={(checked) =>
                    handleSwitchChange("switchOS", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_sms"
                label="Usa SMS"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchSMS}
                  onChange={(checked) =>
                    handleSwitchChange("switchSMS", checked)
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="usa_whatszap"
                label="Usa WhatsZap"
                style={{ alignItems: "center" }}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  checked={switchValues.switchWhats}
                  onChange={(checked) =>
                    handleSwitchChange("switchWhats", checked)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                className="botao"
              >
                <KeyOutlined />
                Pesquisar
              </Button>
            </Col>

            <Col span={3} push={9}>
              <Button onClick={Limpar}>
                <CloseOutlined />
                Limpar
              </Button>
            </Col>
          </Row>
        </Form>
      </>
      <Table
        loading={loading}
        columns={columns}
        size="small"
        rowKey={"id"}
        rowSelection={{
          type: "radio",
          onChange: SelecionarLinha,
        }}
        dataSource={Cartorio}
        pagination={{
          total: totalRecords,
          onChange: (page, pagaSize) => {
            FetchRecords(page, pagaSize, filtros);
          },
        }}
      />
    </Modal>
  );
}
export default SelCartorio;
