import axios from "axios";
import { urlBase } from "../../../../Constante/Url";
import { UserTockem } from "../../../../Utils/autenticacao";

function getConfig(params) {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem(UserTockem)}` },
    params: params,
  };
}

const getVersao = async (params) => {
  const url = `${urlBase}versao/`;
  const config = getConfig(params);
  const response = await axios.get(url, config);
  return response;
};

export const versaoRequest = {
  getVersao,
};
