import { Modal } from "antd";
import React, { useState } from "react";
import { menuRequest } from "../../../../menuRequest";
import { ModalStatusDelete } from "../../../../../../Utils/modalStatus";

export const ModalDelete = ({ visible, onCancel, id, estadoPermissao }) => {
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletarPermissao = async () => {
    setLoading(true);
    try {
      const response = await menuRequest.deleteMenuItem(id);
      if (response.status === 204) {
        onCancel();
        estadoPermissao((prevState) =>
          prevState.filter((item) => {
            return item.id !== id;
          })
        );
        setOpenStatusModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={deletarPermissao}
        confirmLoading={loading}
      >
        <text style={{ fontWeight: "500", fontSize: 18 }}>
          Deletar esta permissão?
        </text>
      </Modal>

      <ModalStatusDelete
        onCancel={() => setOpenStatusModal(false)}
        visible={openStatusModal}
      />
    </>
  );
};
