/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Skeleton,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { SelectCamposRef } from "../selectCamposRef";
import { SelectCampos } from "../selectCampos";
import { colors } from "../../../../../Utils/temas";
import foreignRequest from "../../foreignRequest";
import {
  ModalStatusCreate,
  ModalStatusEdit,
} from "../../../../../Utils/modalStatus";

const { Search } = Input;

const { useForm } = Form;

export const ModalInsert = ({
  open,
  onCancel,
  estadoFk,
  id,
  idEdit,
  initialValue,
}) => {
  const [loading, setLoading] = useState(false);
  const [destroy, setDestroy] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCampo, setOpenModalCampo] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openModalStatus, setOpenModalStatus] = useState({
    create: false,
    edit: false,
  });

  const [form] = useForm();

  useEffect(() => {
    if (idEdit) {
      getForeignId();
    }
  }, [idEdit]);

  const campoSelecionado = (valor) => {
    setOpenModalCampo(false);
    form.setFieldsValue({ campo__nome: valor.nome, campo_id: valor.id });
  };

  const campoSelecionadoRef = (valor) => {
    setOpenModal(false);
    form.setFieldsValue({
      campo_referencia__nome: valor.nome,
      campo_referencia_id: valor.id,
    });
  };

  const incluirCampo = async (dados) => {
    setConfirmLoading(true);
    try {
      const response = await foreignRequest.postForeign(dados, idEdit);
      if (response.status === 201) {
        onCancel();
        form.resetFields();
        const responseData = response.data;
        estadoFk((prevForeign) => [responseData, ...prevForeign]);
        setDestroy(true);
        setOpenModalStatus((prevState) => ({ ...prevState, create: true }));
      }
      if (response.status === 200) {
        onCancel();
        form.resetFields();
        const { data } = response;
        const cb = (callback) => {
          if (callback.id === idEdit) {
            return { ...callback, ...data };
          } else {
            return { ...callback };
          }
        };
        estadoFk((prevState) => prevState.map(cb));
        setOpenModalStatus((prevState) => ({ ...prevState, edit: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getForeignId = async () => {
    setLoading(true);
    try {
      const response = await foreignRequest.getForeignId(idEdit);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        form.setFieldsValue({
          nome: data.nome,
          campo__nome: data.campo__nome,
          campo_id: data.campo_id,
          campo_referencia__nome: data.campo_referencia__nome,
          campo_referencia_id: data.campo_referencia_id,
          em_teste: data.em_teste,
          delete_cascade: data.delete_cascade,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => {
          onCancel();
          form.resetFields();
          setDestroy(true);
        }}
        footer={null}
        destroyOnClose={destroy}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              form.validateFields().then((values) => incluirCampo(values));
            }}
            preserve={false}
            initialValues={{ em_teste: true }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="nome"
                label="Nome:"
                style={{ width: "100%", margin: 0 }}
                rules={[
                  { required: true, message: "Informe o nome da Foreign Key" },
                ]}
                initialValue={initialValue}
              >
                <Input placeholder="Nome" maxLength={31} />
              </Form.Item>
            </div>

            <Divider />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item name="campo_id" style={{ width: "48%", margin: 0 }}>
                <Form.Item
                  name="campo__nome"
                  label="Campo:"
                  rules={[{ required: true, message: "Informe o campo" }]}
                >
                  <Search
                    readOnly
                    allowClear
                    onSearch={() => setOpenModalCampo(true)}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="campo_referencia_id"
                style={{ width: "48%", margin: 0 }}
              >
                <Form.Item
                  name="campo_referencia__nome"
                  label="Campo Referência"
                  rules={[
                    { required: true, message: "Informe o campo referência" },
                  ]}
                >
                  <Search
                    readOnly
                    allowClear
                    onSearch={() => setOpenModal(true)}
                  />
                </Form.Item>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "56%",
              }}
            >
              <Form.Item
                valuePropName="checked"
                name="em_teste"
                label="Em teste:"
                style={{ width: "100%" }}
              >
                <Switch checkedChildren={true} unCheckedChildren={false} />
              </Form.Item>

              <Form.Item
                name="delete_cascade"
                label="Delete Cascade:"
                style={{ width: "100%" }}
              >
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <Divider />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ paddingRight: 20 }}>
                <Button
                  onClick={() => {
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
              </div>
              <Button
                htmlType="submit"
                style={{
                  background: colors.secondary,
                  color: "white",
                  fontWeight: "500",
                }}
                loading={confirmLoading}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        )}

        <SelectCamposRef
          onCancel={() => setOpenModal(false)}
          openModal={openModal}
          onSelecionado={(valor) => campoSelecionadoRef(valor)}
        />
        <SelectCampos
          openModal={openModalCampo}
          onCancel={() => setOpenModalCampo(false)}
          id={id}
          onSelecionado={(valor) => campoSelecionado(valor)}
        />
      </Modal>

      <ModalStatusEdit
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, edit: false }))
        }
        visible={openModalStatus.edit}
      />
      <ModalStatusCreate
        onCancel={() =>
          setOpenModalStatus((prevState) => ({ ...prevState, create: false }))
        }
        visible={openModalStatus.create}
      />
    </>
  );
};
